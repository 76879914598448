import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { login2 } from '../../Services/Actions/user.actions';
import { Helmet } from 'react-helmet';

const TITLE = '404 Error Page';

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }

        console.log(this.props);
    }
    
    render() {
        return (
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <div className="container">
                <div style={{margin:'52px 0px 250px'}}>
                    <div style={{float:'left', width:'40%', padding:'10px 20px', fontSize:'80px', fontWeight:'700', textAlign:'right', color:'#e94c28'}}>
                        404
                        <label style={{fontSize:'100px', fontWeight:'100'}}>|</label>
                    </div>
                    <div style={{float:'left', width:'60%',lineHeight:3.5, color:'#3f4043', fontWeight:'500',fontSize:'20px'}}> 
                        <p style={{marginTop:'65px', marginBottom:'0px'}}>Sorry! The page you are looking for was not found.</p>
                        <Link to="/" style={{textAlign:'left', float:'left', width:'100%'}}>&laquo; Go Back</Link>
                    </div>
                </div>
            </div> 
            </>
        );
    }
}

function mapStateToProps(state) {
    return { 
        cartItems: state.cart.cartData
    }
}

const actionCreators = {
    logIn:login2
};

const ErrorComp = connect(
    mapStateToProps,
    actionCreators
)(ErrorPage)

export default ErrorComp;