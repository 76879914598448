import React, {Component} from 'react';
import ProductImageCarausel from './ProductImageCarausel';
import ProductDetailsListArea from './ProductDetailsListArea';

export default class ProductDetailsContainer extends Component {

    render(){
        return(
            <section className="listing-container product-view-container">
	            <div className="container">
		            <div className="row">
                        {/* <ProductImageCarausel /> */}
                        <ProductDetailsListArea />
                    </div>
                </div>
            </section>
        )
    }
}