import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';

const TITLE = 'E-Vision Panamá / Tienda Online';

class BannerSection extends Component {

    render(){
        let pathName = this.props.match.path.slice(1);
        let pageTitle = "";
        if(pathName==="onlinesale") {
            pageTitle = "TIENDA ONLINE";
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <section className="banner-container">
                <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--photo-catagory d-flex">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">¡Explore equipos y accesorios de fotografía en línea!</h1>
                                            <p>Libere su potencial artístico: explore un mundo de excelencia fotográfica con nuestra colección exclusiva!</p>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/* <div className="product-title">{pageTitle}</div> */}
               {/* <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>		       */}
            </section>
            </>
        )
        
    }
}

export default withRouter(BannerSection);