import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from "../../config/api";

class ProductLeftCategoryMenu extends Component {
  constructor(props) {
    console.log("Props", props);
    super(props);
    this.state = {
      error: null,
      cat_products: [],
      catProducts: [],
      catProductsLoaded: false,
      selectedParentCatId: false,
      currentChildCategorySlug: "",
      isVisible: true,
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  async fetchCategoryData(categorySlug) {
      const apiUrl = `${API_BASE_URL}/category`;
      let requestOptions = {
          method: "POST",
          body: JSON.stringify({ category_slug: categorySlug }),
      };

      try {
          let response = await fetch(apiUrl, requestOptions);
          let result = await response.json();
          if (result.code === 200)
          {
            this.setState({
                catProducts: result.data.menu,
                selectedParentCatId: result.data.selected_parent_category_id,
                catProductsLoaded: true,
            }); 
          }
      } catch (error) {
          this.setState({ error });
      }
  }
  componentDidMount() {
    let params = this.props.match.params;
    let topCategorySlug = params.categoryName;
    let subCategorySlug = params.subCategoryName;
    let lastSubCategoryName = params.lastSubCategoryName;
    let categorySlug = "";

    if (topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined) {
        categorySlug = lastSubCategoryName;
    } else if (topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName === undefined) {
        categorySlug = subCategorySlug;
    } else if (topCategorySlug !== undefined && subCategorySlug === undefined && lastSubCategoryName === undefined) {
        categorySlug = topCategorySlug;
    }
    
    this.fetchCategoryData(categorySlug);

    this.setState({
        currentChildCategorySlug: categorySlug,
    });
}

async componentDidUpdate(prevProps, prevState) {
  if (prevProps.match.params !== this.props.match.params) {
      let params = this.props.match.params;
      let topCategorySlug = params.categoryName;
      let subCategorySlug = params.subCategoryName;
      let lastSubCategoryName = params.lastSubCategoryName;
      let categorySlug = "";

      if (topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined) {
          categorySlug = lastSubCategoryName;
      } else if (topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName === undefined) {
          categorySlug = subCategorySlug;
      } else if (topCategorySlug !== undefined && subCategorySlug === undefined && lastSubCategoryName === undefined) {
          categorySlug = topCategorySlug;
      }

      this.setState({
          currentChildCategorySlug: categorySlug,
      });

      await this.fetchCategoryData(categorySlug);
  }
}

  render() {
    const { catProducts, selectedParentCatId, currentChildCategorySlug, isVisible } = this.state;
    //const { isVisible } = this.state;

    if (!this.state.catProductsLoaded) {
      return (
        <>
          <h2>
            <Skeleton height={50} width={`100%`} />
          </h2>
          <Skeleton count={13} style={{ padding: "10px", margin: "2px 0px" }} />
        </>
      );
    }
    return (
      <nav className="navigation">
          <h2 className={`filter-title ${isVisible ? "dropdown-expanded" : "dropdown-collapsed"}`}>
            <button type="button" className="filter-menu-title" onClick={this.toggleMenu}>
              Departamentos
              {!isVisible ? (
                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-up.svg`} alt="arrow up" className="toggle-image" />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-down.svg`} alt="arrow down" className="toggle-image" />
              )}
            </button>
          </h2>
        <ul className={`mainmenu ${isVisible ? "visible" : "hidden"}`}>
        {
          catProducts && catProducts.length > 0 ? (catProducts.map((cat_product) =>
            <li
              key={cat_product.id}
              className={cat_product.sub && cat_product.sub.length ? "dropdown-list-menu" : ""}
            >
              <Link 
                  to={`/categorias/${cat_product.slug}`} 
                  className={cat_product.id == selectedParentCatId ? "menuactive" : ""}
              >
                  {cat_product.name}
              </Link>
              {cat_product.sub && cat_product.sub.length > 0 && (
                  <ul className="submenu">
                      {cat_product.sub.map((submenu) => (
                          <li key={submenu.id}>
                              <Link 
                                  to={`/categorias/${cat_product.slug}/${submenu.slug}`} 
                                  className={submenu.slug === currentChildCategorySlug ? "menuactive" : ""}
                              >
                                  {submenu.name}
                              </Link>
                          </li>
                      ))}
                  </ul>
              )}
            </li>
          )
        ) : (
        <>
          <h2>
            <Skeleton height={50} width={`100%`} />
          </h2>
          <Skeleton count={13} style={{ padding: "10px", margin: "2px 0px" }} />
        </>
        )
        }
        </ul>
      </nav>
    );
  }
}

export default withRouter(ProductLeftCategoryMenu);