import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const TITLE = 'E-Vision / Tienda Online';

export default class BreadcrumbSection extends Component {

    render(){
        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <section className="breadcamp breadcamp__catagory">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop:'30px'}}>
                            <Link to="/">Home &#62;</Link> <span className="text-blue">Tienda Online</span>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}