import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import API_BASE_URL from '../../config/api';

class BannerSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            productName : '',
            productCategoryId: '',
            productCategory: '',
            productCategorySlug: '',
            isProductLoaded: false
        }
    }

    async componentDidMount() {
        let product_id = this.props.match.params.productId;
        //const apiUrl = 'https://www.evisionstore.com/api/product/get_title_by_id.php';
        const apiUrl = `${API_BASE_URL}/title`;

        let requestOptions;
        if (isNaN(product_id)) {
            requestOptions = {
                method: 'POST',
                body: JSON.stringify({"model_number": product_id})
            };
        } else {
            requestOptions = {
                method: 'POST',
                body: JSON.stringify({"product_id": product_id})
            };
        }
        try {
            const response = await fetch(apiUrl, requestOptions);
            const result = await response.json();
            this.setState({
                productName: result.data.product_name,
                productCategoryId: result.data.category_id,
                productCategory: result.data.category_name,
                productCategorySlug: result.data.category_slug,
                isProductLoaded: true
            });
        } catch (error) {
            this.setState({ error: error });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.productId !== this.props.match.params.productId) {
            let product_id = this.props.match.params.productId;
            //const apiUrl = 'https://www.evisionstore.com/api/product/get_title_by_id.php';
            const apiUrl = `${API_BASE_URL}/title`;
            let requestOptions;
            if (isNaN(product_id)) {
                requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"model_number": product_id})
                };
            } else {
                requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"product_id": product_id})
                };
            }
            try {
                const response = await fetch(apiUrl, requestOptions);
                const result = await response.json();
                this.setState({
                    productName: result.data.product_name,
                    productCategoryId: result.data.category_id,
                    productCategory: result.data.category_name,
                    productCategorySlug: result.data.category_slug,
                    isProductLoaded: true
                });
            } catch (error) {
                this.setState({ error: error });
            }
        }
    }

    render(){
        const { productName, productCategoryId, productCategory, productCategorySlug} = this.state; 
        const hostname = window.location.host;
        let params = this.props.match.params;
        //var canonicalUrl = "https://" + hostname + "/producto/" + params.productId;
        return(
            <>
            {/* <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet> */}
            {/* <section className="banner-container product-banner-container">
                <div className="product-title" dangerouslySetInnerHTML={{__html: productName }} />
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>		      
            </section> */}

            <section className="breadcamp product-breadcamp-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="">Home</Link><span className="separator">&#62;</span><Link to={`/categorias/${productCategorySlug}`}>{productCategory}</Link><span className="separator">&#62;</span><span className="bread-product-name" dangerouslySetInnerHTML={{__html: productName }} />
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default withRouter(BannerSection);