import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import { useAuth } from "./Context/Auth";

function PrivateRoute({ component: Component, page, ...rest }) {
    const  authTokens  = localStorage.getItem('user');
    const isAllowGuestCheckout = localStorage.getItem('isAllowGuestCheckout');
    //return false;
    return (
    <Route {...rest} render = {
           props => authTokens || isAllowGuestCheckout ? 
            (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
            )
            
        }
    />
    );
}

const PrivateRoute2 = props => {
    console.log(props);
    const { isLoggedIn, type, page } = props;
    if (type === "guest" && isLoggedIn && page === "login") return <Redirect to="/profile" />;
    else if (type === "private" && !isLoggedIn && page === "profile") return <Redirect to="/login" />;
  
    return <Route {...props} />;
  };

function mapStateToProps(state) {
    return { 
        isLoggedIn: state.user.isLoggedIn
    }
}

  
export default connect(mapStateToProps)(PrivateRoute);