import React, {Component} from 'react';
import { Link } from "react-router-dom";
import API_BASE_URL from '../../config/api';

export default class DesktopHeaderMenuSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            headerMenuArray: {},
            isHeaderMenuLoaded: false
        }
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/home/headermenu.php';
        const apiUrl = `${API_BASE_URL}/menu`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({"menutype": "header"})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    headerMenuArray: result.data.headermenu,
                    isHeaderMenuLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    render(){
        const { headerMenuArray } = this.state;
        //console.log(headerMenuArray);
        return(
            <section className="main-menu-container desktop">
                <div className="container">
                    <div className="row">
                        <div className="navbar navbar-default " role="navigation">
                            <div className="container">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>
                                <div className="collapse navbar-collapse">
                                    <ul className="nav navbar-nav">
                                        <li><Link to="/">Inicio</Link></li>
                                        <li>
                                            <Link to="#" className="dropdown-toggle" data-toggle="dropdown">Departamentos </Link>
                                            {headerMenuArray.length !== 0 ? (
                                                <ul className="dropdown-menu multi-level">
                                                {Object.values(headerMenuArray).map((main_menu, index) => (
                                                    <li className={(typeof(main_menu.submenu) !== 'undefined' && main_menu.submenu.length > 0) ? 'submenu-arrow' : ''} key={index}>
                                                        {typeof(main_menu.submenu) !== 'undefined' && main_menu.submenu.length > 0 ? (
                                                            <>
                                                            <Link to="#">{main_menu.category_name}</Link>
                                                            <ul>
                                                            {Object.values(main_menu.submenu).map((sub_menu, subindex) => (
                                                                <li key={subindex}>
                                                                    <Link to={`/categorias/${ main_menu.category_slug }/${ sub_menu.category_slug }`}>{sub_menu.category_name}</Link>
                                                                </li>
                                                            ))}  
                                                            </ul>
                                                            </>
                                                        ) : (
                                                            <Link to={`/categorias/${ main_menu.category_slug }`}>{main_menu.category_name}</Link>
                                                        )}
                                                    </li>
                                                ))}
                                                </ul>
                                            ) : (
                                                <></>
                                            )}
                                        </li>
                                        <li><Link to="/contacto">Contáctenos</Link></li>
                                        <li><Link to="/onlinesale">Tienda online</Link></li>
                                    </ul>
                                    {/* <ul className="nav navbar-nav navbar-right">
                                        <li>
                                            <Link to="#"><img src={`${process.env.PUBLIC_URL}/images/tag-icon.png`} alt="" className="icon-float" /> Oferta Especial</Link>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}