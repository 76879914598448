import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'Mis ordenes';

class MyOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            ordersList: [],
            isOrdersLoaded: false
        }
    }

    componentDidMount() {
        const apiUrl = `${API_BASE_URL}/order-list`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "customer_id": this.props.customerId })
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if(result.code === 200){
                    this.setState({
                        ordersList: result.data.order_list,
                        isOrdersLoaded: true
                    });
                } else {
                    this.setState({
                        isOrdersLoaded: true
                    });
                }
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    render(){
        const {isLoggedIn} = this.props;
        const { ordersList } = this.state;

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h3 className="tabname">Lista de orden</h3>
                                    <p> Tu lista de pedidos </p>
                                    <div className="row userInfo">
                                        <div className="col-lg-12">
                                            
                                        </div>
                                        <div style={{clear: 'both'}} />
                                        <div className="col-xs-12 col-sm-12">
                                            <div class="table-responsive">
                                                <table className="table">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>Orden ID</th>
                                                            <th>No. de artículos</th>
                                                            <th> Precio</th>
                                                            <th> Fecha</th>
                                                            <th style={{textAlign:'right'}}> Estado</th>
                                                            <th> Acción </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ordersList.length !== 0 ? (
                                                            ordersList.map((orders, index) => (
                                                                <tr key={index}>
                                                                    <td>#{orders.increment_id}</td>
                                                                    <td>{orders.total_items} <small>elemento(s)</small></td>
                                                                    <td>{orders.currency}{orders.order_amount}</td>
                                                                    <td>{orders.order_time}</td>
                                                                    <td>
                                                                        {(() => {
                                                                            if(orders.payment_status === "SUCCESS"){
                                                                                return (<span className="label label-success float-right">{orders.payment_status}</span>);
                                                                            } else if (orders.payment_status === "PENDING") {
                                                                                return (<span className="label label-warning float-right">{orders.payment_status}</span>);
                                                                            } else {
                                                                                return (<span className="label label-danger float-right">{orders.payment_status}</span>);
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/orderview/${orders.order_id}`} className="view-order" >
                                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/view-eye.svg`} alt="view-eye" />
                                                                            Vista
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6">No se encontraron pedidos!</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    {/*/row end*/}
                                </div>


                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a Mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id
})

const MyOrdersComp = connect(
    mapStateToProps
)(MyOrders)

export default MyOrdersComp;