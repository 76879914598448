import { checkoutConstants } from '../Constants/checkout.constants';

const initialState = { 
    billingInfo : [],
    shippingInfo: [],
    placeorderInfo: [],
    paymentStatus: "",
    isAllowGuestCheckout: false
};

export default function CheckoutReducer(state = initialState, action) {
    //console.log(action.type);
    switch (action.type) {
        
        case checkoutConstants.STORE_BILLING_INFO:
            let billingInfo = action.billingObj;
            return{
                ...state,
                billingInfo: billingInfo
            }

        case checkoutConstants.STORE_SHIPPING_INFO:
            let shippingInfo = action.shippingObj;
            return{
                ...state,
                shippingInfo: shippingInfo
            }

        case checkoutConstants.CARD_PAYMENT_SUCCESS:
            let placeorderInfo = action.placeorderResponse;
            //console.log(placeorderInfo)
            return{
                ...state,
                placeorderInfo: placeorderInfo,
                paymentStatus: placeorderInfo.status
            }

        case checkoutConstants.GUEST_CHECKOUT_METHOD:
            let checkIsAllowGuestCheckout = action.allowGuestCheckoutMethod;
            //console.log(placeorderInfo)
            return{
                ...state,
                isAllowGuestCheckout: checkIsAllowGuestCheckout
            }

        default:
            return state
    }
}