import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

const TITLE = 'Mi tablero';

class Profile extends Component {

    render(){
        const {isLoggedIn, firstName, lastName} = this.props;
        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-top-dashboard">
                                    <h3 className="tabname">Mi Tablero</h3>
                                    <p className="name-bottom-margin">HOLA, {firstName} {lastName}</p>
                                    <p>Desde el Panel de control de Mi cuenta, puede ver una instantánea de la actividad reciente de su cuenta y actualizar la información de su cuenta. Seleccione un enlace a continuación para ver o editar información.</p>
                                </div>

                                <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        {/* <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li> */}
                                        <li className="dash-button">
                                            <Link to="/profile" className="btn">
                                                Volver a Mi Cuenta
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    firstName: state.user.user.first_name,
    lastName: state.user.user.last_name,
    cartItems: state.cart.cartdata
    //return isLoggedIn;
})

const ProfileComp = connect(
    mapStateToProps
)(Profile)

export default ProfileComp;