import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class ProfileTopSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNavModal: false
        }

        this.openNav = this.openNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    openNav() {
        this.setState({showNavModal: true});
    }
    
    closeNav() {
        this.setState({showNavModal: false});
    }

    render(){
        const closeNavPop = {
            width: '0px'
        }

        const openNavPop = {
            width: '250px'
        }

        let pathName = this.props.match.path.slice(1);
        return(
            <>
            {/* <section className="banner-container">
                <div className="product-title">
                {(() => {
                    if(pathName && pathName==="profile"){
                        return ("Mi Tablero");
                    } else if(pathName && pathName==="myaccount"){
                        return ("Información De la Cuenta");
                    } else if(pathName && pathName==="addressbook"){
                        return ("Directorio");
                    } else if(pathName && pathName==="addressedit"){
                        return ("Editar Direccion");
                    } else if(pathName && pathName==="orders"){
                        return ("Mis Órdenes");
                    } else if(pathName && pathName==="orderview/:orderId"){
                        return ("Pedidos");
                    } else if(pathName && pathName==="myquotations"){
                        return ("Mis Cotizaciones");
                    } else{
                        return ("");
                    }
                })()}
                </div>
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>		      
            </section> */}

            <section className="breadcamp">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/">Home &#62;</Link>
                        <span className="text-blue">
                        {(() => {
                            if(pathName && pathName==="profile"){
                                return ("Mi Tablero");
                            } else if(pathName && pathName==="myaccount"){
                                return ("Información De la Cuenta");
                            } else if(pathName && pathName==="addressbook"){
                                return ("Directorio");
                            } else if(pathName && pathName==="addressedit"){
                                return ("Editar Direccion");
                            } else if(pathName && pathName==="orders"){
                                return ("Mis Órdenes");
                            } else if(pathName && pathName==="orderview/:orderId"){
                                return ("Pedidos");
                            } else if(pathName && pathName==="myquotations"){
                                return ("Mis Cotizaciones");
                            } else{
                                return ("");
                            }
                        })()}
                        </span>
                    </div>
                    </div>
                </div>
            </section>
            
            <section className="filter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mobile">
                        <span className="mobile-hamburger" onClick={this.openNav}>☰</span>
                        {/* Mobile menu */}
                        <div id="mySidenav" className="sidenav" style={this.state.showNavModal ? openNavPop : closeNavPop}>
                            <Link to="#" className="closebtn" onClick={this.closeNav}>×</Link>
                            <nav className="navigation">
                            {/* <h2>Mi cuenta</h2> */}
                            <ul className="mainmenu">
                                <li><Link to="/profile">Dashboard</Link></li>
                                <li><Link to="/myaccount">Mi cuenta</Link></li>
                                <li><Link to="/addressbook">Directory</Link></li>
                                <li><Link to="/orders">Mis Órdenes</Link></li>
                                <li><Link to="/myquotations">Mis Cotizaciones</Link></li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default withRouter(ProfileTopSection);