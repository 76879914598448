import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class ProductImageCarausel extends Component {
    render(){
        return <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="exzoom" id="exzoom">
                    <div className="exzoom_img_box">
                        <ul className='exzoom_img_ul'>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                            <li><img src="images/zoom-pic.jpg" alt="" /></li>
                        </ul>
                    </div>
                    <div className="exzoom_nav"></div>
                    <p className="exzoom_btn">
                        <Link to="" className="exzoom_prev_btn"> </Link>
                        <Link to="" className="exzoom_next_btn"> </Link>
                    </p>
                </div>
            </div>
    }
}