import React, {Component} from 'react';
import { Link } from "react-router-dom";

  export default class DesktopHomeOfferSection extends Component {
    render(){
        return(
            <section className="offer-container desktop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 offer-link-item">
                            <Link to="#">
                                <figure>
                                    <img src="images/new-images/icon/first-shipping.svg" alt="Envío veloz y gratuito" width="42" height="24" />
                                </figure>
                                <div className="heading-wrap">
                                    <h2>Envío veloz y gratuito</h2>
                                    <h3>No esperes demás por tu compra</h3>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 offer-link-item">
                            <Link to="#">
                                <figure>
                                    <img src="images/new-images/icon/buy-pay.svg" alt="Compra y paga en línea" width="23" height="36" />
                                </figure>
                                <div className="heading-wrap">
                                    <h2>Compra y paga en línea</h2>
                                    <h3>Descomplicate con nosotros</h3>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 offer-link-item">
                            <Link to="#">
                                <figure>
                                    <img src="images/new-images/icon/24-7.svg" alt="Tienda online 24/7" width="33" height="33" />
                                </figure>
                                <div className="heading-wrap">
                                    <h2>Tienda online 24/7</h2>
                                    <h3>Siempre recibimos tu pedido</h3>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}