import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'E-Vision / Acerca de Nosotros';

export default class About extends Component {
    render(){
        //const { match, location, history } = this.props;
        return(
            <div className="">
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <section className="banner-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--about d-flex">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <h1 className="banner-title">Sobre nosotras</h1>
                                            <p>Somos una empresa con una larga trayectoria en la venta de electrónica, línea blanca y audio/video; siempre en busca de ofrecerle los mejores precios, una excelente calidad y atención de primera.</p>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="breadcum"><a href="javascript:void(0)">Home</a> <span>&#62;</span> <a href="javascript:void(0)">About Us</a></p>
                            </div>
                        </div>
                    </div>		      
                </section>
                <section className="about-content-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/about1.png`} alt="About us image" rel="noopener noreferrer" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h4>Receive More And Pay Less</h4>
                                <p>At E-Vision you get more for everything: More good designs, more to choose from, greater convenience, better service, more electronics, appliances, video games and much, much more, like you've never seen before. For all this you pay less. As simple as that!</p>
                                <p>Our staff are people prepared to provide you with great service, effective help and great support, they will help you decide which is the item that best suits your needs. Our prices offer you high competitiveness and quality for the design of your lifestyle anywhere, every day.</p>
                            </div>
                        </div>  
                        <div className="row align-items-center margin-spacing">  
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h4>E-Vision Is More Than Electronics</h4>
                                <p>At E-Vision, the individual diversity of our work team and the equally diverse characteristics of our clients make us unique. Our commitment to diversity is a core value, an integral part of the way we display our products, select our merchandise, design our stores, and welcome our customers. This is also reflected in our work team, where each one is a valuable contributor with our goal of providing quality in what we do.</p>
                                <p>Thanks for having us.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <img src={`${process.env.PUBLIC_URL}/images/new-images/about2.png`} alt="About us image" rel="noopener noreferrer" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="line-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}