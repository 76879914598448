import {createStore, compose, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from '../rootReducer';
import {loadState, saveState} from '../Connectivity/localStorage';

const persistedState = loadState();


function configureStoreProd() {
  const middlewares = [
    ReduxThunk
  ];

  const store = createStore(rootReducer, persistedState, compose(
    applyMiddleware(...middlewares)
    )
  );

  return store;
}

function configureStoreDev() {
  const middlewares = [
    ReduxThunk
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(rootReducer, persistedState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
