export const cartConstants = {
    ADD_TO_CART: 'ADD_TO_CART_REQUEST',
    UPDATE_TO_CART: 'UPDATE_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',

    ADD_TO_DATABASE_CART: 'ADD_TO_DATABASE_CART',
    UPDATE_DATABASE_CART: 'UPDATE_DATABASE_CART',
    REMOVE_DATABASE_CART: 'REMOVE_DATABASE_CART',

    ADD_TO_CART_WITH_LOGIN: 'ADD_TO_CART_WITH_LOGIN_REQUEST',

    ADD_TO_DATABASE_CART_FAILURE: 'ADD_TO_DATABASE_CART_FAILURE',
    UPDATE_DATABASE_CART_FAILURE: 'UPDATE_DATABASE_CART_FAILURE',

    ADD_ATTRIBUTE_TO_CART: 'ADD_ATTRIBUTE_TO_CART_REQUEST',
};