import React from "react";
import { Link, Redirect } from "react-router-dom";
//import {PostData} from '../../Services/PostData';
import { connect } from 'react-redux';
import { logIn, login2 } from '../../Services/Actions/user.actions';
import { Helmet } from 'react-helmet';

const TITLE = 'E-Vision Panamá / Login';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

        this.state = {
            username: '',
            password: '',
            errors: {},
            submitted: false,
            isLoading: false
        }
    }

    handleOnChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleFormValidation(){
        let username = this.state.username;
        let password = this.state.password;
        let errors = {};
        let formIsValid = true;

        if(!username){
           formIsValid = false;
           errors["username"] = "Email can not be empty";
        }
    
        if(typeof username !== "undefined"){
           let lastAtPos = username.lastIndexOf('@');
           let lastDotPos = username.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && username.indexOf('@@') === -1 && lastDotPos > 2 && (username.length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["username"] = "Email is not valid";
            }
        }  

        if(!password){
            formIsValid = false;
            errors["password"] = "Password can not be empty";
        }
    
       this.setState({errors: errors});
       return formIsValid;
    }

    handleOnSubmit(e){
        e.preventDefault();
        this.setState({ submitted: true });

        if(this.handleFormValidation()){
            const postData = {
                username: this.state.username,
                password: this.state.password
            }
            const username = this.state.username;
            const password = this.state.password;
            const cartItems = this.props.cartItems;

            this.props.logIn(username, password, cartItems);
            //this.props.logIn(postData);
            //this.setState({username : '', password : ''});
        }

    }
    
    render() {
        const {isLoggedIn, isError, errorMessage} = this.props;
        const { username, password, submitted } = this.state;

        if (isLoggedIn) {
            return <Redirect to={{ pathname: "/profile", state: { referer: this.props.location } }} />;
        }

        const printErrorMessage = (
            <p style={{color: "red"}}>{ isError ? errorMessage : '' }</p>
        );
        
        return (
            <div className="container" style={{marginTop: "15px"}}>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <h3>Log In</h3>
                { printErrorMessage }
                <form onSubmit={this.handleOnSubmit}>
                    <div className="form-group">
                        <label>Email:</label>
                        <input 
                        type="email" 
                        className={'form-control' + (submitted && !username ? ' has-error' : '')} 
                        placeholder="Enter Email"  
                        name="email" 
                        value={username} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input 
                        type="password" 
                        className={'form-control' + (submitted && !password ? ' has-error' : '')} 
                        placeholder="Enter Password" 
                        name="password" 
                        value={password} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                </form>
                <Link to="/signup">Don't have an account?</Link>
            </div> 
        );
    }
}

function mapStateToProps(state) {
    //console.log(state.UserReducer.isLoggedIn);
    return { 
        isLoggedIn: state.user.isLoggedIn,
        isError: state.user.isError,
        errorMessage: state.user.errorMessage,
        cartItems: state.cart.cartData
    }
}

const MapDispachToProps = dispatch => ({
    logIn: () => dispatch(logIn())
})

const actionCreators = {
    logIn:login2
};

const LoginComp = connect(
    mapStateToProps,
    actionCreators
)(LoginPage)

export default LoginComp;