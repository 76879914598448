import React from "react";
import { Link, Redirect } from "react-router-dom";
//import {PostData} from '../../Services/PostData';
import { connect } from 'react-redux';
import { signup } from '../../Services/Actions/user.actions';

class SignupPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

        this.state = {
            firstname: '',
            lastname: '',
            username: '',
            telephone: '',
            password: '',
            errors: {},
            submitted: false,
            isLoading: false
        }
    }

    handleOnChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleFormValidation(){
        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let username = this.state.username;
        let telephone = this.state.telephone;
        let password = this.state.password;
        let errors = {};
        let formIsValid = true;

        if(!firstname){
            formIsValid = false;
            errors["firstname"] = "First Name can not be empty";
        }

        if(!lastname){
            formIsValid = false;
            errors["lastname"] = "Last Name can not be empty";
        }

        if(!username){
           formIsValid = false;
           errors["username"] = "Email can not be empty";
        }
    
        if(typeof username !== "undefined"){
           let lastAtPos = username.lastIndexOf('@');
           let lastDotPos = username.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && username.indexOf('@@') === -1 && lastDotPos > 2 && (username.length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["username"] = "Email is not valid";
            }
        }  

        if(!telephone){
            formIsValid = false;
            errors["telephone"] = "Phone Number can not be empty";
        }

        if(!password){
            formIsValid = false;
            errors["password"] = "Password can not be empty";
        }
    
       this.setState({errors: errors});
       return formIsValid;
    }

    handleOnSubmit(e){
        e.preventDefault();
        this.setState({ submitted: true });

        if(this.handleFormValidation()){
            const first_name = this.state.firstname;
            const last_name = this.state.lastname;
            const email = this.state.username;
            const password = this.state.password;
            const telephone = this.state.telephone;

            this.props.signup(first_name, last_name, email, password, telephone);
            //this.setState({username : '', password : ''});
        }

    }
    
    render() {
        const {isSignedUp, isSignupError, signupErrorMessage} = this.props;
        const { firstname, lastname, username, telephone, password, submitted } = this.state;

        if (isSignedUp) {
            return <Redirect to={{ pathname: "/profile", state: { referer: this.props.location } }} />;
        }

        const printErrorMessage = (
            <p style={{color: "red"}}>{ isSignupError ? signupErrorMessage : '' }</p>
        );
        
        return (
            <div className="container" style={{marginTop: "15px"}}>
                <h3>Signup</h3>
                { printErrorMessage }
                <form onSubmit={this.handleOnSubmit}>
                    <div className="form-group">
                        <label>First Name:</label>
                        <input 
                        type="text" 
                        className={'form-control' + (submitted && !firstname ? ' has-error' : '')} 
                        placeholder="Enter First Name"  
                        name="firstname" 
                        value={firstname} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["firstname"]}</span>
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <input 
                        type="text" 
                        className={'form-control' + (submitted && !lastname ? ' has-error' : '')} 
                        placeholder="Enter Last Name"  
                        name="lastname" 
                        value={lastname} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["lastname"]}</span>
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input 
                        type="username" 
                        className={'form-control' + (submitted && !username ? ' has-error' : '')} 
                        placeholder="Enter Email Id"  
                        name="username" 
                        value={username} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                    </div>
                    <div className="form-group">
                        <label>Telephone:</label>
                        <input 
                        type="text" 
                        className={'form-control' + (submitted && !telephone ? ' has-error' : '')} 
                        placeholder="Enter Telephone Number"  
                        name="telephone" 
                        value={telephone} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["telephone"]}</span>
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input 
                        type="password" 
                        className={'form-control' + (submitted && !password ? ' has-error' : '')} 
                        placeholder="Enter Password" 
                        name="password" 
                        value={password} onChange={this.handleOnChange}
                        />
                        <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                </form>
                <Link to="/login">Existing user? Login Now</Link>
            </div> 
        );
    }
}

function mapStateToProps(state) {
    //console.log(state.UserReducer.isLoggedIn);
    return { 
        isSignedUp: state.user.isSignedUp,
        isSignupError: state.user.isSignupError,
        signupErrorMessage: state.user.signupErrorMessage
    }
}

const actionCreators = {
    signup
};

const SignupComp = connect(
    mapStateToProps,
    actionCreators
)(SignupPage)

export default SignupComp;