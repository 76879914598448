import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'Informacion de la cuenta';

class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '', errors: {},
            firstName: this.props.firstName, lastName: this.props.lastName, emailId: this.props.emailId, mobileNumber: this.props.telephone, companyName: this.props.company, oldPassword: '', newPassword: '', confirmPassword: '',
            isHeaderMenuLoaded: false, accountSubmitted: false, accountLoader: 'hidden', accountDisabled : false,
            accountApiSuccessResponse: '', accountApiErrorResponse: '', accountPasswordSubmitted: false, accountPasswordApiSuccessResponse: '', accountPasswordApiErrorResponse: '',
        }

        this.handleOnAccountInputChange = this.handleOnAccountInputChange.bind(this);
        this.handleOnAccountSubmit = this.handleOnAccountSubmit.bind(this);
        this.handleOnPasswordFormSubmit = this.handleOnPasswordFormSubmit.bind(this);
    }

    handleOnAccountInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleAccountFormValidation(){
        let firstName = this.state.firstName;
        let lastName = this.state.lastName;
        let emailId = this.state.emailId;
        let mobileNumber = this.state.mobileNumber;
        let errors = {};
        let accountFormIsValid = true;

        if(!firstName) {
           accountFormIsValid = false;
           errors["firstName"] = "Please enter your first name.";
        }

        if(!lastName) {
            accountFormIsValid = false;
            errors["lastName"] = "Please enter your last name.";
        }
        
        if(!emailId) {
            accountFormIsValid = false;
            errors["emailId"] = "Please enter your email Address.";
        }

        if(!mobileNumber) {
            accountFormIsValid = false;
            errors["mobileNumber"] = "Please enter your email Address.";
        }
    
        if(typeof emailId !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
            if (!pattern.test(emailId)) {
                accountFormIsValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }
    
       this.setState({errors: errors});
       return accountFormIsValid;
    }

    handleAccountPasswordFormValidation(){
        let oldPassword = this.state.oldPassword;
        let newPassword = this.state.newPassword;
        let confirmPassword = this.state.confirmPassword;
        let errors = {};
        let accountPasswordFormIsValid = true;

        if (!oldPassword) {
            accountPasswordFormIsValid = false;
            errors["oldPassword"] = "Please enter your password.";
        }

        if(newPassword && newPassword !== "") {
            let regex = [];
            regex.push("[A-Z]"); //Uppercase Alphabet.
            regex.push("[a-z]"); //Lowercase Alphabet.
            regex.push("[0-9]"); //Digit.
            regex.push("[!@#$%^&*]"); //Special Character.

            let passed = 0;
            for (let pattern of regex) {
                if (new RegExp(pattern).test(newPassword)) {
                    passed++;
                }
            }

            if (passed > 3) {
                if (!oldPassword) {
                    accountPasswordFormIsValid = false;
                    errors["oldPassword"] = "Please enter your password.";
                } 

                if (!confirmPassword) {
                    accountPasswordFormIsValid = false;
                    errors["confirmPassword"] = "Please enter confirm password.";
                } 

                if(typeof confirmPassword !== "undefined"){
                    if (newPassword !== confirmPassword) {
                        accountPasswordFormIsValid = false;
                        errors["confirmPassword"] = "Confirm Passwords does't match.";
                    }
                }

            } else {
                accountPasswordFormIsValid = false;
                errors["newPassword"] = "Password must contain at least 1 capital letter, 1 small letter, 1 number and 1 special character.";
            }
        }

        if(confirmPassword && confirmPassword !== "") {
            if (!oldPassword) {
                accountPasswordFormIsValid = false;
                errors["oldPassword"] = "Please enter your password.";
            } 

            if (!newPassword) {
                accountPasswordFormIsValid = false;
                errors["newPassword"] = "Please enter confirm password.";
            } 
        }
    
       this.setState({errors: errors});
       return accountPasswordFormIsValid;
    }

    handleOnAccountSubmit(e){
        e.preventDefault();
        this.setState({ accountSubmitted: true, accountApiSuccessResponse: '', accountApiErrorResponse: '' });

        if(this.handleAccountFormValidation()){
            this.setState({ accountLoader: "", accountDisabled: true });
            let customerId = this.props.customerId;
            let firstName = this.state.firstName;
            let lastName = this.state.lastName;
            let emailId = this.state.emailId;
            let mobileNumber = this.state.mobileNumber;
            let companyName = this.state.companyName;

            //const apiUrl = 'https://www.evisionstore.com/api/user/profileupdate-react.php';
            const apiUrl = `${API_BASE_URL}/update-profile`;
        
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "first_name": firstName,
                    "last_name": lastName,
                    "email": emailId,
                    "telephone": mobileNumber,
                    "company": companyName,
                    //"old_password": oldPassword,
                    //"new_password": newPassword
                })
            })
            .then(res => res.json())
            .then((result) => {
                    if(result.code === 200) {
                        this.setState({
                            accountApiSuccessResponse: result.message,
                            accountLoader: "hidden",
                            accountDisabled: false,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });

                        localStorage.setItem("user", JSON.stringify({
                                                                    "customer_id": customerId,
                                                                    "first_name": firstName,
                                                                    "last_name": lastName,
                                                                    "email": emailId,
                                                                    "telephone": mobileNumber,
                                                                    "company": companyName
                                                                }));
                    } else {
                        this.setState({
                            accountApiErrorResponse: result.error,
                            accountLoader: "hidden",
                            accountDisabled: false,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });
                    }
                },
                (error) => {
                    this.setState({ error, accountDisabled: false });
                }
            )
        }
    }

    handleOnPasswordFormSubmit(e){
        e.preventDefault();
        this.setState({ accountPasswordSubmitted: true, accountPasswordApiSuccessResponse: '', accountPasswordApiErrorResponse: '' });

        if (this.handleAccountPasswordFormValidation()) {
            this.setState({ accountLoader: "", accountDisabled: true });

            let oldPassword = this.state.oldPassword;
            let newPassword = this.state.newPassword;
            let confirmPassword = this.state.confirmPassword;

            const apiUrl = `${API_BASE_URL}/update-password`;
        
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "password": oldPassword,
                    "new_password": newPassword,
                    "confirm_password": confirmPassword
                })
            })
            .then(res => res.json())
            .then((result) => {
                    if(result.code === 200) {
                        this.setState({
                            accountPasswordApiSuccessResponse: result.message,
                            accountLoader: "hidden",
                            accountDisabled: false,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });
                    } else {
                        this.setState({
                            accountPasswordApiErrorResponse: result.error,
                            accountLoader: "hidden",
                            accountDisabled: false,
                            //oldPassword: '',
                            //newPassword: '',
                            //confirmPassword: ''
                        });
                    }
                },
                (error) => {
                    this.setState({ error, accountDisabled: false });
                }
            )
        }
    }

    render(){
        const { firstName, lastName, emailId, mobileNumber, companyName, oldPassword, newPassword, confirmPassword, accountSubmitted, accountPasswordSubmitted } = this.state;
        const { isLoggedIn } = this.props;

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-top-dashboard">
                                    <h3 className="tabname">Mi información personal</h3>
                                    <p className=""> Asegúrese de actualizar su información personal si ha cambiado.</p>
                                    <span style={{color: "red"}}>{this.state.accountApiErrorResponse}</span>
                                    <span style={{color: "green"}}>{this.state.accountApiSuccessResponse}</span>
                                    <div className="row userInfo">
                                        <div className="col-lg-12">
                                            
                                            {/* <p className="required"><sup>*</sup> Campo requerido</p> */}
                                        </div>
                                        <form className="col-lg-8 p-0" onSubmit={this.handleOnAccountSubmit}>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputFirstName">Nombre de pila <sup>*</sup> </label> */}
                                                    <input type="text" className={'form-control' + (accountSubmitted && !firstName ? ' has-error' : '')} name="firstName" value={firstName} onChange={this.handleOnAccountInputChange} id="InputFirstName" placeholder="Nombre de pila*" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["firstName"]}</span>
                                                </div>
                                                
                                                
                                                <div className="form-group">
                                                    {/* <label htmlFor="InputMobile"> Teléfono <sup>*</sup> </label> */}
                                                    <input type="text" className={'form-control' + (accountSubmitted && !mobileNumber ? ' has-error' : '')} name="mobileNumber" value={mobileNumber} onChange={this.handleOnAccountInputChange} id="InputMobile" placeholder="Teléfono*"  maxLength="12" />
                                                    <span style={{color: "red"}}>{this.state.errors["mobileNumber"]}</span>
                                                </div>
                                                <div className="form-group">
                                                    {/* <label htmlFor="InputCompany"> Compañía </label> */}
                                                    <input type="text" className={'form-control' + (accountSubmitted && !companyName ? ' has-error' : '')} name="companyName" value={companyName} onChange={this.handleOnAccountInputChange} id="InputCompany" placeholder="Tu Compañía*"  maxLength="100"/>
                                                    <span style={{color: "red"}}>{this.state.errors["companyName"]}</span>
                                                </div>
                                                {/* <div className="form-group required">
                                                    <input type="password" className={'form-control' + (accountSubmitted && !newPassword ? ' has-error' : '')} name="newPassword" value={newPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordnew" placeholder="Ingrese nueva clave" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["newPassword"]}</span>
                                                </div> */}
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputLastName">Apellido <sup>*</sup> </label> */}
                                                    <input type="text" className={'form-control' + (accountSubmitted && !lastName ? ' has-error' : '')} name="lastName" value={lastName} onChange={this.handleOnAccountInputChange} id="InputLastName" placeholder="Apellido*" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["lastName"]}</span>
                                                </div>
                                                <div className="form-group">
                                                    {/* <label htmlFor="InputEmail"> Correo electrónico <sup>*</sup> </label> */}
                                                    <input type="email" className={'form-control' + (accountSubmitted && !emailId ? ' has-error' : '')} name="emailId" value={emailId} onChange={this.handleOnAccountInputChange} id="InputEmail" placeholder="Correo electrónico" readOnly />
                                                    <span style={{color: "red"}}>{this.state.errors["emailId"]}</span>
                                                </div>
                                                
                                                {/* <div className="form-group required">
                                                    <input type="password" className={'form-control' + (accountSubmitted && !oldPassword ? ' has-error' : '')} name="oldPassword" value={oldPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordCurrent" placeholder="Ingresa tu contraseña" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["oldPassword"]}</span>
                                                </div>
                                                
                                                <div className="form-group required">
                                                    <input type="password" className={'form-control' + (accountSubmitted && !confirmPassword ? ' has-error' : '')} name="confirmPassword" value={confirmPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordnewConfirm" placeholder="Confirmar Contraseña" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["confirmPassword"]}</span>
                                                </div> */}
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="margin-top-btn">
                                                    <div className="submit-btn">    
                                                        <button type="submit" className="btn btn-primary" disabled={this.state.accountDisabled}>
                                                            { this.state.accountDisabled ? <i className="fa fa-refresh fa-spin"></i> : <i></i> } 
                                                            { this.state.accountDisabled ? ' Actualizando..' : ' Actualizar' }
                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                        </button>
                                                    </div>
                                                    <Link to="/profile" className="btn">
                                                        Volver a Mi Cuenta
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/*/row end*/}
                                </div>


                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 border-top-dashboard" style={{marginTop: '25px'}}>
                                    <h3 className="tabname">Actualizar Contraseña de la Cuenta</h3>
                                    <span style={{color: "red"}}>{this.state.accountPasswordApiErrorResponse}</span>
                                    <span style={{color: "green"}}>{this.state.accountPasswordApiSuccessResponse}</span>
                                    <div className="row userInfo">
                                        <form className="col-lg-8 p-0" onSubmit={this.handleOnPasswordFormSubmit}>
                                            {/* <div className="col-xs-12 col-sm-6"> */}
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputPasswordCurrent"> Contraseña <sup>*</sup> </label> */}
                                                    <input type="password" className={'form-control' + (accountPasswordSubmitted && !oldPassword ? ' has-error' : '')} name="oldPassword" value={oldPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordCurrent" placeholder="Ingresa tu contraseña" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["oldPassword"]}</span>
                                                </div>
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputPasswordnew"> Nueva contraseña </label> */}
                                                    <input type="password" className={'form-control' + (accountPasswordSubmitted && !newPassword ? ' has-error' : '')} name="newPassword" value={newPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordnew" placeholder="Ingrese nueva clave" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["newPassword"]}</span>
                                                </div>
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputPasswordnewConfirm"> Confirmar contraseña </label> */}
                                                    <input type="password" className={'form-control' + (accountPasswordSubmitted && !confirmPassword ? ' has-error' : '')} name="confirmPassword" value={confirmPassword} onChange={this.handleOnAccountInputChange} id="InputPasswordnewConfirm" placeholder="Confirmar Contraseña" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["confirmPassword"]}</span>
                                                </div>
                                            {/* </div> */}
                                            <div className="col-lg-12">
                                                <div className="margin-top-btn">
                                                    <div className="submit-btn">    
                                                        <button type="submit" className="btn btn-primary" disabled={this.state.accountDisabled}>
                                                            { this.state.accountDisabled ? <i className="fa fa-refresh fa-spin"></i> : <i></i> } 
                                                            { this.state.accountDisabled ? ' Actualizando..' : ' Actualizar Contraseña' }
                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a Mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id,
    firstName: state.user.user.first_name,
    lastName: state.user.user.last_name,
    emailId: state.user.user.email,
    telephone: state.user.user.telephone,
    company: state.user.user.company,
    cartItems: state.cart.cartdata
    //return isLoggedIn;
})

const MyAccountComp = connect(
    mapStateToProps
)(MyAccount)

export default MyAccountComp;