import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateQuotation, removeQuotation, removeAllQuotation } from '../../Services/Actions/quotation.actions';
import { login2 } from '../../Services/Actions/user.actions';

class QuotationListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotationProducts: this.props.quotationProducts,
            submitted: false, requestQuotationLoader: 'hidden', quotationApiResponse: '',
            loginCheck: false, showQuotationModal: false, quotationDisabled: false
        }

        this.quotationRequest = this.quotationRequest.bind(this);
        this.openQuotationResponsePop = this.openQuotationResponsePop.bind(this);
        this.closeQuotationResponsePop = this.closeQuotationResponsePop.bind(this);
    }

    openQuotationResponsePop() {
        this.setState({showQuotationModal: true});
    }
    
    closeQuotationResponsePop() {
        this.setState({showQuotationModal: false});
    }

    handleOnQuanChange(prodId, prodName, prodImage, prodPrice, index, inputval){
        const prodQuantity = inputval;

        if(prodQuantity > 0){
            this.props.updateQuotation(prodId, prodName, prodImage, prodPrice, prodQuantity);
        }
    }

    handleOnQuantityUpdate(type, prodId, prodName, prodImage, prodPrice, index){
        let prodQuantity = parseInt(document.getElementById("quan_"+index).value);
        if(type==="increase"){
            prodQuantity = (prodQuantity + 1);
        }else{
            prodQuantity = (prodQuantity - 1);
        }

        if(prodQuantity!==0){
            this.props.updateQuotation(prodId, prodName, prodImage, prodPrice, prodQuantity);
        }
    }

    handleOnRemoveCart(prodId, index){
        this.props.removeQuotation(prodId, index);
    }

    quotationRequest(){
        let quotationProductsArr = this.props.quotationProducts;
        let customer_id = this.props.customerId;

        if(quotationProductsArr.length > 0 && customer_id !== ""){
            this.setState({ requestQuotationLoader: "", quotationDisabled: true });

            const apiUrl = 'https://www.evisionstore.com/api/user/request_quotation.php';
            
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "customer_id": customer_id,
                    "quotation_products_data": quotationProductsArr
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code===200){
                        this.setState({
                            quotationApiResponse: result.message,
                            requestQuotationLoader: "hidden",
                            isApiSuccess: true,
                            quotationDisabled: false
                        },() =>{
                            this.openQuotationResponsePop();
                            localStorage.removeItem("quotationData", JSON.stringify(this.props.quotationProducts));
                            this.props.removeAllQuotation();
                        });
                    }else{
                        this.setState({
                            quotationApiResponse: result.message,
                            requestQuotationLoader: "hidden",
                            isApiSuccess: false,
                            quotationDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, quotationDisabled: false });
                }
            )
        }
    }

    financial = (x) => Number.parseFloat(x).toFixed(2);

    render() {
        const { quotationProducts } = this.props;
        const { loginCheck } = this.state;

        if(loginCheck){
            return <Redirect to={{ pathname: "/checkout", state: { referer: this.props.location } }} />;
        }
        
        let totalProductPrice = 0;
        let totalTaxAmount = 0;

        if (quotationProducts.length > 0) {
            for (var q = 0, pricelen = quotationProducts.length; q < pricelen; q++) {
                let singleProdPrice = parseFloat(quotationProducts[q]['prodPrice']);
                let singleProdQuan = parseInt(quotationProducts[q]['prodQuantity']);
                totalProductPrice = (totalProductPrice + (singleProdPrice * singleProdQuan)); 
            }

            if (totalProductPrice > 0) {
                totalTaxAmount = (totalProductPrice * (7 / 100));
            }
        }

        if(quotationProducts.length === 0){
            return (
                <>
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12" style={{textAlign:"center"}}>
                                <p>No Quotation Request Have Been Made.</p>
                                <p>
                                    <Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={this.state.showQuotationModal} onHide={this.closeQuotationResponsePop} animation={true}>                          
                    <Modal.Header>
                        <button type="button" className="close" onClick={this.closeQuotationResponsePop}>&times;</button>
                        <Modal.Title>
                            <div className="quote-modal-heading">
                                <i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.quotationApiResponse}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{marginTop: '17px', textAlign: 'center'}}>
                            <Link to="/" className="btn btn-primary btn-lg">
                                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Home
                            </Link>
                        </p>
                    </Modal.Body>     
                </Modal>
                </>
            );
        } else {
            return (
                <>
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-center-xs">
                                <h3 className="section-title-inner">
                                    <span><i className="fa fa-file-text-o" aria-hidden="true" /> Cotización Lista de productos </span>
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row userInfo">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="cartContent w100">
                                            <table className="cartTable table-responsive" style={{width: '100%'}}>
                                                <tbody>
                                                    <tr className="CartProduct cartTableHeader">
                                                        <td style={{width: '15%'}}>PRODUCTO</td>
                                                        <td style={{width: '40%'}}>DETALLES</td>
                                                        <td style={{width: '15%'}} className="delete">&nbsp;</td>
                                                        <td style={{width: '15%'}}>CANT.</td>
                                                        <td style={{width: '15%'}}>TOTAL</td>
                                                    </tr>

                                                    {quotationProducts.map((products, index) => (
                                                    <tr className="CartProduct" key={index}>
                                                        <td className="CartProductThumb">
                                                            <div>
                                                                <Link to={`/producto/${products.prodId}`}>
                                                                    <img src={products.prodImage} alt={products.prodImage} />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="CartDescription">
                                                            <h4><Link to={`/producto/${products.prodId}`}>{products.prodName}</Link></h4>
                                                            <span className="size">{products.prodShortDesc}</span>
                                                            <div className="price"><span>$ {this.financial(products.prodPrice)}</span></div>
                                                            </div>
                                                        </td>
                                                        <td className="delete">
                                                            <Link to="#" title="Delete" onClick={() => this.handleOnRemoveCart(products.prodId, index)}> 
                                                                <i className="glyphicon glyphicon-trash fa-2x" />
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div className="input-group bootstrap-touchspin">
                                                                <span className="input-group-btn">
                                                                    <button onClick={() => this.handleOnQuantityUpdate("decrease", products.prodId, products.prodName, products.prodImage, products.prodPrice, index)} className="btn btn-link bootstrap-touchspin-down" type="button">-</button>
                                                                </span>
                                                                <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                                <input id={`quan_${index}`} onChange={e => this.handleOnQuanChange(products.prodId, products.prodName, products.prodImage, products.prodPrice, index, e.target.value)} value={products.prodQuantity} className="quanitySniper form-control" type="text" name="quanitySniper" />
                                                                <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                                <span className="input-group-btn">
                                                                    <button onClick={() => this.handleOnQuantityUpdate("increase", products.prodId, products.prodName, products.prodImage, products.prodPrice, index)} className="btn btn-link bootstrap-touchspin-up" type="button">+</button>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="price">$ {this.financial(products.prodPrice * products.prodQuantity)}</td>
                                                    </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="cartFooter w100">
                                            <div className="box-footer">
                                                <div className="pull-left">
                                                    <Link to="/" className="btn btn-primary"> <i className="fa fa-arrow-left" /> &nbsp; Seguir comprando </Link>
                                                </div>
                                                <div className="pull-right">
                                                    <button onClick={this.quotationRequest} className="btn btn-success" disabled={this.state.quotationDisabled}>
                                                        <i className={`fa fa-refresh fa-spin ${ this.state.requestQuotationLoader }`}></i>
                                                        {this.state.quotationDisabled ? '' : <i className="fa fa-paper-plane" />}
                                                        &nbsp;Solicitar la Cotización
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>
            );
        }
    }
}

function mapStateToProps(state) {
    localStorage.setItem("quotationData", JSON.stringify(state.quotation.quotationData))
    return { 
        isCartUpdateError: state.quotation.isCartUpdateError,
        quotationProducts: [...state.quotation.quotationData],
        isLoggedIn: state.user.isLoggedIn,
        customerId: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.customer_id
    }
}

const actionCreators = {
    updateQuotation,
    removeQuotation,
    removeAllQuotation,
    logIn: login2
};

const QuotationListingComp = connect(
    mapStateToProps,
    actionCreators
)(QuotationListingSection)

export default QuotationListingComp;