import { quotationConstants } from '../Constants/quotation.constants';

let quotationGetData = localStorage.getItem('quotationData');
let quotationData = false;

if(quotationGetData && quotationGetData !== 'undefined'){
    quotationData = JSON.parse(localStorage.getItem('quotationData'));
}

const initialState = quotationData ? { quotationData } : { quotationData : [] };

export default function QuotationReducer(state = initialState, action) {
    switch (action.type) {
        case quotationConstants.ADD_TO_QUOTATION:
            let product = action.prodObj
            let addedItem = state.quotationData.find(item=> item.prodId === product.prodId)
            let existed_item = state.quotationData.find(item=> product.prodId === item.prodId)

            if(existed_item) {
                addedItem.prodQuantity = parseInt(product.prodQuantity) + parseInt(addedItem.prodQuantity)
                return{
                    ...state,
                    isQuotationAdded: true,
                }
            } else {
                return {
                    ...state,
                    isQuotationAdded: true,
                    quotationData: [...state.quotationData, product]
                }
            }
        
        case quotationConstants.UPDATE_TO_QUOTATION:
            let updatedProduct = action.prodObj
            let updatedItem = state.quotationData.find(item=> item.prodId === updatedProduct.prodId)
            updatedItem.prodQuantity = updatedProduct.prodQuantity
            return{
                ...state
            }

        case quotationConstants.REMOVE_FROM_QUOTATION:
            var index = action.prodObj.prodIndex;
            if (index > -1) { 
                state.quotationData.splice(index, 1);
            }
            return{
                ...state
            }

        case quotationConstants.REMOVE_ALL_FROM_QUOTATION:
            return{
                ...state,
                quotationData: []
            }
            
        default:
            return state
    }
}