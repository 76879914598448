import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class SankeyBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <div className="brandshop-section">
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/fabricantes/sankey">
                            <img src="/images/BS_SANKEY_1.jpg" alt="Product" title="Product" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <Link to="/categorias/linea-blanca/aire-acondicionado?brand=sankey">
                            <img src="/images/BS_SANKEY_3.jpg" alt="Product" title="Product" className="product-img1" />
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <Link to="/categorias/televisores/tv-y-smarttv?brand=sankey">
                            <img src="/images/BS_SANKEY2.jpg" alt="Product" title="Product" className="product-img3" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <Link to="/categorias/audio?brand=sankey">
                            <img src="/images/BS_SANKEY_4.jpg" alt="Product" title="Product" className="product-img4" />
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <Link to="/categorias/linea-blanca/refrigeradoras?brand=sankey">
                            <img src="/images/BS_SANKEY_5.jpg" alt="Product" title="Product" className="product-img2" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/lavadoras?brand=sankey">
                            <img src="/images/BS_SANKEY_6.jpg" alt="Product" title="Product" />
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/microondas?brand=sankey">
                            <img src="/images/BS_SANKEY_7.jpg" alt="Product" title="Product" />
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <Link to="/categorias/linea-blanca/estufas?brand=sankey">
                            <img src="/images/BS_SANKEY_8.jpg" alt="Product" title="Product" className="product-img5" />
                        </Link>
                    </div>
                </div>
            </div>
        );
       
    }
}

export default SankeyBrandListingSection;