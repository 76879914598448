import { filterConstants } from '../Constants/filter.constants';

// const productsGetData = localStorage.getItem('productsData');
// let productsData = false;

// if(productsGetData && productsGetData !== 'undefined'){
//     productsData = JSON.parse(localStorage.getItem('productsData'));
// }
// const totalProducts = (productsData) ? { productsData } : { productsData : [] };

const initialState = { 
    brandData : [],
    allowPurchaseData: 0,
    priceRangeData: [],
    productsData: [],
    filteredProducts:[],
    isFilterProductsLoaded: false,
    dropDownBrandFilterData: [],
    isSortedProductsByPrice: false,
    sortedProductsBy: ""
};

export default function FilterReducer(state = initialState, action) {
    switch (action.type) {
        case filterConstants.RESET_PRODUCT_FILTER:
            return{
                ...state,
                brandData: [],
                allowPurchaseData: 0,
                priceRangeData: [],
                filteredProducts: [],
                isFilterProductsLoaded: false
            }

        case filterConstants.PRODUCT_SET_BY_ID:
            return{
                ...state,
                productsData: action.productsObjects
            }

        case filterConstants.PRODUCT_UNIQUE_BRAND_LISTS:
            return{
                ...state,
                dropDownBrandFilterData: action.brandObjects
            }

        case filterConstants.PRODUCT_LIST_BY_BRANDS:
            var filteredBrandProducts = [];
            let priceRangeData = state.priceRangeData;
            
            if(typeof (priceRangeData.length) === 'undefined' && state.allowPurchaseData == 0) {
                let brandFilter = action.selectedBrandIds.map(itemY => { return itemY.brand });
                let fromRange = state.priceRangeData.fromRange;
                let toRange = state.priceRangeData.toRange;

                filteredBrandProducts = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && 
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else if(typeof (priceRangeData.length) !== 'undefined' && state.allowPurchaseData === 1) {
                let onlineFilterdId = state.allowPurchaseData;
                let brandFilter = action.selectedBrandIds.map(itemY => { return itemY.brand });

                filteredBrandProducts = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && item.allow_purchase == onlineFilterdId 
                );
            } else if(state.allowPurchaseData === 1 && typeof (priceRangeData.length) === 'undefined') {
                let brandFilter = action.selectedBrandIds.map(itemY => { return itemY.brand });
                let onlineFilterdId = state.allowPurchaseData;
                let fromRange = state.priceRangeData.fromRange;
                let toRange = state.priceRangeData.toRange;

                filteredBrandProducts = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && item.allow_purchase == onlineFilterdId &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else {
                let yFilter = action.selectedBrandIds.map(itemY => { return itemY.brand });
                filteredBrandProducts = state.productsData.filter((item) => yFilter.indexOf(item.brand) !== -1 );
                console.log(filteredBrandProducts);
            }

            //console.log(filteredX);

            if (filteredBrandProducts && (filteredBrandProducts.length > 0)) {
                
                if(state.isSortedProductsByPrice && state.sortedProductsBy==="lth"){
                    filteredBrandProducts = filteredBrandProducts.sort((a, b) => a.price - b.price);
                }else if(state.isSortedProductsByPrice && state.sortedProductsBy==="htl"){
                    filteredBrandProducts = filteredBrandProducts.sort((a, b) => b.price - a.price);
                }

                return{
                    ...state,
                    brandData: action.selectedBrandIds,
                    filteredProducts: filteredBrandProducts,
                    isFilterProductsLoaded: true
                }
            } else {
                return {
                    ...state,
                    brandData: action.selectedBrandIds
                }
            }

        case filterConstants.PRODUCT_LIST_BY_ONLINE:
            var filteredOnlineProducts = [];
                
            if (state.brandData.length > 0 && typeof (state.priceRangeData.length) !== 'undefined') {
                let onlineFilterdId = action.selectedAllowPurchaseId;
                let brandFilter = state.brandData.map(itemY => { return itemY.brand });

                filteredOnlineProducts = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && item.allow_purchase == onlineFilterdId
                );
                //console.log(filteredOnlineProducts)
            } else if(state.brandData.length === 0 && typeof (state.priceRangeData.length) === 'undefined') {
                let onlineFilterdId = action.selectedAllowPurchaseId;
                let fromRange = state.priceRangeData.fromRange;
                let toRange = state.priceRangeData.toRange;

                filteredOnlineProducts = state.productsData.filter( (item) => 
                    item.allow_purchase == onlineFilterdId &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else if(state.brandData.length > 0 && typeof (state.priceRangeData.length) === 'undefined') {
                let brandFilter = state.brandData.map(itemY => { return itemY.brand });
                let onlineFilterdId = action.selectedAllowPurchaseId;
                let fromRange = state.priceRangeData.fromRange;
                let toRange = state.priceRangeData.toRange;

                filteredOnlineProducts = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && item.allow_purchase == onlineFilterdId &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else {
                let onlineFilterdId = action.selectedAllowPurchaseId;
                filteredOnlineProducts = state.productsData.filter((item) => item.allow_purchase == onlineFilterdId);
            }

            //console.log(filteredOnlineProducts);

            if (filteredOnlineProducts && (filteredOnlineProducts.length > 0)) {

                if(state.isSortedProductsByPrice && state.sortedProductsBy==="lth"){
                    filteredOnlineProducts = filteredOnlineProducts.sort((a, b) => a.price - b.price);
                }else if(state.isSortedProductsByPrice && state.sortedProductsBy==="htl"){
                    filteredOnlineProducts = filteredOnlineProducts.sort((a, b) => b.price - a.price);
                }

                return{
                    ...state,
                    allowPurchaseData: action.selectedAllowPurchaseId,
                    filteredProducts: filteredOnlineProducts,
                    isFilterProductsLoaded: true
                }
            } else {
                return {
                    ...state,
                    allowPurchaseData: 0
                }
                
            }

        case filterConstants.PRODUCT_LIST_BY_PRICE_RANGE:
            let fromRange = action.priceRangeObj.fromRange;
            let toRange = action.priceRangeObj.toRange;
            var filteredPriceProduct = [];
            
            if(state.brandData.length > 0 && state.allowPurchaseData === 0) {
                let brandFilter = state.brandData.map(itemY => { return itemY.brand });

                filteredPriceProduct = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else if(state.brandData.length === 0 && state.allowPurchaseData === 1) {
                let onlineFilterdId = state.allowPurchaseData;

                filteredPriceProduct = state.productsData.filter( (item) => 
                    item.allow_purchase == onlineFilterdId &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else if(state.brandData.length > 0 && state.allowPurchaseData === 1) {
                let brandFilter = state.brandData.map(itemY => { return itemY.brand });
                let onlineFilterdId = state.allowPurchaseData;

                filteredPriceProduct = state.productsData.filter( (item) => 
                    brandFilter.indexOf(item.brand) !== -1 && item.allow_purchase == onlineFilterdId &&
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            } else {
                filteredPriceProduct = state.productsData.filter( (item) => 
                    parseFloat(item.price) >= fromRange && parseFloat(item.price) <= toRange
                );
            }
            
            //console.log(filteredPriceProduct);
            if (filteredPriceProduct && (filteredPriceProduct.length > 0)) {

                if(state.isSortedProductsByPrice && state.sortedProductsBy==="lth"){
                    filteredPriceProduct = filteredPriceProduct.sort((a, b) => a.price - b.price);
                }else if(state.isSortedProductsByPrice && state.sortedProductsBy==="htl"){
                    filteredPriceProduct = filteredPriceProduct.sort((a, b) => b.price - a.price);
                }

                return{
                    ...state,
                    priceRangeData: action.priceRangeObj,
                    filteredProducts: filteredPriceProduct,
                    isFilterProductsLoaded: true
                }
            }else{
                return {
                    ...state,
                    priceRangeData: []
                }
            }

        case filterConstants.PRODUCT_FILTER_BY_PRICE_TYPE:
            let priceFilterType = action.priceFilterType;
            let filteredPriceTypeProduct = [];

            if (state.isFilterProductsLoaded) {
                let allData = [...state.filteredProducts];
                if (priceFilterType && priceFilterType === "lth") {
                    filteredPriceTypeProduct = allData.sort((a, b) => a.price - b.price);
                }
                if (priceFilterType && priceFilterType === "htl"){
                    //console.log(priceFilterType+"+++");
                    filteredPriceTypeProduct = allData.sort((a, b) => b.price - a.price);
                }
            } else {
                let allData = [...state.productsData];
                if (priceFilterType && priceFilterType === "lth") {
                    //console.log(priceFilterType+"----");
                    filteredPriceTypeProduct = allData.sort((a, b) => a.price - b.price);
                }
                if (priceFilterType && priceFilterType === "htl"){
                    //console.log(priceFilterType+"----");
                    filteredPriceTypeProduct = allData.sort((a, b) => b.price - a.price);
                }
            }
            
            return{
                ...state,
                filteredProducts: filteredPriceTypeProduct,
                isFilterProductsLoaded: true,
                isSortedProductsByPrice: true,
                sortedProductsBy: priceFilterType
            }
        
        case filterConstants.RESET_FILTER_BY_PRICE_RANGE:
            return {
                ...state,
                priceRangeData: [],
                isFilterProductsLoaded: false,
            };
        default:
            return state
    }
}