import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'Revisa';

export default class BannerSection extends Component {

    render(){
        return(
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
        )
    }
}