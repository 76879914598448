import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import CartListingSection from './CartListingSection';

export default class CartPage extends Component {
    componentDidMount(){
        window.Intercom('update', {
            "hide_default_launcher": true
        });
    }
    
    render(){
        return <div className="">
                    <BannerSection />
                    <BreadcrumbSection />
                    <CartListingSection />
                </div>
    }
}