import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from '../../config/api';

class ProductLeftCategoryMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            cat_products: [],
            catProductsLoaded: false,
            selectedParentCatId: false,
            currentChildCategoryId: '',
            isVisible: true,
        }
    }

    toggleMenu = () => {
        this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
    };

    async fetchCategoryData(categorySlug) {
        const apiUrl = `${API_BASE_URL}/category`;
        let requestOptions = {
            method: "POST",
            body: JSON.stringify({ category_slug: categorySlug }),
        };
  
        try {
            let response = await fetch(apiUrl, requestOptions);
            let result = await response.json();
            if (result.code === 200)
            {
              this.setState({
                  catProducts: result.data.menu,
                  selectedParentCatId: result.data.selected_parent_category_id,
                  catProductsLoaded: true,
              }); 
            }
        } catch (error) {
            this.setState({ error });
        }
    }

    componentDidMount() {
        let params = this.props.match.params;
        let topCategorySlug = params.categoryName;
        let subCategorySlug = params.subCategoryName;
        let lastSubCategoryName = params.lastSubCategoryName;
        let categorySlug = "";

        if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined){
            categorySlug = lastSubCategoryName;
        }else if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName == undefined){
            categorySlug = subCategorySlug;
        }else if(topCategorySlug !== undefined && subCategorySlug == undefined && lastSubCategoryName == undefined){
            categorySlug = topCategorySlug;
        }

        this.setState({
            currentChildCategorySlug: categorySlug
        });

        this.fetchCategoryData(categorySlug);

        /* const apiUrl = `${API_BASE_URL}/category`;
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({"category_slug": categorySlug})
        };

        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    selectedParentCatId: result.data.selected_parent_category_id,
                    catProducts: result.data.menu,
                    catProductsLoaded: true
                });
            },
            (error) => {
                this.setState({ error });
            }
        ) */
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params !== this.props.match.params) {
            let params = this.props.match.params;
            let topCategorySlug = params.categoryName;
            let subCategorySlug = params.subCategoryName;
            let lastSubCategoryName = params.lastSubCategoryName;
            let categorySlug = "";

            if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined){
                categorySlug = lastSubCategoryName;
            }else if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName == undefined){
                categorySlug = subCategorySlug;
            }else if(topCategorySlug !== undefined && subCategorySlug == undefined && lastSubCategoryName == undefined){
                categorySlug = topCategorySlug;
            }

            this.setState({
                currentChildCategorySlug: categorySlug
            });

            this.fetchCategoryData(categorySlug);

            /* const apiUrl = `${API_BASE_URL}/category`;
            let requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_slug": categorySlug})
            };
            
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        selectedParentCatId: result.data.selected_parent_category_id,
                        catProducts: result.data.menu,
                        catProductsLoaded: true
                    });
                },
                (error) => {
                    this.setState({ error });
                }
            ) */
        }
    }

    render(){
        const { catProducts, selectedParentCatId, currentChildCategorySlug, isVisible } = this.state;
        
        if (!this.state.catProductsLoaded) {
            return (
              <>
                <h2>
                  <Skeleton height={50} width={`100%`} />
                </h2>
                <Skeleton count={13} style={{ padding: "10px", margin: "2px 0px" }} />
              </>
            );
        }

        return (
            <nav className="navigation">
                <h2 className={`filter-title ${isVisible ? "dropdown-expanded" : "dropdown-collapsed"}`}>
                  <button type="button" className="filter-menu-title" onClick={this.toggleMenu}>
                    Departamentos
                    {!isVisible ? (
                      <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-up.svg`} alt="arrow up" className="toggle-image" />
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-down.svg`} alt="arrow down" className="toggle-image" />
                    )}
                  </button>
                </h2>
                <ul className={`mainmenu ${isVisible ? "visible" : "hidden"}`}>
                {
                catProducts && catProducts.length > 0 ? (catProducts.map((cat_product) =>
                    <li key={cat_product.id} className={cat_product.sub && cat_product.sub.length ? "dropdown-list-menu" : ""}>
                        <Link 
                            to={`/categorias/${cat_product.slug}`} 
                            className={cat_product.id == selectedParentCatId ? "menuactive" : ""}
                        >
                            {cat_product.name}
                        </Link>
                        {cat_product.sub && cat_product.sub.length > 0 && (
                            <ul className="submenu">
                                {cat_product.sub.map((submenu) => (
                                    <li key={submenu.id}>
                                        <Link 
                                            to={`/categorias/${cat_product.slug}/${submenu.slug}`} 
                                            className={submenu.slug === currentChildCategorySlug ? "menuactive" : ""}
                                        >
                                            {submenu.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                )
                ) : (
                <>
                    <h2>
                    <Skeleton height={50} width={`100%`} />
                    </h2>
                    <Skeleton count={13} style={{ padding: "10px", margin: "2px 0px" }} />
                </>
                )
              }
                </ul>
            </nav>
        );

        /* if(this.state.catProductsLoaded){
            return(
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 desktop catagory-left-panel">
                    <nav className="navigation">
                        <h2>Departamentos</h2>
                        <ul className="mainmenu">
                            {catProducts.map(cat_products => 
                                (cat_products.sub).length ? (
                                    <li className="dropdown-list-menu" key={cat_products.id}> 
                                        {(() => {
                                        if (cat_products.id === selectedParentCatId){  
                                            return <Link to={`/categorias/${cat_products.slug}`} className="menuactive">{cat_products.name}</Link>
                                        } else {
                                            return <Link to={`/categorias/${cat_products.slug}`} >{cat_products.name}</Link>
                                        }
                                        })()}
                                        <ul className="submenu">
                                            {cat_products.sub.map(submenu => (
                                                <li key={submenu.id}>
                                                    {(() => {
                                                    if (submenu.slug === currentChildCategorySlug){  
                                                        return <Link to={`/categorias/${cat_products.slug}/${submenu.slug}`} className="menuactive">{submenu.name}</Link>
                                                    } else {
                                                        return <Link to={`/categorias/${cat_products.slug}/${submenu.slug}`} >{submenu.name}</Link>
                                                    }
                                                    })()}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={cat_products.id}> 
                                        {(() => {
                                        if (cat_products.id === selectedParentCatId){  
                                            return <Link to={`/categorias/${cat_products.slug}`} className="menuactive">{cat_products.name}</Link>
                                        } else {
                                            return <Link to={`/categorias/${cat_products.slug}`} >{cat_products.name}</Link>
                                        }
                                        })()} 
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                </div>
            )
        } else {
            return(
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 desktop catagory-left-panel">
                    <h2><Skeleton height={50} width={`100%`} /></h2>
                    <Skeleton count={13} style={{padding: '10px', margin:'2px 0px'}} />
                </div>
            )
        } */
    }
}

export default withRouter(ProductLeftCategoryMenu)