import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class BannerSection extends Component {

    render(){
        let keyword = this.props.match.params.keyword;
        const TITLE = 'E-Vision / Promo: '+keyword.toUpperCase();

        return(
            <>
            <section className="banner-container">
                <div className="product-title">{keyword}</div>
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>		      
            </section>

            <section className="breadcamp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/">Home &raquo;</Link> {capitalizeFirstLetter(keyword)}
                    </div>
                </div>
            </div>
            </section>
            </>
        )
        
    }
}

export default withRouter(BannerSection);