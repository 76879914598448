import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class CanonBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <div className="brandshop-section">
                <div className="container">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Link to="/fabricantes/canon">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-01.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="clear-fx" />
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <Link to="/producto/rebelt8iefs1855isstm">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-02.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <Link to="/producto/EOSM50MARKII">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-03.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="clear-fx" />
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Link to="/producto/eosrp">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-04.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="clear-fx" />
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <Link to="/producto/ef50f1_8stm">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-05.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <Link to="/categorias/fotografia/accesorios-de-fotografia?brand=canon">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-06.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <Link to="/producto/efs1018isstm">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-07.jpg" alt="Product" title="Product" className="product-img5" />
                    </Link>
                  </div>
                  <div className="clear-fx" />
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <Link to="/producto/G3160">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-08.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <Link to="/producto/G4110">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-09.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                  <div className="clear-fx" />
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Link to="/categorias/computadoras/tintas-y-consumibles?brand=canon">
                        <img src="https://www.evisionstore.com/images/BrandShop-Canon-10.jpg" alt="Product" title="Product" />
                    </Link>
                  </div>
                </div>
            </div>
        );
       
    }
}

export default CanonBrandListingSection;