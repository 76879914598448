import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'Orden';

class MyOrderDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            orderId: '',
            orderDetails: {}, 
            orderItems: [],
            orderTotals: {}, 
            isAddressLoaded: false
        }
    }

    componentDidMount() {
        let orderId = this.props.match.params.orderId;
        const apiUrl = `${API_BASE_URL}/order-view`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "customer_id": this.props.customerId, "order_id": orderId})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result)
                if(result.code === 200){
                    this.setState({
                        orderId: result.data.order_details[0].increment_id,
                        orderDetails: result.data.order_details[0],
                        orderItems: result.data.order_items,
                        orderTotals: result.data.order_totals[0],
                        isAddressLoaded: true
                    });
                } else {
                    this.setState({
                        isAddressLoaded: true
                    });
                }
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    render(){
        const {isLoggedIn} = this.props;
        const { orderId, orderDetails, orderItems, orderTotals, isAddressLoaded } = this.state;
        console.log(orderDetails)

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <h3 className="section-title-inner"><span> <i className="fa fa-shopping-basket" aria-hidden="true"></i> Detalles del pedido </span></h3>
                                    <h4>Orden para: #{orderId}</h4>
                                    <div className="row userInfo">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <p className="block-title-2"> Su las direcciones se enumeran a continuación. </p>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <h4>FACTURACIÓN DIRECCIÓN</h4>
                                                <p dangerouslySetInnerHTML={{ __html: orderDetails.billing_address }} /> 
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <h4>DIRECCIÓN DE ENVÍO</h4>
                                                <p dangerouslySetInnerHTML={{ __html: orderDetails.shipping_address }} /> 
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr></hr></div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                <h6>FECHA DE ORDEN</h6>
                                                <p>{orderDetails.order_date}</p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                <h6>MÉTODO DE PAGO</h6>
                                                <p>{orderDetails.payment_method}</p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                <h6>ESTADO DE PAGO</h6>
                                                <p>
                                                {(() => {
                                                    if(orderDetails.payment_status === "SUCCESS"){
                                                        return (<span className="label label-success">{orderDetails.payment_status}</span>);
                                                    } else if (orderDetails.payment_status === "PENDING") {
                                                        return (<span className="label label-warning">{orderDetails.payment_status}</span>);
                                                    } else {
                                                        return (<span className="label label-danger">{orderDetails.payment_status}</span>);
                                                    }
                                                })()}
                                                </p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                <h6>ID DE TRANSACCIÓN</h6>
                                                <p>{orderDetails.transaction_id}</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr></hr></div>

                                        <div className="col-xs-12 col-sm-12">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Nombre del producto</th>
                                                        <th>Precio</th>
                                                        <th>Cantidad</th>
                                                        <th>Total parcial</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderItems.length !== 0 ? (
                                                        orderItems.map((products, index) => (
                                                            <tr key={index}>
                                                                <td>#{products.product_name}</td>
                                                                <td>{products.currency}{products.price}</td>
                                                                <td>{products.qty}</td>
                                                                <td>{products.currency}{products.row_total}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6">No se encontraron pedidos!</td>
                                                        </tr>
                                                    )}

                                                    {orderTotals.length !== 0 ? (
                                                        <>
                                                        <tr>
                                                            <td colSpan="3" style={{textAlign: 'right'}}><strong>Subtotales</strong></td>
                                                            <td>{orderTotals.currency}{orderTotals.subtotal}</td>
                                                        </tr>
                                                        <tr>
                                                        <td colSpan="3" style={{textAlign: 'right'}}><strong>{orderTotals.tax_name}</strong></td>
                                                            <td>{orderTotals.currency}{orderTotals.tax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" style={{textAlign: 'right'}}><strong>Entrega Costo</strong></td>
                                                            <td>{orderTotals.currency}{orderTotals.delivery_cost}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" style={{textAlign: 'right'}}><strong>Descuento</strong></td>
                                                            <td>{orderTotals.currency}{orderTotals.discount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" style={{textAlign: 'right'}}><strong>Cantidad total</strong></td>
                                                            <td>{orderTotals.currency}{orderTotals.grand_total}</td>
                                                        </tr>
                                                        </>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6">No se encontraron pedidos!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*/row end*/}
                                </div>

                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a Mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id
})

const MyOrderDetailsComp = connect(
    mapStateToProps
)(MyOrderDetails)

export default withRouter(MyOrderDetailsComp);