import { userConstants } from '../Constants/user.constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? 
{ 
    isLoggedIn: true, 
    loginErrorMessage: '', 
    loginSuccessMessage: '',
    loginLoader: 'hidden',
    loginFormButtonDisabled : false,
    user 
} 
: 
{ 
    isLoggedIn: false, 
    isError: false, 
    loginErrorMessage: '', 
    loginSuccessMessage: '',
    loginLoader: 'hidden',
    loginFormButtonDisabled : false,
    isSignupError: false, 
    signupErrorMessage: '' 
};

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                isLogginIn: true,
                loginLoader: '',
                loginFormButtonDisabled : true,
                //user: action.username
            }
        case userConstants.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                isError: false,
                loginErrorMessage: '',
                loginSuccessMessage: 'You have successfully logged in to your account.'
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.user,
                isError: false,
                loginErrorMessage: '',
                loginSuccessMessage: 'You have successfully logged in to your account.',
                loginLoader: 'hidden',
                loginFormButtonDisabled : false
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                isError: true,
                loginErrorMessage: action.error,
                loginSuccessMessage: '',
                loginLoader: 'hidden',
                loginFormButtonDisabled : false
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                loginErrorMessage: '',
                loginSuccessMessage: '',
                user: {}
            }  
        case userConstants.SIGNUP_REQUEST:
            return {
                ...state,
                isSigninIn: true
            } 
        case userConstants.SIGNUP_SUCCESS:
            return {
                ...state,
                isSignedUp: true,
                isLoggedIn: true,
                user: action.user,
                signupErrorMessage: '',
                isSignupError: false
            };
        case userConstants.SIGNUP_FAILURE:
            return {
                ...state,
                isSignupError: true,
                signupErrorMessage: action.error
            }; 
        default:
            return state
    }
}