import { quotationConstants } from "../Constants/quotation.constants";

export function addToQuotation(prodId, prodName, prodImage, prodPrice, prodQuantity, prodShortDesc) {
    var prodObj = {'prodId':prodId,'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':prodQuantity, 'prodShortDesc':prodShortDesc}
 
    return dispatch => {
        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                dispatch(request(prodObj));
            }
        }
    }
    function request(prodObj) { return { type: quotationConstants.ADD_TO_QUOTATION, prodObj } }
}

export function updateQuotation(prodId, prodName, prodImage, prodPrice, prodQuantity, prodShortDesc) {
    var prodObj = {'prodId':prodId,'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':prodQuantity, 'prodShortDesc':prodShortDesc}
 
    return dispatch => {
        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                dispatch(request(prodObj));
            }
        }
    }
    function request(prodObj) { return { type: quotationConstants.UPDATE_TO_QUOTATION, prodObj } }
}

export function removeQuotation(prodId, index) {
    var prodObj = {'prodId':prodId, 'prodIndex':index}

    return dispatch => {
        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                dispatch(request(prodObj));
            }
        }
    }
    function request(prodObj) { return { type: quotationConstants.REMOVE_FROM_QUOTATION, prodObj } }
}

export function removeAllQuotation() {

    return dispatch => {
        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                dispatch(request());
            }
        }
    }
    function request() { return { type: quotationConstants.REMOVE_ALL_FROM_QUOTATION } }
}
