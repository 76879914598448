import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class DrijaBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <div className="brandshop-section brandshop-drija">
                <div className="container">

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <video className="drija-mp4-1" width={1130} height={500} poster="/images/header.jpg" preload="auto" autoPlay="autoplay" loop="loop">
                            <source src="/images/header.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/estufas?brand=drija" target="_blank">
                            <img src="/images/banner-estufas.jpg" alt="estufas" className="drija-img-1"/>
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/microondas?brand=drija" target="_blank">
                            <img src="/images/banner-mircroondas.jpg" alt="" className="drija-img-2"/>
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/categorias/linea-blanca/extractores-de-grasa?brand=drija">
                            <video className="drija-mp4-2" width={1130} height={400} poster="/images/extractor-video.jpg" preload="auto" autoPlay="autoplay" loop="loop">
                              <source src="/images/extractor-video.mp4" type="video/mp4" />
                            </video>
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/hornos-empotrables?brand=drija" target="_blank">
                            <img src="/images/banner-hornos.jpg" alt="" className="drija-img-3"/>
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/refrigeradoras/compactas-mini-bar?brand=drija" target="_blank">
                            <img src="/images/banner-vineras.jpg" alt="" className="drija-img-4"/>
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <Link to="/categorias/extractores-de-grasa?brand=drija" target="_blank">
                            <img src="/images/boton-extractores.jpg" alt="" className="drija-img-6"/>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <Link to="/categorias/linea-blanca/estufas?brand=drija" target="_blank">
                            <img src="/images/boton-estufas.jpg" alt="" className="drija-img-6" />
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <Link to="/categorias/linea-blanca/hornos-empotrables?brand=drija" target="_blank">
                            <img src="/images/boton-hornos.jpg" alt="" className="drija-img-6" />
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <Link to="/categorias/linea-blanca/refrigeradoras/compactas-mini-bar?brand=drija" target="_blank">
                            <img src="/images/boton-vineras.jpg" alt="" className="drija-img-6" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <img src="/images/footer.jpg" alt="" className="drija-img-7" />
                    </div>
                </div>
            </div>
        );
       
    }
}

export default DrijaBrandListingSection;