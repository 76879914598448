import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';
import { history } from '../../Services/history';

const TITLE = 'Directorio';

class MyAddressBook extends Component {

    constructor(props) {
        console.log("Props: ", props)
        super(props);
        this.state = {
            error: '',
            customer_name: '', 
            company: '',
            address1: '', 
            address2: '', 
            city_name: '', 
            state_name: '', 
            country_name: '', 
            pincode: '', 
            isAddressLoaded: false
        }
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/user/address.php';
        const apiUrl = `${API_BASE_URL}/address`;
        /* const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ "customer_id": this.props.customerId })
        }; */
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("Result: ", result);
                if(result.status === true && result.code === 200){
                    this.setState({
                        customer_name: result.data.customer_name,
                        company: result.data.company,
                        address1: result.data.address1,
                        address2: result.data.address2,
                        city_name: result.data.city_name,
                        state_name: result.data.state_name,
                        country_name: result.data.country_name,
                        pincode: result.data.pincode,
                        isAddressLoaded: true
                    });
                } else {
                    console.log("Logout");
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    window.location.reload(true);
                    //history.push('/');
                    this.setState({
                        isAddressLoaded: true
                    });
                }
            },
            (error) => {
                console.log("error: ", error);
                this.setState({ error: error });
            }
        )
    }

    render(){
        const {isLoggedIn} = this.props;
        const { customer_name, company, address1, address2, city_name, state_name, country_name, pincode } = this.state;

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <h3 className="tabname">Mis direcciones</h3>
                                    <p>Establezca su dirección de facturación y entrega predeterminada al realizar un pedido. También puede agregar direcciones adicionales, que pueden ser útiles para enviar regalos o recibir un pedido en su oficina.</p>
                                    <div className="row userInfo">
                                        <div className="col-lg-12">
                                            <p className="block-title-2"> Su dirección está abajo. </p>
                                            <p> Asegúrese de actualizar su información personal si ha cambiado.</p>
                                        </div>
                                        <div className="col-lg-8 col-md-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">Mis direcciones</h3>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="tittle-area">
                                                            <h4>{customer_name}</h4>
                                                            <Link to="/addressedit" className="edit-btn"> 
                                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/edit-pen.svg`} alt="Edit Pen" width="10" height="10" />
                                                                Editar 
                                                            </Link> 
                                                        </div>
                                                        <div className="address-box-output row">
                                                            {/* <li><span className="address-name"> </span></li> */}
                                                            <div className="col-md-6">
                                                                <p><span className="address-company">Compañía: {company}</span></p>
                                                                <p><span className="address-line1">Dirección 1: {address1}</span></p>
                                                                <p><span className="address-line2">Dirección 2: {address2}</span></p>
                                                                <p><span className="address-city">Ciudad: {city_name}</span></p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p><span className="address-state">Estado: {state_name}</span></p>
                                                                <p><span className="address-state">País: {country_name}</span></p>
                                                                <p><span className="address-state">Código Pin: {pincode}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="panel-footer panel-footer-address">
                                                        
                                                        <Link to="#" className="btn btn-sm btn-danger"> 
                                                            <i className="fa fa-minus-circle" /> Delete 
                                                        </Link>
                                                    </div> */}
                                                </div>
                                        </div>
                                    </div>
                                    {/*/row end*/}
                                </div>

                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a Mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id
})

const MyAddressBookComp = connect(
    mapStateToProps
)(MyAddressBook)

export default MyAddressBookComp;