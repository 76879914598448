import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import ProductSearchAutocomplete from './ProductSearchAutocomplete';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import API_BASE_URL from '../../config/api';
import { login2, logOut, signup } from '../../Services/Actions/user.actions';

class MobileHeaderSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            username: '', password: '',
            firstname: '', lastname: '', email: '', telephone: '', regpassword: '',
            forgotpassemail: '',
            errors: {}, forgotPassLoader: 'hidden', loginLoader: 'hidden', registrationLoader: 'hidden', loginFormButtonDisabled : false,
            showModal: false,
            submitted: false, errorMessage: '', apiErrorMessage: '', apiSuccessMessage: '',
            loginSubmitted: false, registrationSubmitted: false, forgotPasswordSubmitted: false, forgotPasswordApiSuccessResponse: '', forgotPasswordApiErrorResponse: '',
            loginCheck: false,
            loginFormShowHide: "", registrationFormShowHide: "hidden", forgotPasswordShowHide: "hidden",
            headerMenuArray: {},
            isHeaderMenuLoaded: false
        }

        this.handleOnLoginInputChange = this.handleOnLoginInputChange.bind(this);
        this.handleOnRegistrationInputChange = this.handleOnRegistrationInputChange.bind(this);
        this.handleOnLoginSubmit = this.handleOnLoginSubmit.bind(this);
        this.handleOnRegistrationSubmit = this.handleOnRegistrationSubmit.bind(this);
        this.handleOnForgotPasswordSubmit = this.handleOnForgotPasswordSubmit.bind(this);
        this.logOut = this.logOut.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    handleOnLoginInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleOnRegistrationInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleLoginFormValidation(){
        let username = this.state.username;
        let password = this.state.password;
        let errors = {};
        let loginFormIsValid = true;

        if(!username){
           loginFormIsValid = false;
           errors["username"] = "Email can not be empty";
        }
    
        if(typeof username !== "undefined"){
           let lastAtPos = username.lastIndexOf('@');
           let lastDotPos = username.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && username.indexOf('@@') === -1 && lastDotPos > 2 && (username.length - lastDotPos) > 2)) {
              loginFormIsValid = false;
              errors["username"] = "Email is not valid";
            }
        }  

        if(!password){
            loginFormIsValid = false;
            errors["password"] = "Password can not be empty";
        }
    
       this.setState({errors: errors});
       return loginFormIsValid;
    }

    handleOnLoginSubmit(e){
        e.preventDefault();
        this.setState({ loginSubmitted: true });

        if(this.handleLoginFormValidation()){
            this.setState({ loginLoader: "", loginFormButtonDisabled: true });
            const username = this.state.username;
            const password = this.state.password;
            const cartItems = this.props.cartItems;
            
            this.props.logIn(username, password, cartItems);
            setTimeout(()=> 
                this.setState({
                    loginCheck: this.props.isLoggedIn 
                },() => {
                    if(this.state.loginCheck){
                        this.setState({apiSuccessMessage: 'You have successfully logged in to your account.', loginFormButtonDisabled: false, loginLoader: "hidden"});
                        setTimeout(()=> 
                            this.close(),
                        2000);
                    }
                    if(this.props.isError){
                        this.setState({apiErrorMessage: this.props.errorMessage, loginFormButtonDisabled: false, loginLoader: "hidden"})
                    }
                }),
            2000);

        }
    }

    handleRegistrationFormValidation(){
        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let email = this.state.email;
        let telephone = this.state.telephone;
        let regpassword = this.state.regpassword;
        let errors = {};
        let registrationFormIsValid = true;

        if(!firstname){
            registrationFormIsValid = false;
            errors["firstname"] = "Please enter your first name";
        }

        if(!lastname){
            registrationFormIsValid = false;
            errors["lastname"] = "Please enter your last name";
        }

        if(!telephone){
            registrationFormIsValid = false;
            errors["telephone"] = "Please enter your phone number.";
        } else{
            if(telephone.length < 8 || telephone.length > 15){
                registrationFormIsValid = false;
                errors["telephone"] = "Please enter your valid phone number.";
            }
        }
    
        if(typeof email !== "undefined"){
           let lastAtPos = email.lastIndexOf('@');
           let lastDotPos = email.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
              registrationFormIsValid = false;
              errors["email"] = "Email id is not valid";
            }
        } else{
            registrationFormIsValid = false;
            errors["email"] = "Please enter your username.";
        }  

        if(!regpassword){
            registrationFormIsValid = false;
            errors["regpassword"] = "Password can not be empty";
        }else{
            if(regpassword.length < 3){
                registrationFormIsValid = false;
                errors["regpassword"] = "Please enter minimum 4 characters password.";
            }
        }
    
       this.setState({errors: errors});
       return registrationFormIsValid;
    }

    handleOnRegistrationSubmit(e){
        e.preventDefault();
        this.setState({ registrationSubmitted: true });

        if(this.handleRegistrationFormValidation()){
            this.setState({ registrationLoader: "", loginFormButtonDisabled: true });
            const first_name = this.state.firstname;
            const last_name = this.state.lastname;
            const email = this.state.email;
            const password = this.state.regpassword;
            const telephone = this.state.telephone;

            this.props.signup(first_name, last_name, email, password, telephone);
            setTimeout(()=> 
                this.setState({
                    loginCheck: this.props.isLoggedIn 
                },() => {
                    if(this.state.loginCheck){
                        this.setState({apiSuccessMessage: 'Your account has been successfully registered on Evision.', loginFormButtonDisabled: false, registrationLoader: "hidden"});
                        setTimeout(()=> 
                            this.close(),
                        2000);
                    }
                    if(this.props.isSignupError){
                        this.setState({apiErrorMessage: this.props.signupErrorMessage, loginFormButtonDisabled: false, registrationLoader: "hidden"})
                    }
                }),
            2000);
        }
    }

    handleForgotPassFormValidation(){
        let forgotpassemail = this.state.forgotpassemail;
        let errors = {};
        let forgotPasswordFormIsValid = true;
    
        if(typeof forgotpassemail !== "undefined"){
           let lastAtPos = forgotpassemail.lastIndexOf('@');
           let lastDotPos = forgotpassemail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && forgotpassemail.indexOf('@@') === -1 && lastDotPos > 2 && (forgotpassemail.length - lastDotPos) > 2)) {
              forgotPasswordFormIsValid = false;
              errors["forgotpassemail"] = "Your registered email id is not valid";
            }
        } else {
            forgotPasswordFormIsValid = false;
            errors["forgotpassemail"] = "Please enter your registered email id";
        } 
    
       this.setState({errors: errors});
       return forgotPasswordFormIsValid;
    }

    handleOnForgotPasswordSubmit(e){
        e.preventDefault();
        this.setState({ forgotPasswordSubmitted: true });

        if(this.handleForgotPassFormValidation()){
            this.setState({ forgotPassLoader: "", loginFormButtonDisabled: true });
            let forgotpassemail = this.state.forgotpassemail;

            //const apiUrl = 'https://www.evisionstore.com/api/user/forgotpassword.php';
            const apiUrl = `${API_BASE_URL}/forgot-password`;

            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "email": forgotpassemail
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code === 200){
                        this.setState({
                            forgotPasswordApiSuccessResponse: result.message,
                            forgotPassLoader: "hidden",
                            loginFormButtonDisabled: false
                        });
                    }else{
                        this.setState({
                            forgotPasswordApiErrorResponse: result.message,
                            forgotPassLoader: "hidden",
                            loginFormButtonDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, forgotPassLoader: "hidden", loginFormButtonDisabled: false });
                }
            )
        }
    }

    handleOnFormChange(buttonParam) {
        if(buttonParam==="registration") {
            this.setState({
                loginFormShowHide: 'hidden',
                registrationFormShowHide: '',
                forgotPasswordShowHide: 'hidden'
            });
        } else if(buttonParam==="login") {
            this.setState({
                loginFormShowHide: '',
                registrationFormShowHide: 'hidden',
                forgotPasswordShowHide: 'hidden'
            });
        } else if(buttonParam==="forgotpassword"){
            this.setState({
                loginFormShowHide: 'hidden',
                registrationFormShowHide: 'hidden',
                forgotPasswordShowHide: ''
            });
        }
    }

    open() {
        this.setState({showModal: true});
    }
    
    close() {
        this.setState({showModal: false});
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/home/headermenu.php';
        const apiUrl = `${API_BASE_URL}/menu`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"menutype": "header"})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    headerMenuArray: result.data.headermenu,
                    isHeaderMenuLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    logOut(){
        this.props.logOut();
    }

    render(){
        const {isLoggedIn, isSignedUp, cartCount} = this.props;
        const { username, password, firstname, lastname, email, telephone, regpassword, loginSubmitted, registrationSubmitted, forgotPasswordSubmitted, forgotpassemail, loginCheck } = this.state;
        const { headerMenuArray } = this.state;

        const apiError = {
            color: 'red', fontSize: '16px', background: '#fff9b0', padding: '0px 5px 2px 5px', fontWeight: 'normal', textAlign: 'center', border: '1px solid red', borderRadius: '3px'
        }

        const apiSuccess = {
            color: '#316403', fontSize: '16px', background: '#cbffb0', padding: '0px 5px 2px 5px', fontWeight: 'normal', textAlign: 'center', border: '1px solid #178603', borderRadius: '3px'
        }

        if(loginCheck){
            window.location.reload(false);
        }

        if (isSignedUp) {
            return <Redirect to={{ pathname: "/profile", state: { referer: this.props.location } }} />;
        }

        const AfterLoggedIn = (
            <li>
                <Link to="/profile"><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-login.svg`} alt="" className="icon-float" /></Link>
                {/* <ul>
                    <li><Link to="/profile">Mi Profile</Link></li>
                    <li><Link to="#" onClick={this.logOut}>Logout</Link></li>
                </ul> */}
            </li>
        );

        const BeforeLoggedIn = (
            <li>
                <Link to="#" onClick={this.open}>
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-login.svg`} alt="Iniciar sesión" className="icon-float" />
                </Link>
            </li>
        );

        return(
            <header className="mobile">
                <div className="header-top-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-8">
                                <div className="header-phone">
                                    <ul style={{padding: '0px'}}>
                                        <li><Link to="/sucursales"><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/branch-icon.svg`} alt="" className="icon-float" /></Link></li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=50764447679&text=Guarda nuestro número en tus contactos para recibir nuestra newsletter. Y después envía este mensaje para comenzar.">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/whatsapp-icon.svg`} alt="" className="icon-float" />
                                            </a>
                                        </li>
                                        <li><a href="tel:+507-3021030"><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-phone.svg`} alt="" className="icon-float" /></a></li>
                                        <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/mobile-info.svg`} alt="" className="icon-float" /></Link></li>
                                        <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/mobile-header-chat.svg`} alt="" className="icon-float" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-4">
                                { isLoggedIn ? <Link to="#" onClick={this.logOut} style={{position:'relative', float:'right'}}><img src={`${process.env.PUBLIC_URL}/images/new-images/icon/logout-header-top.svg`} alt="Continue Guiest" width="24" height="24" style={{margin:'0 15px 0 0', width:'30px'}} className="icon-float" />
                                {/* Cerrar sesión */}
                                </Link> : '' }
                                {/*<div className="offer-tab">
                                     <Link to="#"><img src={`${process.env.PUBLIC_URL}/images/tag-icon.png`} alt="" className="icon-float" />Vaya Cosa</Link> 
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header1-area header-two">
                    <div className="header-top-area" id="sticker">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="navbar navbar-default " role="navigation">
                                        <div className="navbar-header">
                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                                <span className="sr-only">Toggle navigation</span>
                                                <span className="icon-bar" />
                                                <span className="icon-bar" />
                                                <span className="icon-bar" />
                                            </button>
                                        </div>
                                        <div className="collapse navbar-collapse">
                                            <ul className="nav navbar-nav">
                                                <li><Link to="/">Inicio</Link></li>
                                                <li>
                                                    <Link to="#" className="dropdown-toggle" data-toggle="dropdown">Departamentos </Link>
                                                    {headerMenuArray.length !== 0 && (
                                                        <ul className="dropdown-menu multi-level">
                                                            {Object.values(headerMenuArray).map(({category_name, category_id, category_slug, submenu}, index) => (
                                                                <li key={index}>
                                                                    {typeof(submenu) !== 'undefined' && submenu.length > 0 ? (
                                                                        <>
                                                                        <Link to={`/categorias/${category_slug}`}>{category_name}</Link>
                                                                        <ul>
                                                                        {Object.values(submenu).map(({category_name, category_slug, category_id}, subindex) => (
                                                                            <li key={subindex}>
                                                                                <Link to={`/product-category/${category_id}`}>{category_name}</Link>
                                                                            </li>
                                                                        ))}
                                                                        </ul>
                                                                        </>
                                                                    ) : (
                                                                        <Link to={`/categorias/${category_slug}`}>{category_name}</Link>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                                <li><Link to="/contacto">Contáctenos</Link></li>
                                                <li><Link to="#">Tienda online</Link></li>
                                            </ul>
                                            {/* <ul className="nav navbar-nav navbar-right">
                                                <li>
                                                    <Link to="#"><img src={`${process.env.PUBLIC_URL}/images/tag-icon.png`} alt="" className="icon-float" /> Oferta Especial</Link>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                    
                                    <div className="logo-area">
                                        <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" /></Link>
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="main-menu">
                                        <ul>
                                            { isLoggedIn ? AfterLoggedIn : BeforeLoggedIn }
                                            
                                            <li>
                                                <Link to="/cart">
                                                    <div className="cart-counter">{cartCount}</div>
                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} alt="" className="icon-float" />
                                                </Link>
                                                {/* <ul>
                                                <li><Link to="#">Vaya Cosa</Link></li>
                                                <li><Link to="#">Mi Cuenta</Link></li>
                                                <li><Link to="/cart">Mi Carrito</Link></li>
                                                </ul>  */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <ProductSearchAutocomplete />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showModal} onHide={this.close} animation={true}>                             
                    <Modal.Body>
                        <div className="popupbg"> 
                            <button type="button" className="close" onClick={this.close}>&times;</button>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 right-form-area mobile-logo-login">
                                <div className="right-form-logo">
                                    <img src={`${process.env.PUBLIC_URL}/images/popup-logo.png`} title="logo" alt="logo" />
                                </div>
                                <h2> Iniciar sesion</h2>
                                <h3> Obtenga acceso
                                sus cuentas y
                                pedidos</h3>
                                
                                <div className={`form-wrap ${this.state.apiErrorMessage ? '' : 'hidden'} `}>
                                    <label style={apiError}>{this.state.apiErrorMessage}</label>
                                </div>
                                <div className={`form-wrap ${this.state.apiSuccessMessage ? '' : 'hidden'} `}>
                                    <label style={apiSuccess}>{this.state.apiSuccessMessage}</label>
                                </div>
                                <form className={`login_form ${ this.state.loginFormShowHide }`} id="login-form" onSubmit={this.handleOnLoginSubmit}>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`} alt="Mail Icon" rel="noopener noreferrer" />
                                        </span>
                                        <input className={'input-text' + (loginSubmitted && !username ? ' has-error' : '')} type="email" name="username" value={username} onChange={this.handleOnLoginInputChange} placeholder="Enter Your Email" maxLength="70"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`} alt="Mail Icon" rel="noopener noreferrer" />
                                        </span>
                                        <input className={'input-text' + (loginSubmitted && !password ? ' has-error' : '')} type="password" name="password" value={password} onChange={this.handleOnLoginInputChange} maxLength="50" placeholder="Enter Your Password"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                    </div>
                                    <div className="text-center">
                                        <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("forgotpassword")}>¿Olvidaste tu contraseña? </Link>
                                    </div>
                                    <div className="signup-btn-wrap">
                                        <button className="btn" value="Login" id="logins" type="submit" disabled={this.state.loginFormButtonDisabled}>
                                            <i className={`fa fa-refresh fa-spin ${ this.state.loginLoader }`} style={{fontSize:"17px"}}></i> Iniciar sesion
                                        </button>
                                    </div>
                                    <div className="reg-btn-wrap justify-content-center">
                                        <p>No tienes una cuenta. </p>
                                        <button className="btn" type="button" onClick={e => this.handleOnFormChange("registration")} >Regístrate</button>
                                    </div>
                                </form>

                                <form className={`registration_form ${ this.state.registrationFormShowHide }`} id="registration-form" onSubmit={this.handleOnRegistrationSubmit}>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/new-images/icon/name-icon.svg`}
                                                    alt="Mail Icon"
                                                    rel="noopener noreferrer"
                                                />
                                        </span>
                                        <input className={'input-text' + (registrationSubmitted && !firstname ? ' has-error' : '')} name="firstname" value={firstname} onChange={this.handleOnLoginInputChange} placeholder="Enter Your First Name" maxLength="70"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["firstname"]}</span>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/new-images/icon/name-icon.svg`}
                                                    alt="Mail Icon"
                                                    rel="noopener noreferrer"
                                                />
                                        </span>
                                        <input className={'input-text' + (registrationSubmitted && !lastname ? ' has-error' : '')} name="lastname" value={lastname} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Last Name" maxLength="50"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["lastname"]}</span>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
                                                alt="Mail Icon"
                                                rel="noopener noreferrer"
                                            />
                                        </span>
                                        <input className={'input-text' + (registrationSubmitted && !email ? ' has-error' : '')} type="email" name="email" value={email} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Username" maxLength="50"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/new-images/icon/phone-icon.svg`}
                                                alt="Mail Icon"
                                                rel="noopener noreferrer"
                                            />
                                        </span>
                                        <input className={'input-text' + (registrationSubmitted && !telephone ? ' has-error' : '')} name="telephone" value={telephone} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Telephone Number" maxLength="50"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["telephone"]}</span>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="input-wrap">
                                        <span className="login-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`}
                                                alt="Mail Icon"
                                                rel="noopener noreferrer"
                                            />
                                        </span>
                                        <input className={'input-text' + (registrationSubmitted && !regpassword ? ' has-error' : '')} type="password" name="regpassword" value={regpassword} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Password" maxLength="50"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["regpassword"]}</span>
                                    </div>
                                    <div className="text-center mb-4">
                                    <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("login")}>Usuario existente? Iniciar sesion </Link>
                                    </div>
                                    <div className="reg-btn-wrap reg-signup-btn">
                                    <button className="btn" value="Registration" id="reg" type="submit" disabled={this.state.loginFormButtonDisabled}>
                                        <i className={`fa fa-refresh fa-spin ${ this.state.registrationLoader }`} style={{fontSize:"17px"}}></i> Regístrate
                                    </button>
                                    </div>
                                    <div className="reg-btn-wrap justify-content-center mt-4 pb-4">
                                        <p>Ya tienes una cuenta.</p>
                                        <button className="btn" type="button" onClick={e => this.handleOnFormChange("login")}>Iniciar sesion</button>
                                    </div>
                                </form>

                                <form className={`forgot_pass_form ${ this.state.forgotPasswordShowHide }`} id="forgot-password-form" onSubmit={this.handleOnForgotPasswordSubmit}>
                                    <div className="form-wrap">
                                    <div className="input-wrap">
                                        <span className="login-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
                                                alt="Mail Icon"
                                                rel="noopener noreferrer"
                                            />
                                        </span>
                                        <input className={'input-text' + (forgotPasswordSubmitted && !forgotpassemail ? ' has-error' : '')} type="email" name="forgotpassemail" value={forgotpassemail} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Email" maxLength="50"/>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["forgotpassemail"]}</span>
                                        <span style={{color: "green"}}>{this.state.forgotPasswordApiSuccessResponse}</span>
                                        <span style={{color: "red"}}>{this.state.forgotPasswordApiErrorResponse}</span>
                                    </div>
                                    <div className="text-center">
                                    <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("login")}> Atrás para iniciar sesión? </Link>
                                    </div>
                                    <div className="signup-btn-wrap">
                                        <button className="btn" value="Forgot Password" type="submit" disabled={this.state.loginFormButtonDisabled}>
                                            <i className={`fa fa-refresh fa-spin ${ this.state.forgotPassLoader }`} style={{fontSize:"17px"}}></i> Enviar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>       
                </Modal>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    isSignedUp: state.user.isSignedUp,
    isLoggedIn: state.user.isLoggedIn,
    cartCount: state.cart.cartCount,
    cartItems: state.cart.cartData,
    isError: state.user.isError,
    errorMessage: state.user.errorMessage,
    isSignupError: state.user.isSignupError,
    signupErrorMessage: state.user.signupErrorMessage
    //return isLoggedIn;
})

const actionCreators = {
    logIn: login2,
    signup: signup,
    logOut: logOut
};

const MobileHeaderTopComp = connect(
    mapStateToProps,
    actionCreators
)(MobileHeaderSection)

export default MobileHeaderTopComp;