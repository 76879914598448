import { cartConstants } from '../Constants/cart.constants';

let cartData = JSON.parse(localStorage.getItem('cartData'));
let checkCartAttributesData = localStorage.getItem('cartAttributesData');
let cartAttributesData = [];
if(checkCartAttributesData && checkCartAttributesData !== 'undefined') {
    cartAttributesData = JSON.parse(localStorage.getItem('cartAttributesData'));
}
const initialState = cartData ? { cartData, cartCount: cartData.length, cartAttributesData } : { cartData: [], cartCount: 0, cartAttributesData: [] };

export default function CartReducer(state = initialState, action) {
    switch (action.type) {
        case cartConstants.ADD_TO_CART:
            let product = action.prodObj
            let addedItem = state.cartData.find(item=> item.prodId === product.prodId && item.prodSelectedVariationId === product.prodSelectedVariationId)
            let existed_item= state.cartData.find(item=> product.prodId === item.prodId && item.prodSelectedVariationId === product.prodSelectedVariationId)
            
            if(existed_item)
            {
                addedItem.prodQuantity = parseInt(product.prodQuantity) + parseInt(addedItem.prodQuantity)
                return{
                    ...state,
                    cartCount: state.cartCount
                }
            }else{
                return {
                    ...state,
                    isCartAdding: true,
                    cartData: [...state.cartData, product],
                    cartCount: state.cartCount + 1
                }
            }
        
        case cartConstants.UPDATE_TO_CART:
            let updatedProduct = action.prodObj
            let updatedItem = state.cartData.find(item=> item.prodId === updatedProduct.prodId && item.prodSelectedVariationId === updatedProduct.prodSelectedVariationId)
            updatedItem.prodQuantity = updatedProduct.prodQuantity
            return{
                ...state
            }

        case cartConstants.REMOVE_FROM_CART:
            //let seclectedProduct = action.prodObj
            //var toRemove = seclectedProduct.prodId;
            var index = action.prodObj.prodIndex;
            if (index > -1) { 
                state.cartData.splice(index, 1);
                state.cartAttributesData.splice(index, 1);
            }
            return{
                ...state,
                cartCount: state.cartCount - 1
            }

        case cartConstants.ADD_TO_DATABASE_CART:
            let insertSuccessMsg = action.successMsg
            return{
                ...state,
                addToCartMsg: insertSuccessMsg,
                isCartAddError: false,
                cartErrorMessage: ''
            }

        case cartConstants.ADD_TO_DATABASE_CART_FAILURE:
            return {
                ...state,
                isCartAddError: true,
                cartErrorMessage: action.error
            };

        case cartConstants.UPDATE_DATABASE_CART:
            let updateSuccessMsg = action.successMsg
            return{
                ...state,
                updateCartMsg: updateSuccessMsg,
                isCartUpdateError: false,
                cartErrorMessage: ''
            }

        case cartConstants.UPDATE_DATABASE_CART_FAILURE:
            return {
                ...state,
                isCartUpdateError: true,
                cartErrorMessage: action.error
            };

        case cartConstants.REMOVE_DATABASE_CART:
            let removeSuccessMsg = action.successMsg
            return{
                ...state,
                removeCartMsg: removeSuccessMsg
            }

        case cartConstants.ADD_TO_CART_WITH_LOGIN:
            let userProduct = action.cartItemsObj;
            let userProductAttribute = action.cartAttributeItemsObj;

            return {
                ...state,
                isCartAdding: true,
                cartData: userProduct,
                cartAttributesData: userProductAttribute,
                cartCount: userProduct.length
            }

        case cartConstants.ADD_ATTRIBUTE_TO_CART:
            let productAttr = action.prodAttrObj
            let addedAttrItem = state.cartAttributesData.find(item=> item.prodId === productAttr.prodId && item.prodVariationId === productAttr.prodVariationId)
            let existedAttrItem = state.cartAttributesData.find(item=> productAttr.prodId === item.prodId && productAttr.prodVariationId === item.prodVariationId)

            if(existedAttrItem) {
                addedAttrItem.prodAttribute = productAttr.prodAttribute
                return{
                    ...state
                }
            } else {
                return {
                    ...state,
                    cartAttributesData: [...state.cartAttributesData, productAttr]
                }
            }
            
        default:
            return state
    }
}