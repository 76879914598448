import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class BreadcrumbSection extends Component {

    render(){
        return(
            <section className="breadcamp breadcamp__catagory ooooooooo">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/">Home &raquo;</Link> Resultado de búsqueda
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}