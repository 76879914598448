import React, {Component} from 'react';

export default class SiteMap extends Component {
    render(){
        //const { match, location, history } = this.props;
        return(
            <div className="wraper">
                <section className="banner-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--term">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">Mapa del sitio</h1>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="breadcum"><a href="javascript:void(0)">Home</a> <span>&#62;</span> <a href="javascript:void(0)">Site Map</a></p>
                            </div>
                        </div>
                    </div>		      
                </section>

                <div className="container general-content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h4>Sobre nosotros</h4>

                            <ul>
                                <li>Sucursales</li>
                                <li>Pol&iacute;ticas de privacidad</li>
                                <li>T&eacute;rminos de venta</li>
                                <li>Contacto</li>
                            </ul>

                            <h4>Cat&aacute;logo</h4>
                        </div>
                    </div>
                </div>

                <div className="general-line">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}