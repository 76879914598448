import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class LgBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <section className="dashboard-container">
                <div className="LG-brandpage">
                    <div className="itemfull">
                        <Link to="/fabricantes/lg">
                            <img className="responsive" src="/images/Banner-Principal-1000x611.jpg" />
                        </Link>
                    </div>
                    <div className="itemfull">
                        <Link to="/categorias/televisores/tv-y-smarttv?brand=lg">
                            <video className="responsive" width={478} height={571} autoPlay={true} loop muted>
                                <source src="/videos/Banner-Principal-1000x611-2oled.mp4" type="video/mp4" />
                            </video>
                        </Link>
                    </div>
                    <div className="cont-2">
                        <div className="box-a">
                            <Link to="/producto/50NANO75PA">
                                <img className="responsive" src="/images/Banner-500x600-nanocell.png" />
                            </Link>
                        </div>
                        <div className="box-a">
                            <Link to="/search/washtower">
                                <video className="responsive" width={478} height={571} autoPlay={true} loop muted>
                                    <source src="/videos/Banner-500x600-washtower.mp4" type="video/mp4" />
                                </video>
                            </Link>
                        </div>
                    </div>
                    <div className="cont-3">
                        <div className="box-c">
                            <Link to="/categorias/televisores/tv-y-smarttv?brand=lg">
                                <img className="responsive" src="/images/Boton-300x281-tv.png" />
                            </Link>
                        </div>
                        <div className="box-c">
                            <Link to="/categorias/audio?brand=lg">
                                <img className="responsive" src="/images/Boton-300x281-audio.png" />
                            </Link>
                        </div>
                        <div className="box-c">
                            <Link to="/categorias/linea-blanca?brand=lg">
                                <img className="responsive" src="/images/Boton-300x281-lineaBlanca.png" />
                            </Link>
                        </div>
                    </div>
                    <div className="cont-3">
                        <div className="box-c">
                            <Link to="/producto/SL4">
                                <img className="responsive" src="/images/Boton-300x281-soundbarSL4.jpg" />
                            </Link>
                        </div>
                        <div className="box-c">
                            <Link to="/search/tonefree">
                                <img className="responsive" src="/images/Boton-300x281-tonefree.jpg" />
                            </Link>
                        </div>
                        <div className="box-c">
                            <Link to="/producto/27MP60G">
                                <img className="responsive" src="/images/Boton-300x281-IT-27Mp60.jpg" />
                            </Link>
                        </div>
                    </div>
                    <div className="cont-2">
                        <div className="box-a">
                            <Link to="/categorias/linea-blanca/refrigeradoras/Instaview-door-in-door">
                                <video className="responsive" width={478} height={573} autoPlay={true} loop muted>
                                    <source src="/videos/Banner-500x600-instaview.mp4" type="video/mp4" />
                                </video>
                            </Link>
                        </div>
                        <div className="box-a">
                            <Link to="/categorias/linea-blanca/lavadoras?brand=lg">
                                <img className="responsive" src="/images/Banner 500x600-twinwash.jpg" />
                            </Link>
                        </div>
                    </div>
                    <div className="itemfull">
                        <Link to="/categorias/linea-blanca/estufas?brand=lg">
                            <img className="responsive" src="/images/Banner-500x600-RSG316T-LRGL5825.jpg" />
                        </Link>
                    </div>
                    <div className="itemfull">
                        <Link to="/categorias/linea-blanca/aire-acondicionado?brand=lg">
                            <img className="responsive" src="/images/Banner-500x600-VR122C7.jpg" />
                        </Link>
                    </div>
                    <div className="cont-2">
                        <div className="box-a">
                            <Link to="/categorias/televisores/tv-y-smarttv?brand=lg">
                                <img className="responsive" src="/images/Banner-500x600-qned.jpg" />
                            </Link>
                        </div>
                        <div className="box-a">
                            <Link to="/categorias/televisores/tv-y-smarttv?brand=lg">
                                <img className="responsive" src="/images/Banner-500x600-uhd.jpg" />
                            </Link>
                        </div>
                    </div>
                  </div>
            </section>
        );
       
    }
}

export default LgBrandListingSection;