import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from '../../config/api';

class ProductLeftCategoryMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            catProducts: [],
            catProductsLoaded: false,
            selectedParentCatId: false,
            currentChildCategoryId: ''
        }
    }

    async componentDidMount() {
      let categoryId = this.props.match.params.categoryId;
      this.setState({
          currentChildCategoryId: categoryId
      });

      const apiUrl = `${API_BASE_URL}/category`;
      let requestOptions = {
          method: 'POST',
          body: JSON.stringify({"category_id": categoryId})
      };

      try {
          let response = await fetch(apiUrl, requestOptions);
          let result = await response.json();
          this.setState({
              selectedParentCatId: result.data.selected_parent_category_id,
              catProducts: result.data.menu,
              catProductsLoaded: true
          });
      } catch (error) {
          this.setState({ error });
      }
  }

  async componentDidUpdate(prevProps, prevState) {
      if (prevProps.match.params.categoryId !== this.props.match.params.categoryId) {
          let categoryId = this.props.match.params.categoryId;
          this.setState({
              currentChildCategoryId: categoryId
          });

          const apiUrl = `${API_BASE_URL}/category`;
          let requestOptions = {
              method: 'POST',
              body: JSON.stringify({"category_id": categoryId})
          };

          try {
              let response = await fetch(apiUrl, requestOptions);
              let result = await response.json();
              this.setState({
                  selectedParentCatId: result.data.selected_parent_category_id,
                  catProducts: result.data.menu,
                  catProductsLoaded: true
              });
          } catch (error) {
              this.setState({ error });
          }
      }
  }

    render(){
        const { catProducts, selectedParentCatId, currentChildCategoryId } = this.state;
        if (!this.state.catProductsLoaded) {
            return (
                <>
                    <h2>
                    <Skeleton height={50} width={`100%`} />
                    </h2>
                    <Skeleton count={13} style={{ padding: "10px", margin: "2px 0px" }} />
                </>
            );
        }

        return(
            <nav className="navigation">
                <h2>Departamentos</h2>
                <ul className="mainmenu">
                    {
                        catProducts && catProducts.length > 0 ? (catProducts.map((cat_product) =>
                            <li
                              key={cat_product.id}
                              className={cat_product.sub && cat_product.sub.length ? "dropdown-list-menu" : ""}
                            >
                              <Link 
                                  to={`/categorias/${cat_product.slug}`} 
                                  className={cat_product.id === selectedParentCatId ? "menuactive" : ""}
                              >
                                  {cat_product.name}
                              </Link>
                              {cat_product.sub && cat_product.sub.length > 0 && (
                                  <ul className="submenu">
                                      {cat_product.sub.map((submenu) => (
                                          <li key={submenu.id}>
                                              <Link 
                                                  to={`/categorias/${cat_product.slug}/${submenu.slug}`} 
                                                  className={submenu.id === currentChildCategoryId ? "menuactive" : ""}
                                              >
                                                  {submenu.name}
                                              </Link>
                                          </li>
                                      ))}
                                  </ul>
                              )}
                            </li>
                          )
                        ) : (
                            <>
                                <h2><Skeleton height={50} width={`100%`} /></h2>
                                <Skeleton count={13} style={{padding: '10px', margin:'2px 0px'}} />
                            </>
                        )
                    }
                </ul>
            </nav>
        )
    }
}

export default withRouter(ProductLeftCategoryMenu)