import React, {Component} from 'react';
import API_BASE_URL from '../../config/api';

export default class NewsletterSubmitSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsletteremail: '',
            error: '', errors: {}, newsletterLoader: 'hidden', newsletterDisabled : false,
            newsletterSubmitted: false, newsletterApiErrorResponse: '', newsletterApiSuccessResponse: ''
        }

        this.handleOnNewsletterInputChange = this.handleOnNewsletterInputChange.bind(this);
        this.handleOnNewsletterSubmit = this.handleOnNewsletterSubmit.bind(this);
    }

    handleOnNewsletterInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleNewsletterFormValidation(){
        let newsletteremail = this.state.newsletteremail;
        let errors = {};
        let newsletterFormIsValid = true;
    
        if(typeof newsletteremail !== "undefined"){
           let lastAtPos = newsletteremail.lastIndexOf('@');
           let lastDotPos = newsletteremail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && newsletteremail.indexOf('@@') === -1 && lastDotPos > 2 && (newsletteremail.length - lastDotPos) > 2)) {
              newsletterFormIsValid = false;
              errors["newsletteremail"] = "Su identificación de correo electrónico no es válida";
            }
        } else {
            newsletterFormIsValid = false;
            errors["newsletteremail"] = "Ingrese su ID de correo electrónico";
        } 
    
       this.setState({errors: errors});
       return newsletterFormIsValid;
    }


    handleOnNewsletterSubmit(e) {
        e.preventDefault();
        this.setState({ newsletterSubmitted: true, newsletterApiSuccessResponse: '',  newsletterApiErrorResponse: '' });

        if(this.handleNewsletterFormValidation()){
            this.setState({ newsletterLoader: "", newsletterDisabled: true});
            let newsletteremail = this.state.newsletteremail;

            //const apiUrl = 'https://www.evisionstore.com/api/home/newsletter.php';
            const apiUrl = `${API_BASE_URL}/newsletter`;
        
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "email": newsletteremail
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code===200){
                        this.setState({
                            newsletterApiSuccessResponse: result.message,
                            newsletterLoader: "hidden",
                            newsletteremail: '',
                            newsletterDisabled: false
                        });
                    }else{
                        this.setState({
                            newsletterApiErrorResponse: result.message,
                            newsletterLoader: "hidden",
                            newsletterDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, newsletterDisabled: false });
                }
            )
        }
    }

    render(){
        const { newsletteremail, newsletterSubmitted } = this.state;
        return(
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div className="footer-block" id="newsletterft">
                <h3>Boletin informativo</h3>
                <p>Suscríbete a nuestra newsletter para recibir ofertas, novedades y descuentos</p>
                    <form className="formulario" onSubmit={this.handleOnNewsletterSubmit}>
                        <input 
                        type="email" 
                        name="newsletteremail" 
                        className={'input-text' + (newsletterSubmitted && !newsletteremail ? ' has-error' : '')} 
                        placeholder="Escriba aquí su email" 
                        value={newsletteremail} 
                        onChange={this.handleOnNewsletterInputChange} 
                        maxLength="70"
                        />
                        <span style={{color: "red"}}>{this.state.errors["newsletteremail"]}</span>
                        <span style={{color: "red"}}>{this.state.newsletterApiErrorResponse}</span>
                        <span style={{color: "green"}}>{this.state.newsletterApiSuccessResponse}</span>
                        <button className="btn" type="submit" disabled={this.state.newsletterDisabled}>
                            <i className={`fa fa-refresh fa-spin ${ this.state.newsletterLoader }`} style={{fontSize:"17px"}}></i> 
                            &nbsp; {this.state.newsletterDisabled ? 'Subscribing..' : 'Mostrar más'}
                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="" />
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}