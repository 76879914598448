import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'Mis Cotizaciones';

class MyQuotations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            quotationsList: [],
            isQuotationsLoaded: false
        }
    }

    componentDidMount() {
        const apiUrl = `${API_BASE_URL}/quotation-list`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "customer_id": this.props.customerId })
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.code === 200) {
                    this.setState({
                        quotationsList: result.data.quotation_list,
                        isQuotationsLoaded: true
                    });
                } else if(result.code === 401) {
                    this.setState({
                        isQuotationsLoaded: true
                    });
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    window.location.reload(true);
                } else {
                    this.setState({
                        isQuotationsLoaded: true
                    });
                }
            },
            (error) => {
                this.setState({ error: error, isQuotationsLoaded: true });
            }
        )
    }

    render(){
        const {isLoggedIn} = this.props;
        const { quotationsList } = this.state;

        console.log(quotationsList)

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h3 className="tabname">Cotizaciones Solicitadas</h3>
                                    <p> Sus cotizaciones solicitadas </p>
                                    <div className="row userInfo">
                                        <div className="col-lg-12">
                                            
                                        </div>
                                        <div style={{clear: 'both'}} />
                                        <div className="col-xs-12 col-sm-12">
                                            <div class="table-responsive">
                                                <table className="table">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>Sl. Id</th>
                                                            <th>Cotizar artículos</th>
                                                            <th>Fecha de solicitud</th>
                                                            <th style={{textAlign:'right'}}>Status</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.isQuotationsLoaded ? (
                                                            quotationsList.length !== 0 ? (
                                                                quotationsList.map((quotations, index) => (
                                                                    <tr key={index}>
                                                                        <td>#{index + 1}</td>
                                                                        <td>{quotations.total_items} <small>item(s)</small></td>
                                                                        <td>{quotations.quotation_time}</td>
                                                                        <td>
                                                                            {(() => {
                                                                                if(quotations.quotation_status === "REPLIED"){
                                                                                    return (<span className="label label-success float-right">{quotations.quotation_status}</span>);
                                                                                } else if (quotations.quotation_status === "PENDING") {
                                                                                    return (<span className="label label-warning float-right">{quotations.quotation_status}</span>);
                                                                                } else {
                                                                                    return (<span className="label label-danger float-right">{quotations.quotation_status}</span>);
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                        <td>
                                                                            {/* <Link to={`/quotationdetails/${quotations.quote_id}`} ><i className="fa fa-eye" aria-hidden="true"></i> View</Link> */}
                                                                            {(() => {
                                                                                if(quotations.generated_quotation_file_name !== ""){
                                                                                    return (
                                                                                        <>
                                                                                        <a href={`https://www.evisionstore.com/quote-attachments/${quotations.generated_quotation_file_name}`} target="_blank" rel="noopener noreferrer" className="dwnld-btn">
                                                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/download-pdf.svg`} alt="Download PDF" />Download PDF
                                                                                        </a> 
                                                                                        </>
                                                                                    )
                                                                                }else if(quotations.uploaded_quotation_file_name !== ""){
                                                                                    return (
                                                                                        <>
                                                                                        <a href={`https://www.evisionstore.com/quote-attachments/${quotations.uploaded_quotation_file_name}`} target="_blank" rel="noopener noreferrer" className="dwnld-btn">
                                                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/download-pdf.svg`} alt="Download PDF" />Download PDF
                                                                                        </a>
                                                                                        </>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <span>--</span>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" style={{textAlign:'center'}}>¡No se encontró ninguna solicitud de cotización!</td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                            )
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" style={{fontSize:'17px', fontWeight:'500'}}>Loading...</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>    
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    {/*/row end*/}
                                </div>


                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda</Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id
})

const MyQuotationsComp = connect(
    mapStateToProps
)(MyQuotations)

export default MyQuotationsComp;