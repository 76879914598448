import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class BreadcrumbSection extends Component {

    render(){
        return(
            <section className="breadcamp" style={{marginBottom:"10px"}}>
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/">Home &#62;</Link> <span className="text-blue">Revisa</span>
                        </div>
                        {/*<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="caps"><Link to="/"><i className="fa fa-chevron-left" /> De vuelta a las compras </Link></div>
                        </div>*/}
                    </div>
                </div>
            </section>
        )
    }
}