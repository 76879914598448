import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
class BreadcrumbSection extends Component {

    render(){
        let params = this.props.match.params;
        
        let topCategorySlug = params.categoryName;
        let subCategorySlug = params.subCategoryName;

        let fullCategorySlug;
        if(topCategorySlug !== undefined && subCategorySlug !== undefined){
            fullCategorySlug = topCategorySlug + "/" + subCategorySlug;
        }else if(topCategorySlug !== undefined && subCategorySlug == undefined){
            fullCategorySlug = topCategorySlug;
        }

        const hostname = window.location.host;
        let queryStringVal = queryString.parse(this.props.location.search);
        let brandName = "";
        var canonicalUrl = "https://" + hostname + "/categorias/" + fullCategorySlug;
        if(queryStringVal.brand !== undefined){
            brandName = queryStringVal.brand;
            canonicalUrl = "https://" + hostname + "/categorias/" + fullCategorySlug + "?brand=" + brandName;
        }

        return(
            <>
            {/* <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet> */}
            <section className="breadcamp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/">Home &raquo; </Link> 
                            {!subCategorySlug && <>{capitalizeFirstLetter(topCategorySlug)}</>}
                            {subCategorySlug && 
                            <>
                            <Link to={`/categorias/${topCategorySlug}`}> {capitalizeFirstLetter(topCategorySlug)} &raquo; </Link> {capitalizeFirstLetter(subCategorySlug)}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </section>
            </>
            
        )
    }
}

export default withRouter(BreadcrumbSection);