import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'Carrito de Compras';

export default class BannerSection extends Component {

    render(){
        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <section className="banner-container">
                <div className="product-title">
                    Carrito de compras
                </div>  
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>
            </section>
            </>
        )
    }
}