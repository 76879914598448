import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class ProfileDesktopLeftLinkSection extends Component {
    render(){
        return(
            <ul className="mainmenu">
                <li>
                    <Link to="/profile">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/icon-dashboard.svg`} alt="Dashboard" width="20" height="15" /> Dashboard
                            </span>
                            <svg width="20" height="10" viewBox="0 0 23 16" fill="none"><path d="M22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538406 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM8.74228e-08 9L22 9L22 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#FDFDFD"></path></svg>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/myaccount">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/icon-myaccount.svg`} alt="My Account" width="16" height="18" /> Mi cuenta
                            </span>
                            <svg width="20" height="10" viewBox="0 0 23 16" fill="none"><path d="M22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538406 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM8.74228e-08 9L22 9L22 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#FDFDFD"></path></svg>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/addressbook">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/icon-directory.svg`} alt="Directory" width="16" height="20" /> Directory
                            </span>
                            <svg width="20" height="10" viewBox="0 0 23 16" fill="none"><path d="M22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538406 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM8.74228e-08 9L22 9L22 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#FDFDFD"></path></svg>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/orders">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/icon-myorder.svg`} alt="My Order" width="20" height="20" /> Mis Órdenes
                            </span>
                            <svg width="20" height="10" viewBox="0 0 23 16" fill="none"><path d="M22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538406 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM8.74228e-08 9L22 9L22 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#FDFDFD"></path></svg>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/myquotations">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/icon-myquotes.svg`} alt="My Quotes" width="16" height="20" /> Mis Cotizaciones
                            </span>
                            <svg width="20" height="10" viewBox="0 0 23 16" fill="none"><path d="M22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538406 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM8.74228e-08 9L22 9L22 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#FDFDFD"></path></svg>
                        </div>
                    </Link>
                </li>
            </ul>
        )
    }
}