import React, {Component} from 'react';

export default class ContactDetailsSection extends Component {
    render(){
        return(
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="footer-block">
                    <h3>Contacto</h3>
                    <p id="address" className="address-bar">
                        <span className="main-address">E Visión, S.A.</span><br />
                        Albrook Mall, local PA-M20<br />
                        (Entrada del pingüino, 2° piso)<br />
                        Panamá, PA.
                    </p>
                    <p id="address" className="contact-bar">
                        0831-0492, Panamá, PA.<br />
                        Tels: (507) 302-1030 / 302-1031<br />
                        (507) 302-1032
                    </p>
                </div>
            </div>
        )
    }
}