import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'Editar direccion';

class MyAddressEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '', errors: {},
            countryItems: [], stateItems: [], cityItems: [],
            address1: '', 
            address2: '', 
            city_name: '', 
            state_name: '', 
            country_name: '', 
            cityId: '',
            stateId: '',
            countryId: '',
            pincode: '', 
            isAddressLoaded: false, addressDisabled: false, addressSubmitted: false, addressApiSuccessResponse: '', addressApiErrorResponse: '',

        }

        this.handleOnAddressInputChange = this.handleOnAddressInputChange.bind(this);
        this.handleOnAddressSubmit = this.handleOnAddressSubmit.bind(this);
    }

    componentDidMount() {
        //const apiCountryUrl = 'https://www.evisionstore.com/api/checkout/getcountry.php';
        //const apiAddressUrl = 'https://www.evisionstore.com/api/user/address.php';
        //const apiCountryUrl = `${API_BASE_URL}/getcountry`;
        const apiAddressUrl = `${API_BASE_URL}/address`;
        //alert(this.props.token);

        const requestOptionsForAddress = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        };
        /* const requestOptionsForAddress = {
            method: 'POST', 
            body: JSON.stringify({  "customer_id": this.props.customerId })
        }; */

        fetch(apiAddressUrl, requestOptionsForAddress)
        .then(res => res.json())
        .then((result) => {
            this.setState({
                countryItems: result.data.countries,
                address1: result.data.address1,
                address2: result.data.address2,
                city_name: result.data.city_name,
                state_name: result.data.state_name,
                country_name: result.data.country_name,
                cityId: result.data.city_id,
                stateId: result.data.state_id,
                countryId: result.data.country_id,
                pincode: result.data.pincode,
                isAddressLoaded: true
            });

            const country_id = this.state.countryId;
            //console.log("Country ID: ", country_id);
            //alert(country_id);
            if (country_id !== null) {
                const apiUrl = `${API_BASE_URL}/states-by-country`;
                return fetch(apiUrl, {
                    method: 'POST',
                    body: JSON.stringify({"country": country_id}),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => res.json())
                .then((result) => {
                    //console.log('State: ', result);
                    this.setState({
                        stateItems: result.data
                    });

                    const state_id = this.state.stateId;
                    if (state_id !== null) {
                        const apiUrl = `${API_BASE_URL}/city-by-state`;
                        return fetch(apiUrl, {
                            method: 'POST',
                            body: JSON.stringify({"state": state_id}),
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                        .then(res => res.json())
                        .then((result) => {
                                this.setState({
                                    cityItems: result.data,
                                    paymentLoader: 'hidden'
                                });
                            },
                            (error) => {
                                this.setState({ error });
                            }
                        )
                    }
                })
            }
        });


        /* Promise.all([
            fetch(apiCountryUrl),
            fetch(apiAddressUrl, requestOptionsForAddress)
        ]).then(([res1, res2]) => {
            return Promise.all([res1.json(), res2.json()])
        }).then(([res1, res2, res3]) => {
            console.log("Reponse 1: ", res2);
            this.setState({
                countryItems: res1,
                address1: res2.customer_address[0].address1,
                address2: res2.customer_address[0].address2,
                city_name: res2.customer_address[0].city_name,
                state_name: res2.customer_address[0].state_name,
                country_name: res2.customer_address[0].country_name,
                cityId: res2.customer_address[0].city_id,
                stateId: res2.customer_address[0].state_id,
                countryId: res2.customer_address[0].country_id,
                pincode: res2.customer_address[0].pincode,
                isAddressLoaded: true
            });

            const country_id = this.state.countryId;
            //alert(country_id);
            const apiUrl = 'https://www.evisionstore.com/api/checkout/getstate.php';

            return fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({"country_id": country_id})
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        stateItems: result
                    });

                    const state_id = this.state.stateId;
                    const apiUrl = 'https://www.evisionstore.com/api/checkout/getcity.php';
                    return fetch(apiUrl, {
                        method: 'POST',
                        body: JSON.stringify({"state_id": state_id})
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                cityItems: result,
                                paymentLoader: 'hidden'
                            });
                        },
                        (error) => {
                            this.setState({ error });
                        }
                    )
                },
                (error) => {
                    this.setState({ error });
                }
            )
        }).catch(function (error) {
            console.log(error); 
        }); */


    }

    handleOnAddressInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleAddressFormValidation(){
        let address1 = this.state.address1;
        let countryId = this.state.countryId;
        let stateId = this.state.stateId;
        let cityId = this.state.cityId;
        let pincode = this.state.pincode;
        let errors = {};
        let addressFormIsValid = true;

        if(!address1) {
           addressFormIsValid = false;
           errors["firstName"] = "Please enter your Address1.";
        }

        if(!countryId) {
            addressFormIsValid = false;
            errors["countryId"] = "Please select your country.";
        }
        
        if(!stateId){
            addressFormIsValid = false;
            errors["stateId"] = "Please select your state.";
        }

        if(!cityId){
            addressFormIsValid = false;
            errors["cityId"] = "Please select your city.";
        }

        // if(!pincode){
        //     addressFormIsValid = false;
        //     errors["pincode"] = "Please enter your zip code.";
        // }
    
       this.setState({errors: errors});
       return addressFormIsValid;
    }

    handleOnAddressSubmit(e){
        e.preventDefault();
        this.setState({ addressSubmitted: true, addressApiSuccessResponse: '', addressApiErrorResponse: '' });

        if(this.handleAddressFormValidation()){
            this.setState({ addressLoader: "", addressDisabled: true });
            let customerId = this.props.customerId;
            let address1 = this.state.address1;
            let address2 = this.state.address2;
            let countryId = this.state.countryId;
            let stateId = this.state.stateId;
            let cityId = this.state.cityId;
            let pincode = this.state.pincode;

            //const apiUrl = 'https://www.evisionstore.com/api/user/adddressupdate-react.php';
            const apiUrl = `${API_BASE_URL}/update-address`;
        
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "address_1": address1,
                    "address_2": address2,
                    "country": countryId,
                    "city": cityId,
                    "state": stateId,
                    "pincode": pincode
                })
            })
            .then(res => res.json())
            .then((result) => {
                    if(result.code === 200) {
                        this.setState({
                            addressApiSuccessResponse: result.message,
                            addressLoader: "hidden",
                            addressDisabled: false
                        });
                    } else {
                        this.setState({
                            addressApiErrorResponse: result.message,
                            addressLoader: "hidden",
                            addressDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, addressLoader: "hidden", addressDisabled: false });
                }
            )
        }
    }

    handleOnCountryChange = (event) => {
        const country_id = parseInt(event.target.value);
        const apiUrl = `${API_BASE_URL}/states-by-country`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"country": country_id}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((result) => {
            console.log("State Result: ", result);
                this.setState({
                    stateItems: result.data
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['countryId'] = "";
        this.setState({errors: errors });
        this.setState({ countryId: country_id });
    };

    handleOnStateChange = (event) => {
        const state_id = parseInt(event.target.value);
        const apiUrl = `${API_BASE_URL}/city-by-state`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"state": state_id}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    cityItems: result.data
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['stateId'] = "";
        this.setState({errors: errors });
        this.setState({ stateId: state_id });
    };

    handleOnCityChange = (event) => {
        const city_id = event.target.value;
        this.setState({ cityId: city_id });

        let errors = this.state.errors;
        errors['cityId'] = "";
        this.setState({errors: errors });
    };

    render(){
        const {isLoggedIn} = this.props;
        const { address1, address2, cityId, stateId, countryId, pincode, countryItems, stateItems, cityItems, addressSubmitted} = this.state;

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-top-dashboard">
                                    <h3 className="tabname">Mi dirección personal</h3>
                                    <p className=""> Asegúrese de actualizar su información personal si ha cambiado. </p>
                                    <div className="row userInfo">
                                        <div className="col-lg-12">
                                            
                                            {/* <p className="required"><sup>*</sup> Campo requerido</p> */}
                                        </div>
                                        <form className="col-lg-8 p-0" onSubmit={this.handleOnAddressSubmit}>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputFirstName">Dirección 1 <sup>*</sup> </label> */}
                                                    <input type="text" className={'form-control' + (addressSubmitted && !address1 ? ' has-error' : '')} name="address1" value={address1} onChange={this.handleOnAddressInputChange} id="InputFirstName" placeholder="Dirección 1" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["address1"]}</span>
                                                </div>
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputLastName">Dirección 2 </label> */}
                                                    <input type="text" className={'form-control' + (addressSubmitted && !address2 ? ' has-error' : '')} name="address2" value={address2} onChange={this.handleOnAddressInputChange} id="InputLastName" placeholder="Dirección 2" maxLength="50" />
                                                    <span style={{color: "red"}}>{this.state.errors["address2"]}</span>
                                                </div>
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputPasswordCurrent">País <sup>*</sup> </label> */}
                                                    <select value={countryId} onChange={this.handleOnCountryChange} className={'form-control' + (addressSubmitted && !countryId ? ' has-error' : '')} id="InputPasswordCurrent" name="countryId" aria-hidden="true">
                                                        <option value="">Seleccionar país</option>
                                                        {countryItems.map(country => 
                                                            <option value={country.province_id} key={country.province_id}>{country.province_name}</option>
                                                        )}
                                                    </select>
                                                    <span style={{color: "red"}}>{this.state.errors["countryId"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group">
                                                    {/* <label htmlFor="InputCompany">Estado <sup>*</sup></label> */}
                                                    <select value={stateId} onChange={this.handleOnStateChange} className={'form-control' + (addressSubmitted && !stateId ? ' has-error' : '')} name="stateId" aria-hidden="true">
                                                    <option value="">Seleccionar Estado</option>
                                                    {Array.isArray(stateItems) && stateItems.map(provins => 
                                                        <option value={provins.distrito_id} key={provins.distrito_id}>{provins.distrito_name}</option>
                                                    )}
                                                    </select>
                                                    <span style={{color: "red"}}>{this.state.errors["stateId"]}</span>
                                                </div>
                                                <div className="form-group">
                                                    {/* <label htmlFor="InputEmail">Ciudad <sup>*</sup> </label> */}
                                                    <select value={cityId} onChange={this.handleOnCityChange} className={'form-control' + (addressSubmitted && !cityId ? ' has-error' : '')} name="cityId" aria-hidden="true">
                                                    <option value="">Seleccionar Ciudad</option>
                                                    {Array.isArray(cityItems) && cityItems.map(city => 
                                                        <option value={city.correg_id} key={city.correg_id}>{city.correg_name}</option>
                                                    )}
                                                    </select>
                                                    <span style={{color: "red"}}>{this.state.errors["cityId"]}</span>
                                                </div>
                                                <div className="form-group required">
                                                    {/* <label htmlFor="InputPasswordnew">Código PIN <sup>*</sup></label> */}
                                                    <input type="text" className={'form-control' + (addressSubmitted && !pincode ? ' has-error' : '')} name="pincode" value={pincode} onChange={this.handleOnAddressInputChange} id="InputPasswordnew" placeholder="Ingresar Código PIN" maxLength="10"/>
                                                    <span style={{color: "red"}}>{this.state.errors["pincode"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="margin-top-btn">
                                                    <div className="submit-btn"> 
                                                        <button type="submit" className="btn btn-primary" disabled={this.state.addressDisabled}>
                                                            { this.state.addressDisabled ? <i className="fa fa-refresh fa-spin"></i> : <i/> } 
                                                            { this.state.addressDisabled ? ' Actualizando..' : ' Actualizar' }
                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                        </button>
                                                        <span style={{color: "red"}}>{this.state.addressApiErrorResponse}</span>
                                                        <span style={{color: "green"}}>{this.state.addressApiSuccessResponse}</span>
                                                    </div>
                                                    <Link to="/profile" className="btn">
                                                        Volver a Mi Cuenta
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                    </Link>
                                                </div>    
                                            </div>
                                        </form>
                                    </div>
                                    {/*/row end*/}
                                </div>


                                {/* <div className="col-lg-12 clearfix">
                                    <ul className="pager">
                                        <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li>
                                        <li className="next pull-left"><Link to="/profile"> ← Volver a Mi Cuenta</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    customerId: state.user.user.customer_id
})

const MyAddressEditComp = connect(
    mapStateToProps
)(MyAddressEdit)

export default MyAddressEditComp;