import { filterConstants } from "../Constants/filter.constants";

export function resetFilter(category_id) {
  return dispatch => {
      dispatch(request(category_id));
  }
  function request(category_id) { return { type: filterConstants.RESET_PRODUCT_FILTER, category_id } }
}

export function resetFilterByCategorySlug(category_slug) {
  return dispatch => {
      dispatch(request(category_slug));
  }
  function request(category_slug) { return { type: filterConstants.RESET_PRODUCT_FILTER, category_slug } }
}

export function setBrandOptions(brandObjects) {
  return dispatch => {
      dispatch(request(brandObjects));
  }
  function request(brandObjects) { return { type: filterConstants.PRODUCT_UNIQUE_BRAND_LISTS, brandObjects } }
}

export function setProductsById(productsObjects) {
  return dispatch => {
      dispatch(request(productsObjects));
  }
  function request(productsObjects) { return { type: filterConstants.PRODUCT_SET_BY_ID, productsObjects } }
}

export function productsListByBrand(selectedBrandIds) {
  return dispatch => {
      dispatch(request(selectedBrandIds));
  }
  function request(selectedBrandIds) { return { type: filterConstants.PRODUCT_LIST_BY_BRANDS, selectedBrandIds } }
}

export function productsListByAllowPurchase(selectedAllowPurchaseId) {
  return dispatch => {
      dispatch(request(selectedAllowPurchaseId));
  }
  function request(selectedAllowPurchaseId) { return { type: filterConstants.PRODUCT_LIST_BY_ONLINE, selectedAllowPurchaseId } }
}

export function productsListByPriceRange(fromRange, toRange) {
  var priceRangeObj = {fromRange,toRange}
  return dispatch => {
      dispatch(request(priceRangeObj));
  }
  function request(priceRangeObj) { return { type: filterConstants.PRODUCT_LIST_BY_PRICE_RANGE, priceRangeObj } }
}

export function resetFilterByPriceRange(category_slug) {
  return dispatch => {
      dispatch(request(category_slug));
  }
  function request(category_slug) { return { type: filterConstants.RESET_PRODUCT_FILTER, category_slug } }
}

export function priceFilterByType(priceFilterType) {
  return dispatch => {
      dispatch(request(priceFilterType));
  }
  function request(priceFilterType) { return { type: filterConstants.PRODUCT_FILTER_BY_PRICE_TYPE, priceFilterType } }
}
