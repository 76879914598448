import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import ProductDetailsContainer from './ProductDetailsContainer';

export default class ProductDetails extends Component {
    render(){
        return <div className="wraper">
                    <BannerSection />
                    {/* <BreadcrumbSection /> */}
                    <ProductDetailsContainer />
                </div>
    }
}