import React, {Component} from 'react';
import { Link } from 'react-router-dom';

export default class ContactInfoSection extends Component {
    render(){
        return(
            <section className="contact-info-container">
                <div className="container">
                    <div className="contact-info-wrap">
                        <div className="row">
                            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="product-title">
                                    <h2>Conéctate Con Nosotros.</h2>
                                    <p>Únete a nuestra comunidad! Mantente al tanto de la última tecnología, eventos de la industria y contenido de electrónica de consumo de toda la web.</p>
                                </div>
                            </div> */}
                            <div className="col-md-6 p-0 color1 d-flex align-items-center justify-content-center">
                                <a href="mailto:ventas@evisionstore.com" className="mailinfo">
                                    <img src="images/new-images/icon/send-mail.svg" alt="Envíenos Un Correo" width="44" height="44"/>
                                    <div className="info-text">
                                        <h3>Envíanos un correo</h3>
                                        <p>Por favor no dude en enviarnos su consulta</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 p-0 color2 d-flex align-items-center justify-content-center">
                                <div className="callinfo">
                                    {/* <h2><span>Llamenos</span>(507) 302-1030 / 302-1031<br />(507) 302-1032</h2> */}
                                    <img src="images/new-images/icon/call-us-on.svg" alt="Llámanos al" width="45" height="44"/>
                                    <div className="info-text">
                                        <h3>Llámanos al</h3>
                                        <p>(507) 302-1030 / 302-1031 / 302-1032</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="contactinfoheading contactinfo-padding">
                                    <h2><img src="images/live-chat-icon.png" alt="Chat En Vivo"/>&nbsp;&nbsp;Chat En Vivo</h2>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}