import { combineReducers } from 'redux';
import UserReducer from './Services/Reducers/user.reducers';
import CartReducer from './Services/Reducers/cart.reducers';
import FilterReducer from './Services/Reducers/filter.reducers';
import CheckoutReducer from './Services/Reducers/checkout.reducers';
import QuotationReducer from './Services/Reducers/quotation.reducers';

export default combineReducers({
    user: UserReducer,
    cart: CartReducer,
    filter: FilterReducer,
    checkout: CheckoutReducer,
    quotation: QuotationReducer
});