export const filterConstants = {
    PRODUCT_LIST_BY_BRANDS : "PRODUCT_LIST_BY_BRANDS",
    PRODUCT_LIST_BY_ONLINE : "PRODUCT_LIST_BY_ONLINE",
    PRODUCT_LIST_BY_PRICE_RANGE : "PRODUCT_LIST_BY_PRICE_RANGE",
    PRODUCT_FILTER_BY : "PRODUCT_LIST_BY_BRANDS",
    PRODUCT_FILTER_BY_PRICE_TYPE : "PRODUCT_FILTER_BY_PRICE_TYPE",
    RESET_PRODUCT_FILTER : "RESET_PRODUCT_FILTER",
    PRODUCT_UNIQUE_BRAND_LISTS: "PRODUCT_UNIQUE_BRAND_LISTS",
    PRODUCT_SET_BY_ID : "PRODUCT_SET_BY_ID",
    RESET_FILTER_BY_PRICE_RANGE: "RESET_FILTER_BY_PRICE_RANGE"
};