import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Pagination from './ProductPagination';
import { addToCart, addToCart2 } from '../../Services/Actions/cart.actions';
import { resetFilter, setBrandOptions, setProductsById } from '../../Services/Actions/filter.actions';
import Skeleton from "react-loading-skeleton";
import { redirectTo } from '../Header/redirecturl';
import API_BASE_URL from "../../config/api";

class ProductRightCategoryListComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProductError: null,
            isProductsLoaded : false,
            categoryProducts: [],
            isCartAddError: false, cartErrorMessage: '', addToCartButtonnLoader: "hidden", addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false,
            pageOfItems: [], showUpdateCartMsgModal: false,
            clickedProductId: '',
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.openCartReturnPop = this.openCartReturnPop.bind(this);
        this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
        this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
        this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    handleOnProductRedirect(productId){
        return redirectTo("/producto/"+productId);
    }

    componentDidMount() {
        let keyword = this.props.match.params.keyword;
        this.props.resetFilter(keyword);
        const apiUrl = 'https://www.evisionstore.com/api/product/lgsummerfestproducts-react.php';
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"keyword": keyword})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
                this.setState({
                    categoryProducts: result.online_products_all,
                    isProductsLoaded: true
                });
                //localStorage.setItem('productsData', JSON.stringify(result.online_products_all));
                this.props.setProductsById(result.online_products_all);
                //console.log(result.online_products_all)
                var res = result.online_products_all;
                let mymap = new Map(); 
  
                let unique = res.filter(el => { 
                    const val = mymap.get(el.brand); 
                    if(val) { 
                        if(el.id < val) { 
                            mymap.delete(el.brand); 
                            mymap.set(el.brand, el.brand); 
                            return true; 
                        } else { 
                            return false; 
                        } 
                    } 
                    mymap.set(el.brand, el.brand); 
                    return true; 
                }); 

                var resArr = [];
                unique.filter(function(item){
                    var i = resArr.findIndex(x => x.brand == item.brand);
                    if(i <= -1){
                        resArr.push({name: item.brand, id: item.brand});
                    }
                    return null;
                });
                this.props.setBrandOptions(resArr);
                //console.log(resArr);
            },
            (error) => {
                this.setState({ isProductError: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.keyword !== this.props.match.params.keyword) {
            
            this.setState({
                isProductsLoaded: false
            });

            let keyword = this.props.match.params.keyword;
            this.props.resetFilter(keyword);
            //const apiUrl = 'https://www.evisionstore.com/api/product/onlineproducts-react.php';
            const apiUrl = `${API_BASE_URL}/online-products`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"keyword": keyword})
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categoryProducts: result.data.online_products_all,
                        isProductsLoaded: true
                    });
                    //localStorage.setItem('productsData', JSON.stringify(result.online_products_all));
                    this.props.setProductsById(result.online_products_all);
                    //Added this code for to get unique category name from array
                    var res = result.online_products_all;
                    let mymap = new Map(); 
    
                    let unique = res.filter(el => { 
                        const val = mymap.get(el.brand); 
                        if(val) { 
                            if(el.id < val) { 
                                mymap.delete(el.brand); 
                                mymap.set(el.brand, el.brand); 
                                return true; 
                            } else { 
                                return false; 
                            } 
                        } 
                        mymap.set(el.brand, el.brand); 
                        return true; 
                    }); 

                    var resArr = [];
                    unique.filter(function(item){
                        var i = resArr.findIndex(x => x.brand == item.brand);
                        if(i <= -1){
                            resArr.push({name: item.brand, id: item.brand});
                        }
                        return null;
                    });
                    this.props.setBrandOptions(resArr);
                    //Added this code for to get unique category name from array
                },
                (error) => {
                    this.setState({ isProductError: error });
                }
            )
        }
    }

    openCartReturnPop() {
        this.setState({showCartReturnModal: true});
    }
    
    closeCartReturnPop() {
        this.setState({showCartReturnModal: false});
    }

    cartUpdatePopupResOpen() {
        this.setState({showUpdateCartMsgModal: true});
    }
    
    cartUpdatePopupResClose() {
        this.setState({showUpdateCartMsgModal: false});
    }

    handleOnAddToCart(product_id, product_name, product_image, price, quantity) {
        this.setState({addToCartButtonnLoader: '', addToCartButtonnCart: 'hidden', addToCartButton: true, addToCartButtonDisabled: true, cartErrorMessage: '', clickedProductId: product_id})
        this.props.addToCart(product_id, product_name, product_image, price, quantity);

        setTimeout(()=> 
            this.setState({
                isCartAddError: this.props.isCartAddError 
            },() => {
                if(this.state.isCartAddError){
                    this.setState({cartErrorMessage: this.props.cartErrorMessage, addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false, clickedProductId: ''});
                    this.cartUpdatePopupResOpen();
                    setTimeout(()=> 
                        this.cartUpdatePopupResClose(),
                    5000);
                } else {
                    this.openCartReturnPop();
                    this.setState({addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: false, addToCartButtonDisabled: false, cartErrorMessage: ''});
                }
            }),
        3000);
    }

    render(){
        const { isProductsLoaded, categoryProducts, pageOfItems } = this.state;
        const { match, location, history, cartData, filteredCategoryProducts, isFilterProductsLoaded } = this.props;
        //console.log(cartData);

        if(isProductsLoaded && isFilterProductsLoaded===false){
            if (categoryProducts && categoryProducts.length > 0) {
                
                return(
                    <>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                        <div className="col-md-12">
                            <h2 className="top-listing">Fotografía</h2>
                         </div>   
                         <div className="col-md-6">
                            <div className="similar-catagory">
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/photography-view.png`} alt="Cámaras digitales" className="full-width-auto" />
                                <h4>Cámaras digitales</h4>
                                <a href="javascript:void(0)">
                                    Mostrar más
                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} width="20" height="16" alt="Cart" />
                                </a>
                            </div>
                         </div>  
                         <div className="col-md-6">
                            <div className="similar-catagory">
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/accessories-view.png`} alt="Accesorios de fotografía" className="full-width-auto" />
                                <h4>Accesorios de fotografía</h4>
                                <a href="javascript:void(0)">
                                    Mostrar más
                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} width="20" height="16" alt="Cart" />
                                </a>
                            </div>
                         </div>
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={localState.product_id}>
                                <div className="deal-link-item">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                            <a href="javascript:void(0)" className="wishlist-link">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                            </a>
                                            {(() => {
                                                let modelNumber = localState.modelo;
                                                let checkSlash = "/";
                                                let setModelNumber;
                                                if(modelNumber.indexOf(checkSlash) !== -1){
                                                    setModelNumber = localState.modeloch;
                                                }else{
                                                    setModelNumber = localState.modelo;
                                                }
                                                return (
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        <img src={localState.product_image} alt="" />
                                                    </Link>
                                                )
                                            })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2>
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }
                                                    return (
                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                            {localState.product_name}
                                                        </Link>
                                                    )
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>5.0 | 14 opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            {(() => {
                                                if (localState.price === '0.00'){
                                                    return (<></>)
                                                } else {
                                                    return (
                                                        <div className="price">
                                                            {localState.regular_price ? (
                                                                <span style={{textDecoration:'line-through', fontWeight:'400', fontSize:'15px', marginRight:'10px', color:'#4d4a4a'}}>
                                                                    $ {localState.regular_price}
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            $ {localState.price}
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            
                                            {/* <h3>{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase === '1'){
                                                if(this.state.addToCartButton){
                                                    return (
                                                    <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} disabled={this.state.addToCartButtonDisabled}>
                                                 
                                                        {(() => {
                                                            if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </button>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                    return (
                                                    <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                    return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                            <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                        </button>
                                                        )
                                                } else {
                                                    return null;
                                                }
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={categoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>

                    <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>                          
                        <Modal.Header>
                            <button type="button" className="close" onClick={this.closeCartReturnPop}>&times;</button>
                            <Modal.Title>
                                <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p style={{marginTop: '17px', textAlign: 'center'}}>
                        <Link to="/cart" className="btn btn-primary btn-lg">
                            &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                        </Link>
                        <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop} style={{margin:"0 0 0 30px"}}>
                            &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                        </button>
                        </p>
                        </Modal.Body>     
                    </Modal>
                    </>
                )

            }else{

                return(
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                            <h3>No Product Found.</h3>
                            </div>
                        </div>
                    </div>
                )

            }
        }else if(isProductsLoaded && isFilterProductsLoaded){
            if (filteredCategoryProducts && filteredCategoryProducts.length > 0) {
                
                return(
                    <>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                        <div className="col-md-12">
                            <h2 className="top-listing">Fotografía</h2>
                         </div>   
                         <div className="col-md-6">
                            <div className="similar-catagory">
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/photography-view.png`} alt="Cámaras digitales" className="full-width-auto" />
                                <h4>Cámaras digitales</h4>
                                <a href="javascript:void(0)">
                                    Mostrar más
                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} width="20" height="16" alt="Cart" />
                                </a>
                            </div>
                         </div>  
                         <div className="col-md-6">
                            <div className="similar-catagory">
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/accessories-view.png`} alt="Accesorios de fotografía" className="full-width-auto" />
                                <h4>Accesorios de fotografía</h4>
                                <a href="javascript:void(0)">
                                    Mostrar más
                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} width="20" height="16" alt="Cart" />
                                </a>
                            </div>
                         </div>
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={localState.product_id}>
                                <div className="deal-link-item">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                        <a href="javascript:void(0)" className="wishlist-link">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                        </a>
                                        {(() => {
                                            let modelNumber = localState.modelo;
                                            let checkSlash = "/";
                                            let setModelNumber;
                                            if(modelNumber.indexOf(checkSlash) !== -1){
                                                setModelNumber = localState.modeloch;
                                            }else{
                                                setModelNumber = localState.modelo;
                                            }
                                            return (
                                                <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                    <img src={localState.product_image} alt="" />
                                                </Link>
                                            )
                                        })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2>
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }
                                                    return (
                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                            {localState.product_name}
                                                        </Link>
                                                    )
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>5.0 | 14 opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            <div className="price">$ {localState.price}</div>
                                            
                                            {/* <h3>{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase === '1'){
                                                if(this.state.addToCartButton){
                                                    return (
                                                    <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} disabled={this.state.addToCartButtonDisabled}>
                                                 
                                                        {(() => {
                                                            if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </button>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                    return (
                                                    <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                    return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                            <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                        </button>
                                                        )
                                                } else {
                                                    return null;
                                                }
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={filteredCategoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>

                    <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>                          
                        <Modal.Header>
                            <button type="button" className="close" onClick={this.closeCartReturnPop}>&times;</button>
                            <Modal.Title>
                                <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p style={{marginTop: '17px', textAlign: 'center'}}>
                        <Link to="/cart" className="btn btn-primary btn-lg">
                            &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                        </Link>
                        <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop} style={{margin:"0 0 0 30px"}}>
                            &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                        </button>
                        </p>
                        </Modal.Body>     
                    </Modal>
                    </>
                )

            }else{

                return(
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                            <h3>No Product Found.</h3>
                            </div>
                        </div>
                    </div>
                )

            }

        }else{
           
            return(
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                    <div className="row">
                        {Array(6).fill().map((item, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                                <div className="deal-link-item" key={index}>
                                    <Skeleton height={200} width={`85%`} style={{marginBottom:'20px'}} />
                                    <Skeleton height={30} width={90} style={{marginBottom:'7px'}} />
                                    <Skeleton width={180} style={{marginBottom:'7px'}} />
                                    <Skeleton width={200} style={{marginBottom:'7px'}} />
                                    <Skeleton height={35} width={225} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )

        }
    }
    
}

function mapStateToProps(state) {
    //console.log(state.cart.cartData);
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData))
    return { 
        isLoggedIn: state.user.isLoggedIn,
        isError: state.user.isError,
        errorMessage: state.user.errorMessage,
        cartData: state.cart.cartData,
        filteredCategoryProducts: state.filter.filteredProducts,
        isFilterProductsLoaded: state.filter.isFilterProductsLoaded,
        isCartAddError: state.cart.isCartAddError,
        cartErrorMessage: state.cart.cartErrorMessage,
    }
}

const MapDispachToProps = dispatch => ({
    addToCart: () => dispatch(addToCart())
})

const actionCreators = {
    addToCart:addToCart2,
    resetFilter: resetFilter,
    setBrandOptions: setBrandOptions,
    setProductsById: setProductsById
};

const ProductRightCategoryList = connect(
    mapStateToProps,
    actionCreators
)(ProductRightCategoryListComp)

export default withRouter(ProductRightCategoryList);