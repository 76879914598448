import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

// function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }

class BannerSection extends Component {

    render(){
        const TITLE = 'E-Vision / LG Summerfest: ';

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>

            <section className="banner-container">
                <div className="product-title">LG Summerfest</div>
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>		      
            </section>

            <section className="breadcamp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/">Home &raquo;</Link> LG Summerfest
                    </div>
                </div>
            </div>
            </section>
            </>
        )
        
    }
}

export default withRouter(BannerSection);