import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class OsterBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <section className="dashboard-container">
                <div id="g-BrandShop_editable-box" className="ai2html">
                    <Link to="/fabricantes/oster">
                      <div id="g-BrandShop_editable-Artboard_1" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '556px'}} data-aspect-ratio="3.453" data-min-width={0}>
                        <div style={{padding: '0 0 28.9583% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1.jpg" />
                      </div>
                    </Link>
                    <Link to="/categorias/electrodomesticos">
                      <div id="g-BrandShop_editable-Artboard_1_copy_2" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '1221px'}} data-aspect-ratio="1.572" data-min-width={0}>
                        <div style={{padding: '0 0 63.6094% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1_copy_2-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1_copy_2.jpg" />
                      </div>
                    </Link>
                    <Link to="/categorias/electrodomesticos?brand=oster">
                      <div id="g-BrandShop_editable-Artboard_1_copy_3" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '1221px'}} data-aspect-ratio="1.572" data-min-width={0}>
                        <div style={{padding: '0 0 63.6094% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1_copy_3-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1_copy_3.jpg" />
                      </div>
                    </Link>
                    <Link to="/categorias/electrodomesticos/otros?brand=oster">
                      <div id="g-BrandShop_editable-Artboard_1_copy_4" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '1221px'}} data-aspect-ratio="1.572" data-min-width={0}>
                        <div style={{padding: '0 0 63.6094% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1_copy_4-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1_copy_4.jpg" />
                      </div>
                    </Link>
                    <Link to="/fabricantes/oster">
                      <div id="g-BrandShop_editable-Artboard_1_copy_5" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '548px'}} data-aspect-ratio="3.502" data-min-width={0}>
                        <div style={{padding: '0 0 28.5514% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1_copy_5-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1_copy_5.jpg" />
                      </div>
                    </Link>
                    <Link to="/categorias/electrodomesticos?brand=oster">
                      <div id="g-BrandShop_editable-Artboard_1_copy_7" className="g-artboard" style={{maxWidth: '1920px', maxHeight: '1221px'}} data-aspect-ratio="1.572" data-min-width={0}>
                        <div style={{padding: '0 0 63.6094% 0'}} />
                        <img id="g-BrandShop_editable-Artboard_1_copy_7-img" className="g-aiImg" alt="" src="/images/BrandShop_editable-Artboard_1_copy_7.jpg" />
                      </div>
                    </Link>
                </div>
            </section>
        );
       
    }
}

export default OsterBrandListingSection;