import React, {Component} from 'react';
// import ProductLeftCategoryMenu from './ProductLeftCategoryMenu';
// import ProductRightCategoryList from './ProductRightCategoryList';

export default class ProductListingContainer extends Component {

    render(){
        return(
            <></>
            // <section className="listing-container">
	        //     <div className="container">
		    //         <div className="row">
            //             <ProductLeftCategoryMenu />
            //             <ProductRightCategoryList />
            //         </div>
            //     </div>
            // </section>
        )
    }
}