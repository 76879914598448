import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class BreadcrumbSection extends Component {

    render(){
        let categoryName = this.props.match.params.categoryName;
        let brandName = "";
        let TITLE = "";

        if(this.props.match.params.brandName !== undefined){
            brandName = this.props.match.params.brandName;
            TITLE = 'E-Vision | '+categoryName.toUpperCase()+' | '+brandName.toUpperCase();

        }else{
            TITLE = 'E-Vision | '+categoryName.toUpperCase();
        }

        //const hostname = window.location.host;
        //let canonicalUrl = "https://" + hostname + "/categorias/" + categoryName + "?brand=" + brandName;
        //console.log(canonicalUrl);
        return(
            <>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="title" content={TITLE} />
                <meta name="description" content="Albrook Mall | Los Pueblos | Online | E Vision te trae la mejor calidad en los productos que necesitas para tu hogar y la oficina. Contamos con una gran variedad de cómputo, línea blanca, televisores y mucho más." />
                <meta name="keywords" content="e-commerce, electronica, linea-blanca, tecnologia, compra online" />
            </Helmet>
            <section className="breadcamp">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop:'30px'}}>
                    <Link to="/">Home &raquo;</Link> <Link to={`/categorias/${categoryName}`}>{capitalizeFirstLetter(categoryName)} &raquo;</Link> {capitalizeFirstLetter(brandName)}
                    </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default withRouter(BreadcrumbSection);