import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import QuotationListingSection from './QuotationListingSection';

export default class QuotationPage extends Component {
    render(){
        return <div className="wraper">
                    <BannerSection />
                    <BreadcrumbSection />
                    <QuotationListingSection />
                </div>
    }
}