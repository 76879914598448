import React, {Component} from 'react';
import { Modal, Form } from 'react-bootstrap';
import API_BASE_URL from '../../config/api';

export default class FooterCopyrightSection extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            error: '', errors: {},
            showEmailModal: false,
            isPopupEmailSubmitted: false, popuptoemail: "", popupEmailLoader: "hidden", popupApiSuccessResponse: "", newsletterDisabled : false,
            showFeedbackModal: false,
            isFeedbackFromSubmitted: false, feedbackrate: "", feedbackemail: "", feedbackcomments: "", feedbackBtnDisabled: false, feedbackLoader: "hidden"
        }

        this.openEmailPop = this.openEmailPop.bind(this);
        this.closeEmailPop = this.closeEmailPop.bind(this);
        this.handleOnPopupEmailInputChange = this.handleOnPopupEmailInputChange.bind(this);
        this.handleOnEmailPopupSubmit = this.handleOnEmailPopupSubmit.bind(this);

        this.openFeedbackPop = this.openFeedbackPop.bind(this);
        this.closeFeedbackPop = this.closeFeedbackPop.bind(this);
        this.handleOnFeedbackInputChange = this.handleOnFeedbackInputChange.bind(this);
        this.handleOnFeedbackFormSubmit = this.handleOnFeedbackFormSubmit.bind(this);
    }

    openEmailPop() {
        this.setState({showEmailModal: true});
    }
    
    closeEmailPop() {
        this.setState({showEmailModal: false});
        localStorage.setItem("isNewsletterChecked", true);
    }

    openFeedbackPop() {
        this.setState({showFeedbackModal: true});
    }
    
    closeFeedbackPop() {
        this.setState({showFeedbackModal: false});
    }

    handleOnPopupEmailInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleEmailPopupFormValidation(){
        let popuptoemail = this.state.popuptoemail;
        let errors = {};
        let emailPopupFormIsValid = true;
    
        if(typeof popuptoemail !== "undefined"){
           let lastAtPos = popuptoemail.lastIndexOf('@');
           let lastDotPos = popuptoemail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && popuptoemail.indexOf('@@') === -1 && lastDotPos > 2 && (popuptoemail.length - lastDotPos) > 2)) {
              emailPopupFormIsValid = false;
              errors["popuptoemail"] = "Su identificación de correo electrónico no es válida";
            }
        } else {
            emailPopupFormIsValid = false;
            errors["popuptoemail"] = "Ingrese su ID de correo electrónico";
        } 
    
       this.setState({errors: errors});
       return emailPopupFormIsValid;
    }

    handleOnEmailPopupSubmit(e){
        e.preventDefault();
        this.setState({ isPopupEmailSubmitted: true, popupApiSuccessResponse: '',  popupApiErrorResponse: '' });

        if(this.handleEmailPopupFormValidation()){
            this.setState({ popupEmailLoader: "", newsletterDisabled: true });
            let customer_email = this.state.popuptoemail;

            //const apiUrl = 'https://www.evisionstore.com/api/home/newsletter.php';
            const apiUrl = `${API_BASE_URL}/newsletter`;
        
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "email": customer_email
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code===200){
                        //console.log(result);
                        this.setState({
                            popupApiSuccessResponse: result.message,
                            popuptoemail:'',
                            popupEmailLoader: "hidden",
                            newsletterDisabled: false
                        },() => {
                            setTimeout(()=> 
                                this.closeEmailPop(),
                            2000);
                        });
                        localStorage.setItem("isNewsletterChecked", true);
                    }else{
                        this.setState({
                            popupApiErrorResponse: result.message,
                            popupEmailLoader: "hidden",
                            newsletterDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, newsletterDisabled: false });
                }
            )
        }
    }

    handleOnFeedbackInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleOnRateInputChange(feedbackratenumber){
        this.setState({
            feedbackrate: feedbackratenumber
        });

        let errors = this.state.errors;
        errors["feedbackrate"] = "";
        this.setState({errors: errors });
    }

    handleOnFeedbackFormValidation(){
        let feedbackemail = this.state.feedbackemail;
        let feedbackcomments = this.state.feedbackcomments;
        let feedbackrate = this.state.feedbackrate;
        let errors = {};
        let feedbackFormIsValid = true;

        if(!feedbackrate){
            feedbackFormIsValid = false;
            errors["feedbackrate"] = "Seleccione cualquier experiencia de la tienda Evision.";
        }
    
        if(typeof feedbackemail !== "undefined"){
           let lastAtPos = feedbackemail.lastIndexOf('@');
           let lastDotPos = feedbackemail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && feedbackemail.indexOf('@@') === -1 && lastDotPos > 2 && (feedbackemail.length - lastDotPos) > 2)) {
              feedbackFormIsValid = false;
              errors["feedbackemail"] = "Su identificación de correo electrónico no es válida";
            }
        } else {
            feedbackFormIsValid = false;
            errors["feedbackemail"] = "Ingrese su ID de correo electrónico";
        } 

        if(!feedbackcomments){
            feedbackFormIsValid = false;
            errors["feedbackcomments"] = "Por favor ingrese sus comentarios.";
        }
    
       this.setState({errors: errors});
       return feedbackFormIsValid;
    }

    handleOnFeedbackFormSubmit(e){
        e.preventDefault();
        this.setState({ isFeedbackFromSubmitted: true, feedbackApiSuccessResponse: '',  feedbackApiErrorResponse: '' });

        if(this.handleOnFeedbackFormValidation()){
            this.setState({ feedbackLoader: "", feedbackBtnDisabled: true });
            let feedbackemail = this.state.feedbackemail;
            let feedbackcomments = this.state.feedbackcomments;
            let feedbackrate = this.state.feedbackrate;

            const apiUrl = 'https://www.evisionstore.com/api/home/feedback.php';
        
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "rating": feedbackrate,
                    "email": feedbackemail,
                    "comments": feedbackcomments
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code===200){
                        //console.log(result);
                        this.setState({
                            feedbackApiSuccessResponse: result.message,
                            feedbackemail: '',
                            feedbackcomments: '',
                            feedbackLoader: "hidden",
                            feedbackBtnDisabled: false
                        },() => {
                            setTimeout(()=> 
                                this.closeFeedbackPop(),
                            2000);
                        });
                    }else{
                        this.setState({
                            feedbackApiErrorResponse: result.message,
                            feedbackLoader: "hidden",
                            feedbackBtnDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, feedbackBtnDisabled: false });
                }
            )
        }
    }

    componentDidMount(){
        if (localStorage.getItem('isNewsletterChecked')) {
            this.setState({showEmailModal: false});
        } else {
            this.setState({showEmailModal: true});
        }
    }

    render(){
        const {popuptoemail, isPopupEmailSubmitted, feedbackemail, feedbackcomments, isFeedbackFromSubmitted} = this.state;
        return(
            <>
            <Modal show={this.state.showEmailModal} onHide={this.closeEmailPop} animation={true} size="md" aria-labelledby="example-modal-sizes-title-md">                          
                <Modal.Body>
                    <div className=""> 
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="button" className="close" onClick={this.closeEmailPop}>&times;</button>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <form className="notify_prod_form" id="forgot-password-form" onSubmit={this.handleOnEmailPopupSubmit}>
                                <div className="col-xs-12">
                                <h4 style={{marginTop:"20px"}}>NEWSLETTER</h4>
                                <p>Suscríbase a nuestro newsletter para ofertas, novedades y descuentos</p>
                                </div>
                                <div className="col-xs-12">
                                    <Form.Group controlId="formGroupCardNumber">
                                        <Form.Control type="email" name="popuptoemail" value={popuptoemail} onChange={this.handleOnPopupEmailInputChange} placeholder="Escriba aquí su email" className={'form-control' + (isPopupEmailSubmitted && !popuptoemail ? ' has-error' : '')} maxLength="60"/>
                                        <span style={{color: "red"}}>{this.state.errors["popuptoemail"]}</span>
                                        <span style={{color: "red"}}>{this.state.popupApiErrorResponse}</span>
                                        <span style={{color: "green"}}>{this.state.popupApiSuccessResponse}</span>
                                    </Form.Group>
                                </div>
                                
                                <div className="col-xs-12 signup-btn-wrap" style={{marginBottom:'15px'}}>
                                    <button className="btn" value="Forgot Password" type="submit" disabled={this.state.newsletterDisabled}>
                                        <i className={`fa fa-refresh fa-spin ${ this.state.popupEmailLoader }`} style={{fontSize:"17px"}}></i> 
                                        &nbsp; {this.state.newsletterDisabled ? 'Subscribing..' : 'Suscribir'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>       
            </Modal>
            <section className="copyright">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <ul className="bottom-footer-menu">
                                <li><a href="javscript:void(0)">Inicio</a></li>
                                <li><a href="javscript:void(0)">Departamentos</a></li>
                                <li><a href="javscript:void(0)">Contáctenos</a></li>
                                <li><a href="javscript:void(0)">Tienda online</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <div className="card-wrap">
                                {/* <h3>ACEPTAMOS</h3> */}
                                <img src={`${process.env.PUBLIC_URL}/images/card-1.jpg`} alt="Mastercard" />
                                <img src={`${process.env.PUBLIC_URL}/images/card-2.jpg`} alt="Visa" />
                            </div>
                        </div>
                        <div className="col-md-3 text-center text-md-right">
                            <p>Copyright {(new Date().getFullYear())} E Vision, S.A.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="feedback-btn" onClick={this.openFeedbackPop}><i className="fa fa-comments-o" aria-hidden="true"></i> <span>Feedback</span></div>
            <Modal show={this.state.showFeedbackModal} onHide={this.closeFeedbackPop} animation={true} size="md" aria-labelledby="example-modal-sizes-title-md">                          
                <Modal.Body>
                    <div className=""> 
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="button" className="close" onClick={this.closeFeedbackPop}>&times;</button>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <form className="notify_prod_form" id="feedback-form" onSubmit={this.handleOnFeedbackFormSubmit}>
                                <div className="col-xs-12" style={{textAlign:'center'}}>
                                <h4 style={{marginTop:"20px"}}>Help Us Improve Our Site</h4>
                                </div>
                                
                                <div className="col-lg-12 col-md-12 col-xs-12" style={{textAlign:'center'}}>Rate your E-Vision store experience</div>
                                <div className="col-lg-12 col-md-12 col-xs-12" style={{textAlign:'-webkit-center'}}>
                                    <fieldset className="feedback-stars">
                                        <input className="feedback-input" id="star1" type="radio" name="feedbackrate" value="1" /> 
                                        <label className="fa fa-star" htmlFor="star1" onClick={e => this.handleOnRateInputChange(1)}></label>
                                    
                                        <input className="feedback-input" id="star2" type="radio" name="feedbackrate" value="2" />
                                        <label className="fa fa-star" htmlFor="star2" onClick={e => this.handleOnRateInputChange(2)}></label>
                                    
                                        <input className="feedback-input" id="star3" type="radio" name="feedbackrate" value="3" />
                                        <label className="fa fa-star" htmlFor="star3" onClick={e => this.handleOnRateInputChange(3)}></label>
                                    
                                        <input className="feedback-input" id="star4" type="radio" name="feedbackrate" value="4" />
                                        <label className="fa fa-star" htmlFor="star4" onClick={e => this.handleOnRateInputChange(4)}></label>
                                    
                                        <input className="feedback-input" id="star5" type="radio" name="feedbackrate" value="5" />
                                        <label className="fa fa-star" htmlFor="star5" onClick={e => this.handleOnRateInputChange(5)}></label>
                                        <figure className="feedback-face">
                                            <i></i><i></i>
                                            <u>
                                                <div className="feedback-cover"></div>
                                            </u>
                                        </figure>
                                    </fieldset>
                                    <span className="feedbackrate-error">{this.state.errors["feedbackrate"]}</span>
                                </div>
                                <div className="col-xs-12">
                                    <Form.Group controlId="formGroupEmailId">
                                        <Form.Label>Email Id *</Form.Label>
                                        <Form.Control type="email" name="feedbackemail" value={feedbackemail} onChange={this.handleOnFeedbackInputChange} placeholder="Escriba aquí su email" className={'form-control' + (isFeedbackFromSubmitted && !feedbackemail ? ' has-error' : '')} maxLength="60"/>
                                        <span style={{color: "red"}}>{this.state.errors["feedbackemail"]}</span>
                                    </Form.Group>

                                    <Form.Group controlId="formGroupComments">
                                        <Form.Label>Cuéntenos su experiencia *</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="feedbackcomments" value={feedbackcomments} onChange={this.handleOnFeedbackInputChange} placeholder="¿Qué piensas de nuestro sitio?" className={'form-control' + (isFeedbackFromSubmitted && !feedbackcomments ? ' has-error' : '')} maxLength="300"/>
                                        <span style={{color: "red"}}>{this.state.errors["feedbackcomments"]}</span>
                                    </Form.Group>

                                    <span style={{color: "red"}}>{this.state.feedbackApiErrorResponse}</span>
                                    <span style={{color: "green"}}>{this.state.feedbackApiSuccessResponse}</span>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <p>For E-Vision store customer service, Order or Product related support, please visit our&nbsp; 
                                        <a className="fl-inline-link" href="https://www.evisionstore.com/terminos" target="_blank" rel="noopener noreferrer">Help Center</a>
                                        &nbsp;and <a href="https://www.evisionstore.com/politicas" target="_blank" rel="noopener noreferrer">E-Vision store Privacy Policy</a></p>
                                </div>
                                <div className="col-xs-12 signup-btn-wrap" style={{marginBottom:'15px'}}>
                                    <button className="btn" type="submit" disabled={this.state.feedbackBtnDisabled}>
                                        <i className={`fa fa-refresh fa-spin ${ this.state.feedbackLoader }`} style={{fontSize:"17px"}}></i> 
                                        &nbsp; {this.state.feedbackBtnDisabled ? 'Sumisión..' : 'Enviar'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>       
            </Modal>
            </>
        )
    }
}