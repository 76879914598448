import React, {Component} from 'react';

export default class SocialLinksSection extends Component {
    render(){
        return(
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                <div className="footer-block">
                    <div className="footer-social-ico">
                        <h3>Síganos</h3>
                        <span className="evision-fb">
                            <a href="https://facebook.com/EVision" target="_blank" rel='noopener noreferrer'>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-facebook.svg`} width="44" height="44" title="Facebook" alt="Facebook" />
                            </a>
                        </span>
                        <span className="evision-twit">
                            <a href="https://twitter.com/evisionstore" target="_blank" rel='noopener noreferrer'>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-twitter.svg`} width="44" height="44" title="Twitter" alt="Twitter" />
                            </a>
                        </span>
                        <span className="evision-insta">
                            <a href="https://instagram.com/evisionpanama" target="_blank" rel='noopener noreferrer'>
                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-instagram.svg`} width="44" height="44" title="Instagram" alt="Instagram" />
                            </a>
                        </span>
                    </div>
                    <div className="footerApiLogos">
                        <a href="https://play.google.com/store/apps/details?id=com.evision" className="playstore_link" target="_blank" rel='noopener noreferrer'>
                            <img src="https://www.evisionstore.com/images/google-play-store-icon.png" alt="Download E-Vision App"  className="playstore_img"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/e-vision/id1535303372" className="playstore_link" target="_blank" rel='noopener noreferrer'>
                            <img src="https://www.evisionstore.com/images/apple-store-icon.png" alt="Download E-Vision App"  className="playstore_img"/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}