import React, { Component } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import API_BASE_URL from '../../config/api';

export default class ProductCategoryListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      categories: [],
      categoryProductLoded: false,
    };
  }

  componentDidMount() {
    const apiUrl = `${API_BASE_URL}/department`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("Category Menu: ", result);
          this.setState({
            categories: result.data,
            categoryProductLoded: true,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  render() {
    const { error, categories, categoryProductLoded } = this.state;
    const departmentSliderSettings = {
      dots: true,
      fade: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 9,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            autoplay: false,
            dots: true,
          }
        }
      ]
    };
    if (categoryProductLoded === false) {
      return (
        <section className="category-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="product-title common-section-title">
                  <h2>Departamentos</h2>
                </div>
              </div>
              {Array(6)
                .fill()
                .map((item, index) => (
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6" key={index}>
                    <div className="product-link-item">
                      <Skeleton height={70} width={110} style={{ marginBottom: "15px" }} />
                      <Skeleton height={20} width={95} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section className="category-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="product-title common-section-title">
                  <h2>Departamentos</h2>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 department-slider-wrapper">
                <Slider {...departmentSliderSettings}>
                  {categories.map((category) => (
                    <div className="" key={category.id}>
                      <div className="department-link-item">
                        <Link to={`/categorias/${category.slug}`}>
                          <figure>
                            {/* <img src={category.category_image} alt={category.name} style={{ height: "90px" }} /> */}
                            <img src={category.category_image} alt={category.name} />
                          </figure>
                          <div className="department-heading-wrap">
                            <h3>{category.name}</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
