import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class FrigidaireBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <div className="brandshop-section">
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/fabricantes/frigidaire">
                            <img src="/images/Banner-principal.jpg" alt="Product" title="Product" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <Link to="/categorias/linea-blanca/estufas?brand=frigidaire">
                            <img src="/images/BS-FRGD2.jpg" alt="Product" title="Product" className="product-img11" />
                        </Link>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <Link to="/categorias/linea-blanca/extractores-de-grasa?brand=frigidaire">
                                <img src="/images/BS-FRGD3.jpg" alt="Product" title="Product" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <Link to="/categorias/linea-blanca/refrigeradoras/compactas-mini-bar">
                                <img src="/images/BS-FRGD4.jpg" alt="Product" title="Product" />
                            </Link>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/categorias/linea-blanca/hornos-empotrables?brand=frigidaire">
                                <img src="/images/BS-FRGD5.jpg" alt="Product" title="Product" className="product-img44" />
                            </Link>
                        </div>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <Link to="/categorias/linea-blanca/refrigeradoras?brand=frigidaire">
                            <img src="/images/BS-FRGD6.jpg" alt="Product" title="Product" className="product-img22" />
                        </Link>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <Link to="/categorias/linea-blanca/microondas?brand=frigidaire">
                            <img src="/images/BS-FRGD7.jpg" alt="Product" title="Product" className="product-img44" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/categorias/linea-blanca/lavadoras-de-platos?brand=frigidaire">
                            <img src="/images/BS-FRGD-Mesa-de-trabajo.jpg" alt="Product" title="Product" className="product-img11" />
                        </Link>
                    </div>
                    <div className="clear-fx" />
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/categorias/linea-blanca/lavadoras/centro-de-lavado?brand=frigidaire">
                            <img src="/images/BS-FRGD10.jpg" alt="Product" title="Product" className="product-img33" />
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Link to="/search/ffad">
                            <img src="/images/BS-FRGD11.jpg" alt="Product" title="Product" />
                        </Link>
                    </div>
                </div>
              </div>
        );
       
    }
}

export default FrigidaireBrandListingSection;