import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

class Success extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false, customerOrderId: '', customerOrderRevenues:'', customerOrderShipping: '',customerOrderTax:'',
            customerOrderItems1:[], customerOrderItems2:[]
        }
        
    }

    componentDidMount(){
        let customer_id = this.props.customerId;
        let payment_status = this.props.paymentStatus;
        let placeorder_info = this.props.placeorderInfo;
        let order_id = placeorder_info.order_id;

        if(payment_status === "success") {
            //const apiUrl = 'https://www.evisionstore.com/api/checkout/orderdetails-react.php';
            const apiUrl = `${API_BASE_URL}/update-order`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"customer_id": customer_id, "payment_status": payment_status, "order_id": order_id})
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code === 200){
                        this.setState({
                            customerOrderId: result.data.order_id,
                            customerOrderRevenues: result.data.revenues,
                            customerOrderShipping: result.data.shipping,
                            customerOrderTax: result.data.tax,
                            customerOrderItems1: result.data.items1,
                            customerOrderItems2: result.data.items2,
                            isSuccessResLoaded: true
                        });
                        this.setState({
                            cart: {}
                        });
                        localStorage.removeItem("cartData");
                        localStorage.removeItem("cartAttributesData");
                    }
                },
                (error) => {
                    this.setState({ isSuccessResLoaded: error });
                }
            )
        }
    }
    
    render(){
        const paymentStatus = this.props.paymentStatus;
        const placeorderInfo = this.props.placeorderInfo;
        //let criteoTrackingProducts;
        
        let l = (this.props.cartDataArray.length - 1);
        let s = "";
        this.props.cartDataArray.map((cartProductArr, index) => (
            index === l
            ? s += "{id:'" + cartProductArr.prodId + "', price:" + cartProductArr.prodPrice + ", quantity:" + cartProductArr.prodQuantity + "}"
            : s += "{id:'" + cartProductArr.prodId + "', price:" + cartProductArr.prodPrice + ", quantity:" + cartProductArr.prodQuantity + "},"
        ))  
        const criteoTrackingProducts = s;
        

        if(paymentStatus){

            if(placeorderInfo.payment_method === "bank_transfer"){
                return(
                    <>
                    <Helmet>
                    <script>
                    {`
                        var dataLayer = dataLayer || [];
                        dataLayer.push({
                            event: 'crto_transactionpage',
                            crto: {
                                email: "${this.props.userEmail}",
                                transactionid: ${placeorderInfo.order_id},
                                products: [${criteoTrackingProducts}]
                            }
                        });
                    `}
                    </script>

                    {/* Criteo GTM Tag */}
                    <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async="true"></script>
                    <script>
                    {`
                        window.criteo_q = window.criteo_q || [];
                        window.criteo_q.push(
                        { event: "setAccount", account: 65991 },
                        { event: "setEmail", email: "${this.props.userEmail}" },
                        { event: "setSiteType", type: "d" },
                        { event: "trackTransaction", id: ${placeorderInfo.order_id}, item: [${criteoTrackingProducts}] }
                        );
                    `}
                    </script>
                    {/* End Criteo GTM Tag */}

                    <script>
                    {`    
                        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                        ga('create', 'UA-521381-2', 'auto');
                        ga('require', 'ecommerce', 'ecommerce.js');
                        ga('set', 'currencyCode', 'USD');

                        ga('ecommerce:addTransaction', {
                            'id': '${placeorderInfo.order_id}',
                            'affiliation': 'Evision Store',
                            'revenue': '${this.state.customerOrderRevenues}',
                            'shipping': '${this.state.customerOrderShipping}',
                            'tax': '${this.state.customerOrderTax}'
                        });
                    `}
                    </script>
                    </Helmet>
                    
                    {this.state.customerOrderItems1.length !== 0 ? (
                        this.state.customerOrderItems1.map((items1, index) => (
                            <Helmet> 
                            <script>
                            {`
                                ga('ecommerce:addItem', {
                                    'id': '${items1.id}',
                                    'sku': '${items1.sku}', 
                                    'name': '${items1.name}', 
                                    'category': '${items1.category}', 
                                    'price': ${items1.price}, 
                                    'quantity': ${items1.quantity}
                                });
                            `}
                            </script>
                            </Helmet>
                        ))
                    ) : (
                        <></>
                    )}

                    {this.state.customerOrderItems2.length !== 0 ? (
                        this.state.customerOrderItems2.map((items2, index) => (
                            <Helmet> 
                            <script>
                            {`
                                ga('ec:addProduct', {
                                    'id': '${items2.sku}',
                                    'name': '${items2.name}', 
                                    'category': '${items2.category}', 
                                    'brand': '${items2.brand}', 
                                    'price': '${items2.price}', 
                                    'quantity': '${items2.quantity}'
                                });
                            `}
                            </script>
                            </Helmet>
                        ))
                    ) : (
                        <></>
                    )}

                    <Helmet>        
                    <script>
                    {`
                        ga('ec:setAction', 'purchase', {
                            'id': '${placeorderInfo.order_id}',
                            'affiliation': 'Evision Store',
                            'revenue': '${this.state.customerOrderRevenues}',
                            'tax': '${this.state.customerOrderTax}',
                            'shipping': '${this.state.customerOrderShipping}'
                        });
                
                        ga('send', 'pageview');

                        fbq('track', 'Purchase', {value: ${this.state.customerOrderRevenues}, currency: 'USD'});
                    `}
                    </script>
                    </Helmet>
                    <div className="container" style={{marginTop: "25px"}}>
                        <p style={{float: 'left', width: '100%', textAlign: 'center'}}>
                            <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Success" style={{width:"45px"}}/>
                        </p>
                        {/* <p style={{float: 'left', width: '100%', textAlign: 'center'}}>
                        <img src="https://www.evisionstore.com/api/images/unsuccess.png" alt="Fail" />
                        </p> */}
                        <h3 className="success-heading">Gracias por su pedido</h3>
                        <p className="success-msg">Su orden se colocó con éxito. Su número de orden es: #{placeorderInfo.order_id}</p>
                        <p className="success-msg">Estado de Pago: #PENDING</p>
                        <p className="success-msg">
                            <b>Detalles del banco:</b>
                            <br /> Datos de la cuenta Bancaria:<br />Banco General<br />
                            Número de cuenta: 03-72-01-059285-6<br />
                            Nombre: E Vision S.A<br />
                            Cuenta Corriente  <br />
                            Correo de confirmacion: dp@evisionstore.com <br />
                            Colocar su nombre, numero y productos comprados en la descripcion de la transferencia
                        </p>
                        <p className="success-msg">
                            En cuanto usted haya depositado, debe enviar el comprobante de pago a la direcci&#243;n <a href = "mailto:dp@evisionstore.com"> dp@evisionstore.com </a>.
							La venta se completa al recibir el segundo correo de confirmacion de venta. De no tener disponibilidad del producto, la responsabilidad de la empresa se limita a devolverle la suma completa pagada.
                        </p>
                        <p><Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link></p>
                    </div>
                    </>
                )
            } else if (placeorderInfo.payment_method === "yappy"){
                return(
                    <>
                    <Helmet>
                    <script>
                    {`
                        var dataLayer = dataLayer || [];
                        dataLayer.push({
                            event: 'crto_transactionpage',
                            crto: {
                                email: "${this.props.userEmail}",
                                transactionid: ${placeorderInfo.order_id},
                                products: [${criteoTrackingProducts}]
                            }
                        });
                    `}
                    </script>

                    {/* Criteo GTM Tag */}
                    <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async="true"></script>
                    <script>
                    {`
                        window.criteo_q = window.criteo_q || [];
                        window.criteo_q.push(
                        { event: "setAccount", account: 65991 },
                        { event: "setEmail", email: "${this.props.userEmail}" },
                        { event: "setSiteType", type: "d" },
                        { event: "trackTransaction", id: ${placeorderInfo.order_id}, item: [${criteoTrackingProducts}] }
                        );
                    `}
                    </script>
                    {/* End Criteo GTM Tag */}

                    <script>
                    {`    
                        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                        ga('create', 'UA-521381-2', 'auto');
                        ga('require', 'ecommerce', 'ecommerce.js');
                        ga('set', 'currencyCode', 'USD');

                        ga('ecommerce:addTransaction', {
                            'id': '${placeorderInfo.order_id}',
                            'affiliation': 'Evision Store',
                            'revenue': '${this.state.customerOrderRevenues}',
                            'shipping': '${this.state.customerOrderShipping}',
                            'tax': '${this.state.customerOrderTax}'
                        });
                    `}
                    </script>
                    </Helmet>
                    
                    {this.state.customerOrderItems1.length !== 0 ? (
                        this.state.customerOrderItems1.map((items1, index) => (
                            <Helmet> 
                            <script>
                            {`
                                ga('ecommerce:addItem', {
                                    'id': '${items1.id}',
                                    'sku': '${items1.sku}', 
                                    'name': '${items1.name}', 
                                    'category': '${items1.category}', 
                                    'price': ${items1.price}, 
                                    'quantity': ${items1.quantity}
                                });
                            `}
                            </script>
                            </Helmet>
                        ))
                    ) : (
                        <></>
                    )}

                    {this.state.customerOrderItems2.length !== 0 ? (
                        this.state.customerOrderItems2.map((items2, index) => (
                            <Helmet> 
                            <script>
                            {`
                                ga('ec:addProduct', {
                                    'id': '${items2.sku}',
                                    'name': '${items2.name}', 
                                    'category': '${items2.category}', 
                                    'brand': '${items2.brand}', 
                                    'price': '${items2.price}', 
                                    'quantity': '${items2.quantity}'
                                });
                            `}
                            </script>
                            </Helmet>
                        ))
                    ) : (
                        <></>
                    )}

                    <Helmet>        
                    <script>
                    {`
                        ga('ec:setAction', 'purchase', {
                            'id': '${placeorderInfo.order_id}',
                            'affiliation': 'Evision Store',
                            'revenue': '${this.state.customerOrderRevenues}',
                            'tax': '${this.state.customerOrderTax}',
                            'shipping': '${this.state.customerOrderShipping}'
                        });
                
                        ga('send', 'pageview');
                    `}
                    </script>
                    </Helmet>

                    <div className="container" style={{marginTop: "25px"}}>
                        <p style={{float: 'left', width: '100%', textAlign: 'center'}}>
                            <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Success" style={{width:"45px"}}/>
                        </p>
                        <h3 className="success-heading">Gracias por su pedido</h3>
                        <p className="success-msg">Su orden se colocó con éxito. Su número de orden es: #{placeorderInfo.order_id}</p>
                        <p className="success-msg">Estado de Pago: #PENDING</p>

                        <div><p className="payment-icon"><img src="http://evisionstore.com/images/yappy.png" width="100px" height="100px" alt=""/></p></div>
                        <span className="evi-text" style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>@Evision</font>
                            </font>
                        </span>
                        <p><Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link></p>
                    </div>
                    </>
                )
            } else{
                return(
                    <div className="container" style={{marginTop: "50px"}}>
                        <p style={{textAlign:'center'}}><Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link></p>
                    </div>
                )
            }
        } else {
            return(
                <div className="container" style={{marginTop: "50px"}}>
                    <p style={{textAlign:'center'}}><Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link></p>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return { 
        paymentStatus: state.checkout.paymentStatus,
        placeorderInfo: state.checkout.placeorderInfo,
        cartDataArray: state.cart.cartData,
        userEmail: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.email,
        customerId: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.customer_id
    }
}

const actionCreators = {
    
}

const SuccessArea = connect(
    mapStateToProps,
    actionCreators
)(Success)

export default withRouter(SuccessArea);