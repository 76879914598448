import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';

class SamsungBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
        }

    }

    componentDidMount() {
        let customer_id = this.props.customerId;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const {isLoggedIn, cartProducts} = this.props;
     
        return (
            <div className="section">
                <div className="w-100 flex justify_between">
                  <div className="col1 pt-0">
                    <figure className="w-100 text-center">
                        <Link to="/categorias/linea-blanca/lavadoras?brand=samsung">
                            <picture>
                              <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_Lavadoras_1200x480_wide.jpg" media="(max-width: 1024px)" />
                              <img src="/brandshop_samsung/template-shop/img/E-Shop_Lavadoras_1200x480.jpg" alt="example" />
                            </picture>
                        </Link>
                    </figure>
                  </div>
                  <div className="col3">
                    <figure className="w-100 text-center">
                      <Link to="/categorias/linea-blanca/aire-acondicionado?brand=samsung">
                        <picture>
                          <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_Aire_386x480_wide.jpg" media="(max-width: 1024px)" />
                          <img src="/brandshop_samsung/template-shop/img/E-Shop_Aire_386x480.jpg" alt="example" />
                        </picture>
                      </Link>
                    </figure>
                  </div>
                  <div className="col3">
                    <figure className="w-100 text-center">
                      <Link to="/categorias/linea-blanca/estufas?brand=samsung">
                        <picture>
                          <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_Estufas_386x480_wide.jpg" media="(max-width: 1024px)" />
                          <img src="/brandshop_samsung/template-shop/img/E-Shop_Estufas_386x480.jpg" alt="example" />
                        </picture>
                      </Link>
                    </figure>
                  </div>
                  <div className="col3">
                    <figure className="w-100 text-center">
                      <Link to="/categorias/linea-blanca/refrigeradoras?brand=samsung">
                        <picture>
                          <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_Refrigerador_386x480_wide.jpg" media="(max-width: 1024px)" />
                          <img src="/brandshop_samsung/template-shop/img/E-Shop_Refrigerador_386x480.jpg" alt="example" />
                        </picture>
                      </Link>
                    </figure>
                  </div>
                  <div className="col1">
                    <figure className="w-100 text-center">
                      <Link to="/categorias/televisores?brand=samsung">
                        <picture>
                          <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_Qled_1200x480_wide.jpg" media="(max-width: 1024px)" />
                          <img src="/brandshop_samsung/template-shop/img/E-Shop_Qled_1200x480.jpg" alt="example" />
                        </picture>
                      </Link>
                    </figure>
                  </div>
                  <div className="col2">
                    <figure className="w-100 text-center">
                      <picture>
                        <Link to="/categorias/televisores?brand=samsung">
                            <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_The_Sero_610x480_wide.jpg" media="(max-width: 1024px)" />
                        </Link>
                        <Link to="/categorias/proyectores-de-datos?brand=samsung">
                            <img src="/brandshop_samsung/template-shop/img/Freestyle_Evision_Mobile_1200x480_1.jpg" alt="example" />
                        </Link>
                      </picture>
                    </figure>
                  </div>
                  <div className="col2">
                    <figure className="w-100 text-center">
                      <picture>
                        <Link to="/categorias/audio?brand=samsung">
                            <source srcSet="/brandshop_samsung/template-shop/img/E-Shop_SounsTower_610x480_wide.jpg" media="(max-width: 1024px)" />
                        </Link>
                        <Link to="/search/freestyle">
                            <img src="/brandshop_samsung/template-shop/img/Freestyle_Evision_Mobile_1200x480_2.jpg" alt="example" />
                        </Link>
                      </picture>
                    </figure>
                  </div>
                  <div className="col1">
                    <figure className="w-100 text-center">
                      <Link to="/search/freestyle">
                        <picture>
                          <source srcSet="/brandshop_samsung/template-shop/img/Freestyle_Evision_Mobile_1200x480_3.jpg" media="(max-width: 1024px)" />
                          <img src="/brandshop_samsung/template-shop/img/Freestyle_Evision_1200x480.jpg" alt="example" />
                        </picture>
                      </Link>
                    </figure>
                  </div>
                  <div className="col2 pb-0">
                    <figure className="w-100 text-center">
                      <picture>
                        <source srcSet="/brandshop_samsung/template-shop/img/Freestyle_Evision_Mobile_1200x480_2.jpg" media="(max-width: 1024px)" />
                        <Link to="/categorias/televisores?brand=samsung"><img src="/brandshop_samsung/template-shop/img/E-Shop_The_Sero_610x480.jpg" alt="example" /></Link>
                      </picture>
                    </figure>
                  </div>
                  <div className="col2 pb-0">
                    <figure className="w-100 text-center">
                      <picture>
                        <source srcSet="/brandshop_samsung/template-shop/img/Freestyle_Evision_Mobile_1200x480_3.jpg" media="(max-width: 1024px)" />
                        <Link to="/categorias/audio?brand=samsung"><img src="/brandshop_samsung/template-shop/img/E-Shop_SounsTower_610x480.jpg" alt="example" /></Link>
                      </picture>
                    </figure>
                  </div>
                </div>
              </div>
        );
       
    }
}

export default SamsungBrandListingSection;