import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'E-Vision / Términos y condiciones de venta';

export default class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAndConditions: ''
        }
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/home/termsncondition.php';
        const apiUrl = `${API_BASE_URL}/terms-and-conditions`

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    termsAndConditions: result.data[0].page_contents
                });
            }
        )
    }

    render(){
        const { termsAndConditions } = this.state;
        return(
            <div className="wraper">
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <section className="banner-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--term">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">Términos y condiciones de venta</h1>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="breadcum"><a href="javascript:void(0)">Home</a> <span>&#62;</span> <a href="javascript:void(0)">Terms & Conditions</a></p>
                            </div>
                        </div>
                    </div>		      
                </section>

                <div className="container general-content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                    </div>
                </div>
                <div className="general-line">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}