import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

class BannerSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            categoryName : '',
            isCategoryLoaded: false
        }
    }

    componentDidMount() {
        let params = this.props.match.params;
        let topCategorySlug = params.categoryName;
        let subCategorySlug = params.subCategoryName;
        let lastSubCategoryName = params.lastSubCategoryName;
        let categorySlug = "";

        if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined){
            categorySlug = lastSubCategoryName;
        }else if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName == undefined){
            categorySlug = subCategorySlug;
        }else if(topCategorySlug !== undefined && subCategorySlug == undefined && lastSubCategoryName == undefined){
            categorySlug = topCategorySlug;
        }

        const apiUrl = 'https://www.evisionstore.com/api/product/get_title_and_image.php';
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"category_slug": categorySlug})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    categoryName: result.category_name,
                    categoryImage: result.category_image_url,
                    isCategoryLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params !== this.props.match.params) {
            
            this.setState({
                isCategoryLoaded: false
            });

            let params = this.props.match.params;
            let topCategorySlug = params.categoryName;
            let subCategorySlug = params.subCategoryName;
            let lastSubCategoryName = params.lastSubCategoryName;
            let categorySlug = "";

            if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined){
                categorySlug = lastSubCategoryName;
            }else if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName == undefined){
                categorySlug = subCategorySlug;
            }else if(topCategorySlug !== undefined && subCategorySlug == undefined && lastSubCategoryName == undefined){
                categorySlug = topCategorySlug;
            }
            const apiUrl = 'https://www.evisionstore.com/api/product/get_title_and_image.php';
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_slug": categorySlug})
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categoryName: result.category_name,
                        categoryImage: result.category_image_url,
                        isCategoryLoaded: true
                    });
                },
                (error) => {
                    this.setState({ error: error });
                }
            )
        }
    }

    render(){
        const {categoryName, categoryImage} = this.state;  
        const hostname = window.location.host;

        let params = this.props.match.params;
        let topCategorySlug = params.categoryName;
        let subCategorySlug = params.subCategoryName;
        let lastSubCategoryName = params.lastSubCategoryName;

        let fullCategorySlug;
        if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName !== undefined){
            fullCategorySlug = topCategorySlug + "/" + subCategorySlug + "/" + lastSubCategoryName;
        }else if(topCategorySlug !== undefined && subCategorySlug !== undefined && lastSubCategoryName == undefined){
            fullCategorySlug = topCategorySlug + "/" + subCategorySlug;
        }else if(topCategorySlug !== undefined && subCategorySlug == undefined && lastSubCategoryName == undefined){
            fullCategorySlug = topCategorySlug;
        }
        
        // let queryStringVal = queryString.parse(this.props.location.search);
        // let brandName = "";
        // var canonicalUrl = "https://" + hostname + "/categorias/" + fullCategorySlug;
        // if(queryStringVal.brand !== undefined){
        //     brandName = queryStringVal.brand;
        //     canonicalUrl = "https://" + hostname + "/categorias/" + fullCategorySlug + "?brand=" + brandName;
        // }

        let queryStringVal = queryString.parse(this.props.location.search);
        let brandName = "";
        var canonicalUrl = "https://" + hostname + "/landings/" + fullCategorySlug;
        if(queryStringVal.brand !== undefined){
            brandName = queryStringVal.brand;
            canonicalUrl = "https://" + hostname + "/landings/" + fullCategorySlug + "/" + brandName;
        }



        return(
            <>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            {/* <section className="banner-container"> */}
                {/* <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--photo-catagory d-flex">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">¡Explore equipos y accesorios de fotografía en línea!</h1>
                                            <p>Libere su potencial artístico: explore un mundo de excelencia fotográfica con nuestra colección exclusiva!</p>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
                {/* <div className="product-title">{categoryName}</div> */}
                {/* <figure>  */}
                {/* <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt={categoryName} /> */}
                {/* {(() => {
                    if (categoryImage !== ''){
                        return ( <img src={categoryImage} alt={categoryName} /> );
                    } else {
                        return ( <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt={categoryName} /> );
                    }
                })()} */}
                {/* </figure> */}
            {/* </section> */}

            <section className="breadcamp breadcamp__catagory ooooooooo">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/">Home &#62;</Link> <span className="text-blue">{categoryName}</span>
                    </div>
                </div>
            </div>
            </section>
            </>
        )
        
    }
}

export default withRouter(BannerSection);