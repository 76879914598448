import React, { Component } from "react";
import NewProductSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from "../../config/api";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "red" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "red" }} onClick={onClick} />;
}

export default class HomeNewProductSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      topProducts: [],
      isTopProductLoaded: false,
    };
  }

  componentDidMount() {
    //const apiUrl = 'https://www.evisionstore.com/api/home/specialproducts.php';
    const apiUrl = `${API_BASE_URL}/new-products`;
    /* const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"menutype": "header"})
        }; */
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result)
          this.setState({
            topProducts: result.data,
            isTopProductLoaded: true,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  render() {
    const desktopdelalslidersettings = {
      //dots: true,
      infinite: true,
      autoplay: false,
      speed: 500,
      //rows: 1,
      //slidesPerRow: 2,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const { topProducts } = this.state;

    return (
      <section className="deal-container home-new-product">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {(() => {
                if (this.state.isTopProductLoaded === false) {
                  return (
                    <section className="customer-logos">
                      {Array(4)
                        .fill()
                        .map((item, index) => (
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" key={index}>
                            <div className="deal-link-item deal-link-item--skeleton">
                              <Skeleton height={145} width={`100%`} style={{ marginBottom: "25px" }} />
                              <Skeleton width={175} style={{ marginBottom: "10px" }} />
                            </div>
                          </div>
                        ))}
                    </section>
                  );
                } else {
                  return (
                    <section className="customer-logos slider">
                      <NewProductSlider {...desktopdelalslidersettings}>
                        {topProducts.length !== 0 ? (
                          topProducts.map((products, index) => (
                            <div className="slide" key={index}>
                              <div className="deal-link-item new-product-card">
                                <Link to={`/producto/${products.product_id}`}>
                                  <figure>
                                    <a href="javascript:void(0)" className="wishlist-link">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`}
                                        width="20"
                                        height="18"
                                        alt="wishlist"
                                      />
                                    </a>
                                    <img src={products.product_image} alt={products.product_name} />
                                  </figure>
                                  <div className="heading-wrap">
                                    <h2>{products.product_name}</h2>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </NewProductSlider>
                    </section>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
