import React, { Component } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

class BannerSection extends Component {
  render() {
    let keyword = this.props.match.params.keyword;

    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "url": "https://www.evisionstore.com/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.evisionstore.com/search/{${keyword}}",
                            "query-input": "required name=${keyword}"
                        }
                    }
                `}
          </script>
        </Helmet>
        <section className="banner-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-md-0">
                <div className="banner-area-inner banner-area-inner--photo-catagory d-flex">
                  <figure className="banner-area-inner-image">
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" />
                  </figure>
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <h1 className="banner-title">Resultado de búsqueda<br />{keyword}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(BannerSection);
