import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default class ImageWithLinkListSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            images: [],
            imageLoded: false
        }
    }

    componentDidMount() {
        const apiUrl = 'https://www.evisionstore.com/api/home/subbanner.php';

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    images: result.secondsubbanner,
                    imageLoded: true
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
    }

    render(){
        const { error, images, imageLoded } = this.state;
        if(imageLoded===false) {
            return (
                <section className="subbanner-container">
                    <div className="container image_container">
                        <div className="row">
                            {Array(4).fill().map((item, index) => (
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" key={index}>
                                    <div className="image-link-item">
                                        <Skeleton height={200} width={300} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )
        } else {
            if(images.success===true) {
                return(
                    <section className="subbanner-container">
                        <div className="container image_container">
                            <div className="row">
                                {images.list.map(res => (
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 img-padding" key={res.id}>
                                        <div className="image-link-item">
                                            <Link to={{ pathname:`${res.link}`}} >
                                                <figure>
                                                    <img src={res.image} alt={res.title} />
                                                </figure>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )
            }else{
                return(
                        <></>
                    )
            }
        }
    }
}