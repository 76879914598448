import React, {Component} from 'react';
import { Link } from "react-router-dom";
import API_BASE_URL from '../../config/api';

export default class ContactInfoSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            footerMenuArray: {},
            isFooterMenuLoaded: false
        }
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/home/headermenu.php';
        const apiUrl = `${API_BASE_URL}/menu`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"menutype": "footer"})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    footerMenuArray: result.data.footermenu,
                    isFooterMenuLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    render(){
        const { footerMenuArray } = this.state;
        return(
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="footer-block">
                    <h3>Información</h3>
                    <ul>
                        {footerMenuArray.length !== 0 && Object.values(footerMenuArray).map((footer_menu, index) => (
                            <li key={index}><Link to={footer_menu.link_to}>{footer_menu.link_name}</Link></li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}