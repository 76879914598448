import React, {Component} from 'react';

export default class BannerSection extends Component {
    render(){
        return(
            <>
            <section className="banner-container">
                <div className="product-title">
                    Artículos de cotización
                </div>  
                <figure> 
                    <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt="" /> 
                </figure>
            </section>
            </>
        )
    }
}