import React, {Component} from 'react';
import QuickLinksSection from './QuickLinksSection';
import ContactDetailsSection from './ContactDetailsSection';
import SocialLinksSection from './SocialLinksSection';
import NewsletterSubmitSection from './NewsletterSubmitSection';

export default class Footer extends Component {
    render(){
        return(
            <footer className="main-footer">
                <div className="container">
                    <div className="row">
                        <QuickLinksSection />
                        <ContactDetailsSection />
                        <SocialLinksSection />
                        <NewsletterSubmitSection />
                    </div>
                </div>
            </footer>
        )
    }
}