import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

const TITLE = 'E-Vision / Sucursal';

class SucurSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            sucurSalesPlaces: []
        }
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/home/sucursales.php';
        const apiUrl = `${API_BASE_URL}/sucursales`
        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    sucurSalesPlaces: result.data
                });
                //console.log(result);
            },
            (error) => {
                this.setState({ error });
            }
        )
    }

    render(){
        const { sucurSalesPlaces } = this.state;

        return(
            <div className="wraper">
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <section className="banner-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--term">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">Sucursales</h1>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="breadcum"><a href="/">Home</a> <span>&#62;</span> <a href="javascript:void(0)">Sucursales</a></p>
                            </div>
                        </div>
                    </div>		      
                </section>
                <section className="general-content">
                {sucurSalesPlaces.map((places, index) => (
                <div className="branch-item">    
                    <div className="container" key={index}>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="left-text-padding">
                                    <div className="col-md-12"><h4>{places.nombre}</h4></div>
                                    <div className="cell-padding">
                                        <div className="col-md-3">
                                            <h5>Dirección:</h5>
                                        </div>
                                        <div className="col-md-9">
                                            <p>{places.direccion}</p>
                                        </div>
                                    </div>
                                    <div className="cell-padding">
                                        <div className="col-md-3">
                                            <h5>Teléfonos:</h5>
                                        </div>
                                        <div className="col-md-9">
                                            <p itemprop="telephone">+507 {places.telephone1}</p>
                                        </div>
                                    </div>
                                    <div className="cell-padding">
                                        <div className="col-md-3">
                                            <h5>Telefono 2:</h5>
                                        </div>
                                        <div className="col-md-9">
                                            <p itemprop="faxNumber">+507 {places.telephone2}</p>
                                        </div>
                                    </div>
                                    <div className="cell-padding">
                                        <div className="col-md-3">
                                            <h5>Horario:</h5>
                                        </div>
                                        <div className="col-md-9">
                                            <p dangerouslySetInnerHTML={{__html: places.horario}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 map-block">
                                <iframe src={places.maps} width="100%" height={400} frameBorder={0} style={{border: "0px"}} allowFullScreen title="map" />
                                <p itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
                                <meta itemProp="latitude" content="{places.lat}" />
                                <meta itemProp="longitude" content="{places.long}" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
                </section>

                <div className="general-line">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SucurSales;