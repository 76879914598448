import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import ProductFilterSection from './ProductFilterSection';
import ProductListingContainer from './ProductListingContainer';

export default class ProductList extends Component {
    render(){
        return (
            <div className="wraper">
                {/* <BannerSection /> */}
                <BreadcrumbSection />
                <ProductFilterSection />
                <ProductListingContainer />
            </div>
        )
    }
}